export default function tvaList({tvaList}) {
    // console.log(tvaList)
    return (
        <>
            <div className="tvaList">
                {
                    tvaList && tvaList.length > 0 &&
                    tvaList.map((tva, index) => {
                        return (
                            <div className='tvaList__line' key={index}>
                                <p className='tvaList__line_pourcent'>TVA à {tva.rate}%</p>
                                <p className='tvaList__line_price'>{tva.value}€</p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}