import Header from './header';
import Nav from "./nav";

function TabBar({ setShowNav, showNav}) {


    // const location = useLocation();
    // const path = location.pathname;

    return (
        <>
            <nav className="tabBar">
                <Header setShowNav={setShowNav} />
                {
                    showNav &&
                    <Nav setShowNav={setShowNav} />
                }
            </nav>
        </>
    );
}

export default TabBar;
