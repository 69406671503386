import MainButton from "../addOns/buttonComponent/MainButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/CapBox.svg";
export default function Header({navigate}) {
    return (
        <div className="loginHeader">
            <div className="loginHeader__logoContainer">
                <img onClick={(e) => navigate('/')} className="loginHeader__logoContainer_logo" src={logo} alt=""/>
                <hr className="loginHeader__line"/>
            </div>
                <div className="loginHeader__rightContainer">
                    <MainButton className="loginHeader__rightContainer_registerButton" buttonText={"Créer un compte"}/>
                    <hr className='loginHeader__line'/>
                    <FontAwesomeIcon className="loginHeader__rightContainer_icon" icon={faEnvelope}/>
                </div>
        </div>
    );
}