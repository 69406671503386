import {useContext, useRef} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { PostApiContext} from "../../context/apiCalls/postApiCalls";

export default function AccountantPop({ accountant, handleShowAccountantPop, setRefresh, refresh }) {
  const lastNameRef = useRef("");
  const firstNameRef = useRef("");
  const mailRef = useRef("");

  const PostApiCall = useContext(PostApiContext);
  const id = accountant?.id || "";

  const handleAccountantSubmit = (e) => {

    e.preventDefault();
    const value = {
      lastName: lastNameRef.current.value || accountant?.last_name,
      firstName: firstNameRef.current.value || accountant?.first_name,
      email: mailRef.current.value || accountant?.email,
    };

    //verif entrée formulaire pour éviter les champs vides
    const isFormVal = Object.values(value).every(
      (field) => field !== "" && field !== null && field !== undefined
    );
    console.log("id", id);
    console.log("log de value", value);
    if (isFormVal) {
      PostApiCall.settingsAccountantPost(id, value)
      //axios.post(`${process.env.REACT_APP_SETTINGS_ACCOUNTANT_POST}&id=${id}`,value,customHeaders)
        .then((response) => {
          console.log("response", response);
          setRefresh(!refresh);
        })
        .catch((error) => {
          console.log(error);
        });
      handleShowAccountantPop();
    } else {
      alert("formulaire invalide");
    }
  };

  return (
    <>
      <form
        onSubmit={handleAccountantSubmit}
        type="submit"
        className="popUp interlocutorSettingsPop"
      >
        <div className="titleContent">
          <h3 className="title">
            Créer un nouveau Comptable
          </h3>
          <FontAwesomeIcon className="closeIcon" onClick={handleShowAccountantPop} icon={faXmark} />
        </div>
        <div className="content">
          <input
            required
            ref={lastNameRef}
            defaultValue={accountant?.last_name}
            className="input"
            type="text"
            placeholder="Nom"
            id={"lastName"}
          />
          <input
            required
            ref={firstNameRef}
            defaultValue={accountant?.first_name}
            className="input"
            type="text"
            placeholder="Prénom"
          />

          <input
            required
            ref={mailRef}
            defaultValue={accountant?.email}
            className="input"
            type="mail"
            placeholder="Mail"
          />
        </div>
        {!id && <p>Un email lui sera envoyé avec ses identifiants</p>}
        <button className="submitButton">Valider</button>
      </form>
    </>
  );
}
