import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {useContext, useState} from "react";
import {DeleteApiContext} from "../../context/apiCalls/deleteApiCalls";
import ValidPop from "./popComponents/validPop";

export default function DeletButton({url, id, destination, className, setRefresh, deleteItemByNotId, refresh, validPopContent}) {
    const navigate = useNavigate();

    const deleteApiCalls = useContext(DeleteApiContext)
    const [showValidPop, setShowValidPop] = useState(false);

    const handleDelet = (e) => {
        e?.preventDefault();
        if (!id?.toLowerCase().includes('new')) {
            deleteApiCalls.deleteItem(url, id).then((data) => {
                console.log("delete response", data)
                setShowValidPop(false)
                if (setRefresh) {
                    if (refresh) {
                        setRefresh(false)
                    } else {
                        setRefresh(true)
                    }
                }
            })
        } else {
            deleteItemByNotId(id)
        }
        destination && navigate(destination);
    };

    const buttonsObject = [
        {text: "Retour", className: "backButton", action: (e) => setShowValidPop(false)},
        {text: "Continuer", className: "continueButton", action: handleDelet},
    ]

    return (
        <>
            {showValidPop && <ValidPop title={validPopContent?.title || ""} desc={validPopContent?.desc || ""} showValidPop={showValidPop} setShowValidPop={setShowValidPop} buttonsObject={buttonsObject}/>}
            <FontAwesomeIcon
                onClick={() =>setShowValidPop(true)}
                className={`mainDeleteButton ${className}`}
                icon={faTrashCan}
            />
        </>
    );
}