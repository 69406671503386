import axios from "axios";
import { useContext, useRef } from 'react';
import { AppContext } from '../../../context/appContext';

export default function CreateProspectPop() {

    const appContext = useContext(AppContext);

    const callRef = useRef();
    const meetingRef = useRef();
    const dunningRef = useRef();
    const commentRef = useRef();
    const prospect = appContext.prospectValue;

    const handleProspectSubmit = async (e) => {
        e.preventDefault();

        const customHeaders = {
            'content-type': 'application/json',
        };
        const values = {
            idContact: appContext.idContact,
            idProspect: prospect.id ? prospect.id : null,
            call: callRef?.current?.value,
            meeting: meetingRef?.current?.value,
            dunning: dunningRef?.current?.value,
            comment: commentRef?.current?.value,

        };
        await axios.post(`${process.env.REACT_APP_CONTACT_PROSPECT_POST}`, values, customHeaders)
            .then(response => {
                appContext.setShowCreateProspectPopup(false);
                appContext.setReload(true);
                appContext.setBlur(false);
            })
            .catch(error => {
                console.log(error);
            })
    }
    return (

        <form className='pop-up' onSubmit={handleProspectSubmit} action="submit">
            <div className='pop-up-line'>
                <label className='label'>Appel</label>
                <input className='pop-up-input' placeholder='Ajouter un appel' type="text" ref={callRef} />
            </div>
            <div className='pop-up-line'>
                <label className='label'>Rendez-vous</label>
                <input className='pop-up-input' placeholder='Ajouter un rendez-vous' type="text" ref={meetingRef} />
            </div>
            <div className='pop-up-line'>
                <label className='label'>Relance</label>
                <input className='pop-up-input' placeholder='Ajouter une relance' type="text" ref={dunningRef} />
            </div>
            <div className='pop-up-line'>
                <label className='label'>Commentaire</label>
                <input className='pop-up-input' placeholder='Ajouter un commentaire' type="text" ref={commentRef} />
            </div>
            <div className='pop-up-validation'>
                <p className='pop-up-cancel' onClick={() => {
                    appContext.setShowCreateProspectPopup(false);
                    appContext.setBlur(false);   
                }}>Annuler</p>
                <button className='pop-up-valid' type='submit'>Envoyer</button>
            </div>
        </form>

    )

}