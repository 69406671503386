import { useNavigate } from 'react-router-dom';
import {useEffect, useState, useCallback, useMemo, useRef, useContext} from 'react';
import Pagination from '../../components/addOns/Pagination';
import TotalAmonts from '../../components/addOns/TotalAmonts';
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import StateFilter from '../../components/addOns/filterComponent/stateFilter';
import Card from "../../components/addOns/card";
import MainHeader from "../../components/addOns/headers/MainHeader";
import Loader from "../../components/addOns/loader";
import {
    faCircleCheck,
    faCopy,
    faDownload,
    faFileMedical,
    faPaperPlane,
    faPrint,
    faRightLeft
} from "@fortawesome/free-solid-svg-icons";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import ValidPop from "../../components/addOns/popComponents/validPop";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import {useAuthState} from "../../context/authContext";
import roleVerify from "../../utils/roleVerify";

function QuotesList() {

    const {roleId} = useAuthState();
    const roleList = ["1", "2", "3", "4", "5", "6"];
    const roleVerif = roleVerify(roleList, roleId)

    const navigate = useNavigate();
    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);


    const [quotesList, setQuotesList] = useState();
    const [setListTva] = useState();
    const [setListUnit] = useState();
    const [interlocutors, setInterlocutors] = useState();
    const [quoteState, setQuoteState] = useState('all');
    const [sort, setSort] = useState('datedown')
    const [setToken] = useState();
    const [setSessionRole] = useState();
    const [setSessionSociety] = useState();
    const [listRefresh, setListRefresh] = useState(false);
    const [nbPages, setNbPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showValidPop, setShowValidPop] = useState(false);
    const [quoteValidId, setQuoteValidId] = useState();

    //Setter for pipeline
    useEffect(() => {
        setTotalTTC(0);
        setTotalHT(0);
        setTotalVAT(0);
    }, [])


    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const quoteFilterRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const societyRef = useRef();

    const [interlocutorSearch, setInterlocutorSearch] = useState();

    const setFunctions = useMemo(() => ({
        setListTva,
        setListUnit,
        setSessionRole,
        setSessionSociety,
        setToken,
    }), [setListTva, setListUnit, setSessionRole, setSessionSociety, setToken]);

    const setFunctionsCallback = useCallback(() => setFunctions, [setFunctions]);


    useEffect(() => {
        document.querySelector("body").style.overflow = showValidPop ? "hidden" : "auto";
    }, [showValidPop])


    useEffect(() => {
        if (quoteState === "all") {
            document.querySelector("#valid-0").classList.add("active");
        }
        getApiContext.getQuotesList(sort, quoteState, currentPage, perPage)
            .then(response => {
                //console.log(response);
                setQuotesList(response.quotesList);
                setInterlocutors(response.listInterlocutors);
                setNbPages(response.nbPages);
                setPerPage(response.perPage);
                setLoading(false)
                //setTotalTTC(response.data.totalAmonts.total_ttc);
                //setTotalHT(response.data.totalAmonts.total_ht);
                //setTotalVAT(response.data.totalAmonts.total_vat);
                setListRefresh(false)

            })
            .catch(error => {
                console.log(error);
            })

    }, [quoteState, sort, currentPage, perPage, setFunctionsCallback, listRefresh, getApiContext]);

    /* const quotesFilter = () => {
        if (!showFilter) {
            setShowFilter(true);
        } else {
            setShowFilter(false);
        }
    } */

    const onValidFilter = async (e) => {
        e.preventDefault();

        setQuotesList(null)
        // setLoading(true)

        const values = {
            lastName: lastNameRef.current.value,
            firstName: firstNameRef.current.value,
            society: societyRef.current.value,
            interlocuteur: interlocutorSearch,
            startDate: startDateRef.current.value,
            endDate: endDateRef.current.value,
            quoteRef: quoteFilterRef.current.value
        }

        postApiContext.postQuotesList(values, sort, quoteState)
            .then(response => {
                //console.log(response);
                setQuotesList(response.quotesList)
                // setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })

    }


    const changePerPage = (e) => {
        setPerPage(e.target.value);
        setCurrentPage(1);
    };


    const onSearchSelectChange = (e) => {
        console.log(e.target.value);
        setInterlocutorSearch(e.target.value);
    }

    const transferQuoteToInvoice = (e) => {
        let quoteId = e?.target?.parentElement?.parentElement?.id;
        getApiContext?.transferQuoteToInvoice(quoteId).then(response => {
            //console.log(response);
            navigate("/invoicesList/invoice/" + response.invoice?.id);
        });
    }

    const duplicateQuote = (e) => {
        const dupId = e.target.parentNode.parentNode.id;
        getApiContext.getQuote("", dupId).then(response => {
            if (response?.quote){
                const quote = response?.quote;
                navigate(`/quotesList/quote/${quote?.id}`);
            }
        });
    }

    const contentObject = {
        ContentName: "DEVIS",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "refDevis", title: "Référence Devis", type: "text", ref: quoteFilterRef },
            { name: "startDate", title: "Date de début", type: "text", ref: startDateRef },
            { name: "endDate", title: "Date de fin", type: "text", ref: endDateRef },
            { name: "society", title: "Société", type: "text", ref: societyRef },
        ]
    }

    const stateObject = [
        { valid: "all", name: "tous les devis" },
        { valid: "signed", name: "vos devis signés" },
        { valid: "validated", name: "vos devis finalisés" },
        { valid: "draft", name: "vos brouillons" },
        { valid: "canceled", name: "vos devis annulés" },
        { valid: "transfer", name: "vos devis transférés" }
    ];

    const otherButtonList = [
        {title: "Télécharger", icon: faDownload},
        {title: "Envoyer", icon: faPaperPlane},
        {title: "imprimer", icon: faPrint},
        {title: "dupliquer", icon: faCopy, action: (e) => duplicateQuote(e)},
        {title: "Transférer en facture", icon: faRightLeft, action: (e) => transferQuoteToInvoice(e)}
    ]

    const stateButtonsList = [
        {title: "Finalisé", icon: faCircleCheck, color: "stateGreen", name: "validated"},
        {title: "Signé", icon: faCircleCheck, color: "stateOrange", name: "signed"},
        {title: "Annulé", icon: faCircleCheck, color: "stateRed", name: "canceled"},
    ]


    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setListRefresh(true);
    }

    const openEmptyQuote = () => {
        navigate("/quotesList/quote/")
    }

    const handleOpenValidQuote = (id) => {
        setShowValidPop(true)
        setQuoteValidId(id)
    }

    const handleOpenQuote = () => {
        navigate(`/quotesList/quote/${quoteValidId}`)
    }

    const objectButtons = [
        {title: "Nouveau devis", className: "", icon: faFileMedical, action: openEmptyQuote, showByRoleId: true},
    ]
    const sortOptions = {
        options: [
            { value: "refup", content: "Références croissantes" },
            { value: "refdown", content: "Références décroissantes" },
            { value: "dateup", content: "Date de création croissante" },
            { value: "datedown", content: "Date de création décroissante" },
        ]
    }

    const buttonsObject = [
        {text: "Retour", className: "backButton", action: (e) => setShowValidPop(false)},
        {text: "Continuer", className: "continueButton", action: handleOpenQuote},
    ]

    return (
        <>
            <MainHeader headerTitle={"DEVIS"} objectButtons={objectButtons}/>
            {
                showValidPop &&
                <ValidPop bgcAction={(e) => setShowValidPop(false)} showValidPop={showValidPop} title={"Continuer vers ce devis ?"} desc={"Ce devis est verrouillé car il a été transféré en facture, voulez-vous le déverrouiller ?"} buttonsObject={buttonsObject}/>
            }
            {
                loading &&
                <Loader/>
            }

            <section className='listPage'>

                <div className="listPage__filterContainer">
                    <StateFilter setState={setQuoteState} stateObject={stateObject} state={quoteState}/>
                    <SearchComponent
                        refresh={refresh}
                        onSearchSelectChange={onSearchSelectChange}
                        onSearchSubmit={onValidFilter}
                        contentObject={contentObject}
                        interlocutor={interlocutors}
                    />
                    <SortByFilter setSort={setSort} sortOptions={sortOptions}/>
                </div>

                    {quotesList && quotesList.map((quote, k) => {
                        const element = {
                            id: quote.quoteId,
                            valid: quote.quoteValid,
                            state: quote.quoteState,
                            date: quote.quoteDate,
                            ref: quote.quoteRef,
                            subject: quote.quoteSubject,
                            lastName: quote.societyContactLastName,
                            firstName: quote.societyContactFirstName,
                            society: quote.societyContactSociety,
                            totalHt: quote.quoteTotalHt,
                            totalTtc: quote.quoteTotalTtc,
                            directionPath: "/quotesList/quote/",
                            initials: quote.userInitials,
                        }

                        return (
                            <Card roleVerif={roleVerif} key={k} element={element} tableName={"quote"} handleOpenValidItem={handleOpenValidQuote}
                                stateButtonsList={stateButtonsList} setListRefresh={setListRefresh}
                                  deleteEndPoint={"c-deactive-quote"} otherButtonList={otherButtonList}/>
                        )
                    })
                    }
                    <TotalAmonts
                        totalTTC={totalTTC}
                        totalHT={totalHT}
                        totalVAT={totalVAT}
                    />
                    <div className='pagination'>
                        <label htmlFor='perPage'>Éléments par page </label>
                        <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="300">300</option>
                            <option value="500">500</option>
                        </select>

                        <Pagination
                            postsPerPage={perPage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            nbPages={nbPages}
                        />

                    </div>
                    <a href="#!" alt="" className="add-button"><i className="fa-solid fa-plus"></i></a>
            </section>
        </>
);
}

export default QuotesList;
