import {useState, useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import {DeleteApiContext} from "../../context/apiCalls/deleteApiCalls";
import ValidPop from "./popComponents/validPop";
export default function AddFile({ fileType, setFile, extentionFiles, receivedFile, setRefresh, refresh }) {
  // State
  const [fichier, setFichier] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showValidPop, setShowValidPop] = useState(false);

  const deleteApiCall = useContext(DeleteApiContext);

  const handleDeleteButtonClick = () => {
    setShowValidPop(false);
    setFichier(null);
    setSelectedImage(null);
    setFile(null);
    receivedFile = null;
    var path = "";
    extentionFiles.includes("pdf") ? path = "c-delete-cgv" : path = "c-delete-logo";
    deleteApiCall.deleteItem(path, null).then((response) => {
        //console.log(response);
      if (setRefresh){
        console.log(response)
        setRefresh(!refresh);
      }
    })
  };

  function showFileSelected(e) {
    if (e) {
      const extension = e.name.split(".").pop().toLowerCase();
      let error = document.getElementsByClassName("image-error");
      if (extentionFiles.includes(extension)) {

        if (extension === "pdf") {
          let maxSize = 5 * 1024 * 1024;
            if (e.size > maxSize) {

                error[0].innerHTML = "Le fichier est trop lourd";
                console.log(error)
                return;
            }
          const imageUrl = URL.createObjectURL(e);
          setSelectedImage(imageUrl);
          setFichier(e);
          setFile(e);
            return;
        }
        const imageUrl = URL.createObjectURL(e);

        const img = new Image();
        img.src = imageUrl;

        img.onload = function() {
          const width = img.width;
          const height = img.height;

          console.log(`Largeur: ${width}px, Hauteur: ${height}px`);
          if (width > 280 || height > 60) {
            error[0].innerHTML = "L'image est trop grande";
            console.log(error)
          } else {
            setSelectedImage(imageUrl);
            setFichier(e);
            setFile(e);
          }
        };
      } else {
        error[0].innerHTML = "L'image n'est pas au bon format";
        console.log(error)
      }
    }
  }

  const handleDepotFichier = (event) => {
    console.log("wow")
    event.preventDefault();
    const fichierDepose = event.dataTransfer.files[0];

    showFileSelected(fichierDepose)
  };

  const buttonsObject = [
    {text: "Retour", className: "backButton", action: (e) => setShowValidPop(false)},
    {text: "Continuer", className: "continueButton", action: handleDeleteButtonClick},
  ]

  return (
    <>
      {!receivedFile && !selectedImage ? (
          <div className="file-input-container dropzone" title={extentionFiles.includes('pdf') ? "Format accepté : PDF" : "Formats acceptés : PNG, JPG, JPEG"}>
            <input
                id={`fileInput-${fileType}`}
                type="file"
                accept={extentionFiles.map((ext) => `.${ext}`).join(",")}
                onDrop={handleDepotFichier}
                onChange={(e) => showFileSelected(e.target.files[0])}
                style={{ display: "none" }}
            />
            <label htmlFor={`fileInput-${fileType}`} className="fileLabel">
              <div>
                <p><FontAwesomeIcon className="fileIcon" icon={faFileArrowDown}/> Glissez ou sélectionnez</p><p
                  className={"fileLabelSpan"}>{extentionFiles.includes("pdf") ? "vos cgv" : " votre logo"}</p>
              </div>
              <div>
                <p className={"image-error"}></p>
                <p className={"fileLabelSpan"}>{!extentionFiles.includes('pdf') ? "(Taille max: 280x60 pixels)" : "(Taille max: 5Mo)"}</p>
              </div>
            </label>
          </div>

      ) : (
          <>
            {showValidPop &&
              <ValidPop title={"Suppression"} desc={"Êtes-vous sûr de vouloir supprimer le fichier ?"} showValidPop={showValidPop} buttonsObject={buttonsObject}/>}
            <div className="fileZone dropzone">
              {!extentionFiles.includes("pdf") ? (
                  <img className="showSelectedImage"
                // ici lors du lancement il faudra remplacer ca par l'emplacement du dossier sur le serveur
                src={selectedImage ? selectedImage : `http://localhost/CAPBOXV2-SERVER/uploads/imagesFiles/${receivedFile}`}
                alt="logo de l'entreprise"
              />
            ) : (
              <>
                <div className="showFileName" onClick={window.open}>
                  <FontAwesomeIcon className="fileNameIcon" icon={faFilePdf} />
                  <p className="fileName">{fichier?.name ? fichier?.name : receivedFile}</p>
                </div>
              </>
            )}
              <div>
                <p onClick={() =>setShowValidPop(true)} className="fileEditButton">Supprimer</p>
                <p className={"image-error"}></p>
              </div>
          </div>
        </>
      )}
    </>
  );
}
