import Order from "../routes/order/Order";
import OrderList from "../routes/order/OrderList";
import PrivateRouter from "../privateRouter";
const roles = ["1", "2", "3"];

export const orderPaths = [
    {
        path: "/ordersList",
        element: <PrivateRouter roleList={roles}><OrderList /></PrivateRouter>,
    },
    {
        path: "/ordersList/order/:id",
        element: <PrivateRouter roleList={roles}><Order /></PrivateRouter>,
    },
    {
        path: "/ordersList/order",
        element: <PrivateRouter roleList={roles}><Order /></PrivateRouter>,
    }

]