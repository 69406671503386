export default function UnderLineSelect({sortOptions, setSort, label, selectClassName, action, optionClassName}) {

    return (
        <>
            <div className="underLineSelect">
                {
                    label &&
                    <label className="underLineSelect__label" htmlFor="sortFilterSelect">{label}</label>
                }
                    <select className={`underLineSelect__select ${selectClassName}`} id="sortFilterSelect" onChange={action}>
                        {sortOptions && sortOptions.options.map((option, k) => (
                            <option className={`underLineSelect__select_option ${optionClassName}`} key={k} value={option.value}>{option.content}</option>
                        ))}
                    </select>
            </div>
        </>
    )
}