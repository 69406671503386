export default function Loader() {

    return (
        <>
            <div className="loaderContainer">
                <div className="loaderContainer__loader"></div>
            </div>
        </>
    )

}