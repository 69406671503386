import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/appContext';
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";


export default function ProspectPopList() {

    const appContext = useContext(AppContext);
    const [prospect, setProspect] = useState([]);

    useEffect(() => {

        const limit = 10;


        axios.get(`${process.env.REACT_APP_CONTACT_PROSPECT_LIST_GET}${appContext.idContact}&limit=${limit}`)
            .then(response => {
                setProspect(response.data.listProspectings);
                appContext.setProspectValue(response.data.listProspectings);
                appContext.setReload(false)
                // console.log("log de listProspectings", response.data.listProspectings);
            });

    }, [appContext]);

    const closePop = () => {
        appContext.setBlur(false);
        appContext.setShowProspectListPopup(false);
        window.removeEventListener('scroll', window.monEcouteurScroll);
    }

    const editProspect = (e) => {
        appContext.setIdprospect(e);
        appContext.setShowEditProspectPopup(!appContext.showEditProspectPopup);
        appContext.setBlur(true);
        appContext.setShowProspectListPopup(false);
    }


    return (

        <div className='tablePop'>
            <h3 className='titlePop'>Liste des prospects</h3>
            <div className='borderForm'>
                <table className='tableForm'>
                    <thead>
                        <tr>
                            <th className='thForm th1'>Appel</th>
                            <th className='thForm th2'>Rendez-vous</th>
                            <th className='thForm th3'>Relance</th>
                            <th className='thForm th4'>Commentaire</th>
                            <th className='thForm th5'>Modifier</th>
                        </tr>
                    </thead>
                    <tbody className='tbody'>
                        {prospect &&
                            prospect.map((element, index) => {
                                return (
                                    <tr className='tr' key={index} >
                                        <td className='tdForm td1'>{element?.call ? element?.call : "vide"}</td>
                                        <td className='tdForm td2'>{element?.meeting ? element?.meeting : "vide"}</td>
                                        <td className='tdForm td3'>{element?.dunning ? element?.dunning : "vide"}</td>
                                        <td className='tdForm td4'>{element?.comment ? element?.comment : "comment vide"}</td>
                                        <td className='tdEdit tdForm'><FontAwesomeIcon onClick={() => { editProspect(element.id) }} className='editIcon' icon={faPenToSquare} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <p className='pop-up-cancel' onClick={closePop}>fermer</p>

        </div>
    )

}