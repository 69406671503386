import "../../../../styles/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Decimal from "decimal.js";
import {useEffect, useRef, useState} from "react";
import {faAngleDown, faAngleUp, faTrashCan} from "@fortawesome/free-solid-svg-icons";

function QuoteLine(props) {
    const {onLineChange} = props;
    const [totalHT,setTotalHT] = useState(props.line.unitPrice * props.line.quantity|| 0);
    const [quantityRef,setQuantityRef] = useState(props.line.quantity);
    const [unitPriceRef,setUnitPriceRef] = useState(props.line.unitPrice);
    const idRef= useRef(props.id);
    const onLineChangeRef = useRef(onLineChange);

    useEffect(() => {
        setQuantityRef(props.line.quantity);
        setUnitPriceRef(props.line.unitPrice);
        idRef.current = props.id;
        onLineChangeRef.current = onLineChange
    }, [props.line.quantity, props.line.unitPrice, props.id, onLineChange]);

    useEffect(() => {
        const quantityDecimal = new Decimal(quantityRef || 0);
        const unitPriceDecimal = new Decimal(unitPriceRef || 0);
        const totalDecimal = unitPriceDecimal.times(quantityDecimal);
        setTotalHT(totalDecimal.toNumber());
        onLineChangeRef.current(idRef, "totalHT", totalDecimal.toNumber());
    }, [quantityRef, unitPriceRef]);

    const onChange = (e) => {
        props.onLineChange(props.id, e.target.name, e.target.value);
    }



    return (
        <div className="line">
            <input type="hidden" name={"idLine" + props.grpId + "_" + props.id} />
            <div className="line-component2" style={{ display: "flex", flexDirection: "column" }}>
                <div className="options">
                    <div className="buttons">
                        <p className="num">{props?.line.numero || 1}</p>
                        <a className="cart" href="#!" onClick={() => { props.openCatalogues(props.index, props.grpIndex) }}>
                            <img src={require("../../../../assets/images/icons/Picto/Achat.svg").default} alt="" />
                            <p style={{ marginLeft: "3px" }}>Ajouter un article</p>
                        </a>
                        <a className="garbage" href="#!" onClick={() => { props.removeItem(props.index) }}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </a>
                    </div>
                    <div className="tri">
                        <a className="up" href="#!" onClick={() => { props.changeOrderLine(props.line.numero, "up") }}>
                            <FontAwesomeIcon icon={faAngleUp} style={props.line.numero <= 1 && {opacity: 0.3, cursor: 'not-allowed'}}/>
                        </a>
                        <a className="down" href="#!" onClick={() => { props.changeOrderLine(props.line.numero, "down") }}>
                            <FontAwesomeIcon icon={faAngleDown} style={props.line.numero.toString() === props.group.lines.length.toString() && {opacity: 0.3, cursor: 'not-allowed'}}/>
                        </a>
                    </div>
                </div>
                <textarea placeholder="Désignation" title="Désignation" name="name" value={props.line.name || ""} onChange={onChange} ></textarea>
            </div>
            <div className="line-component1">

                <div className="unit">
                    <div>
                        <p>Unité</p>
                        <select name="unitId" value={props.line.unitId} onChange={onChange}>
                            { props.listUnit &&
                                props.listUnit.map((unit, i) => {
                                    return (
                                        <option key={i} value={unit.id}>{unit.unit_name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="unit">
                        <p>Quantité</p>
                        <input type="number" placeholder="Quantité" name="quantity" title="Quantité" onWheel={(e) => e.target.blur()} value={props.line.quantity} onChange={onChange}/>
                    </div>
                    <div className="unit">
                        <p>Prix&nbsp;HT</p>
                        <input type="number" placeholder="Prix HT" name="unitPrice" title="Prix HT" onWheel={(e) => e.target.blur()} value={props.line.unitPrice} onChange={onChange}/>
                    </div>
                    <div className="unit">
                        <p>TVA</p>
                        <select name="vatId" value={props.line.vatId || 0} onChange={onChange}>
                            {
                                props.listTva.map((tva, i) => {
                                    return (
                                        <option key={i} value={tva.id}>{tva.rate} %</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="options">
                    <div className="line-amount">
                        <p className="ht">MONTANT HT : <span>{totalHT} €</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuoteLine;