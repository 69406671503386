import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import ValidPop from "../../addOns/popComponents/validPop";
import {useState} from "react";

export default function LinkedDepositList({onSubmit, linkDeposit, deleteDeposit, parentPath, createDeposit}) {

    const [showValidPop, setShowValidPop] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleDepositItemAdd = (e) => {
        onSubmit(e, "");
        createDeposit(e);
    }

    if (parentPath) {
        localStorage.setItem("parentPath", parentPath);
    }

    const handleDeleteDeposit = (e, id) => {
        e.stopPropagation();
        setIdToDelete(id);
        setShowValidPop(true);
    }

    const test = (e) => {
        console.log("suppression", e.target.parentNode);
        deleteDeposit(idToDelete);
        setShowValidPop(false);
    }

    const buttonsObject = [
        {text: "Annuler", className: "backButton", action: (e) => setShowValidPop(false)},
        {text: "Supprimer", className: "continueButton", action: (e) => test(e)},
    ]

    return (
        <div className="linkedDepositList">
        {
            showValidPop &&
        <ValidPop bgcAction={(e) => setShowValidPop(false)} showValidPop={showValidPop} title={"Confirmer la suppression ?"} desc={"Voulez-vous vraiment supprimer cet acompte ?"} buttonsObject={buttonsObject}/>
        }
            <header className={"linkedDepositList__header"}>
                <h3>ACOMPTES LIÉS</h3>
                <span className={"linkedDepositList__header_amount"}>
                    <p>{linkDeposit?.reduce((acc, deposit) => parseFloat(acc) + (parseFloat(deposit.totalTtc) || 0), 0).toFixed(2)}€</p>
                    <div className="v-line-blue"></div>
                    <p>{linkDeposit?.reduce((acc, deposit) => parseFloat(acc) + (parseFloat(deposit.rate) || 0), 0).toFixed(2)}%</p>
                </span>
            </header>
            <article className={"linkedDepositList__article"}>
            {
                linkDeposit?.map((deposit, i) => {
                    //console.log(deposit);
                    return (
                        <article key={i} className={"linkedDepositList__article_item"} onClick={(e) => onSubmit(e, `/depositsList/deposit/${deposit.id}`)}>
                            <header>
                                <span> N° {deposit.ref}</span>
                            </header>
                            <section>
                                <div>
                                    {deposit.paymentDate && <span> {deposit.paymentDate.split('-').reverse().join('/') }</span>}
                                    {deposit.paymentDate && <div className="v-line"></div>}
                                    <span> {deposit.totalTtc || 0} €</span>
                                    <div className="v-line"></div>
                                    <span
                                        className={"linkedDepositList__article_item_rate"}> {deposit.rate || 0} %</span>
                                </div>
                                <p onClick={(e) => {
                                    handleDeleteDeposit(e, deposit.id)
                                }}><FontAwesomeIcon icon={faTrashCan} /></p>
                            </section>

                        </article>
                    )
                })
            }
                <article className={"linkedDepositList__article_item addItem"} onClick={(e) => handleDepositItemAdd(e)}>
                    <p className={"addText"}>Ajouter un acompte</p>
                    <FontAwesomeIcon className="addPlus" icon={faPlus} />
                </article>
            </article>
        </div>
    );
}