import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { fr } from 'date-fns/locale';
import { useEffect } from "react";
import {faClock} from "@fortawesome/free-solid-svg-icons";

export default function MarginCard({
    index,
    delivery,
    openMargin,
}) {

    useEffect(() => {

    }, [])

    return (
        <div className="cards" onClick={() => openMargin(delivery.id)} key={index}>

            <div className="cards__titleContainer">
                <p className="cards__titleContainer_title">BON N° <span className="cards__titleContainer_title_span">{delivery.ref} - {delivery.subject}</span></p>
            </div>
            <div className="cards__name">
                {delivery.contact &&
                    <p>{delivery.contact.lastName} {delivery.contact.firstName} {delivery.contact.society ? `- ${delivery.contact.society}` : ""}</p>
                }
            </div>
            <div className="cards__content">
                {delivery.date &&
                    <FontAwesomeIcon className="cards__content_icon" icon={faClock} />}
                {delivery.date &&
                    <p className="cards__content_date">{format(new Date(delivery.date), 'dd MMMM yyyy', { locale: fr })}</p>}
            </div>
        </div>
    )
}