import {useEffect, useState} from "react";

export function useEventScroll() {
    const [hasScrolled, setHasScrolled] = useState(false);

    const handleScroll = () => {
        setHasScrolled(window.scrollY > 0);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasScrolled]);

    return {
        hasScrolled,
    };
}