import ContactsListPop from '../../components/customer/contacts/ContactsListPop';
import CataloguesListPop from '../../components/customer/catalogues/CataloguesListPop';
import MailPop from '../../components/addOns/MailPop';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useCallback, useEffect, useRef} from 'react';
import CreditGroup from '../../components/customer/credits/creditGroup';
import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import {faArrowLeft, faCopy, faPaperPlane, faPlus, faPrint,} from "@fortawesome/free-solid-svg-icons";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import {useAuthState} from "../../context/authContext";
import {getCurrentDate} from "../../utils/utils";
import ContactInfosPanel from "../../components/customer/editingPages/informations/ContactInfosPanel";

function Credit() {
    const appContext = useContext(AppContext);
    let { id } = useParams()

    let navigate = useNavigate();
    const [listTva, setListTva] = useState([]);
    const [contact, setContact] = useState();
    const [contacts, setContacts] = useState([]);
    const nameRef = useRef("");
    const [discount, setDiscount] = useState();
    const [depositPrice, setDepositPrice] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    const [interlocutorsList, setInterlocutorsList] = useState([]);
    const [interlocutor, setInterlocutor] = useState();
    const [comment, setComment] = useState();
    const [catalogues] = useState([]);
    const [showCatalogues, setShowCatalogues] = useState(false);
    const [grpIndexSelected, setGrpIndexSelected] = useState();
    const [lineSelected, setLineSelected] = useState();
    const [lineIndexSelected, setLineIndexSelected] = useState();
    const [listUnit, setListUnit] = useState([]);
    const [subTotalHT, setSubTotalHT] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalTTC, setTotalTTC] = useState(0);
    const [firstShowModal, setFirstShowModal] = useState(false);
    const [depositPercent, setDepositPercent] = useState();
    const [showModal, setShowModal] = useState(false);
    const [ref, setRef] = useState();
    const refRef = useRef("");
    const [credit, setCredit] = useState({});
    const [showCalendar, setShowCalendar] = useState(false);
    const [date, setDate] = useState();
    const dateRef = useRef(getCurrentDate());
    const [showMail, setShowMail] = useState(false);

    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);
    const {roleId} = useAuthState();

    useEffect(() => {
        getApiContext.getCredit(id)
            .then(response => {
                //console.log("response", response);
                setCredit(response.credit)
                setRef(response.credit.ref);
                nameRef.current.value = response.credit.subject || "";
                setListTva(response.listTva);
                setInterlocutorsList(response.listInterlocutors);
                setComment(response.credit.comment);
                if (response.credit.creditDate !== undefined || response.credit.creditDate) {

                    let day = response.credit.creditDate.split("-")[2];
                    let month = response.credit.creditDate.split("-")[1];
                    let year = response.credit.creditDate.split("-")[0];

                    let newDate = month + "/" + day + "/" + year;
                    //console.log("newDate", newDate);
                    setDate(new Date(newDate));
                }
                setDate(new Date());
                setListUnit(response.listUnit);
                setDiscount(response.credit.discount);
                setContact(response.credit.contact);
            })
            .catch(error => {
                console.log("err", error);
            })

    }, [id, getApiContext]);

    function removeGroup(index) {
        let newCredit = { ...credit };
        const filteredGroups = newCredit.groups.filter((grp, i) => {
            return i !== index;
        });
        newCredit.groups = filteredGroups;
        setCredit(newCredit);
        updateCredit();
    }

    function changeOrderGroup(index, direction) {
        let newCredit = { ...credit };
        let orderGroups = newCredit.groups;
        if (direction === "up") {
            let grp = orderGroups[index];
            orderGroups.splice(index, 1);
            orderGroups.splice(index - 1, 0, grp);
        } else {
            let grp = orderGroups[index];
            orderGroups.splice(index, 1);
            orderGroups.splice(index + 1, 0, grp);
        }

        newCredit.groups = orderGroups;
        setCredit(newCredit);
    }

    function openCatalogues(index, grpIndex) {
        setShowCatalogues(true);
        setGrpIndexSelected(grpIndex);
        setLineIndexSelected(index);
        setLineSelected(credit.groups[grpIndex].lines[index]);
    }

    const updateCredit = useCallback((updatedGroup = undefined, newDiscount = undefined, newDepositPercent = undefined,
        updatedCredit = undefined, estimatedHours = undefined, hourlyCosts = undefined, purchaseCosts = undefined,
        fixedCosts = undefined, variableCosts = undefined) => {
        setCredit(credit => {
            let newCredit;
            if (updatedCredit !== undefined) {
                newCredit = { ...updatedCredit };
            } else {
                newCredit = { ...credit };
            }

            const newGroup = [...newCredit.groups];
            if (updatedGroup !== undefined) {
                newGroup[updatedGroup.index] = updatedGroup;
                newCredit = { ...newCredit, groups: newGroup };
            }
            let subtotalHT = 0;
            newGroup.forEach((grp) => {
                subtotalHT += parseFloat(grp.totalHT);
            });
            setSubTotalHT(subtotalHT);
            let newdiscount = 0;
            let newdiscountPrice;
            if (newDiscount !== undefined) {
                newdiscount = newDiscount;
                setDiscount(newDiscount);
                newdiscountPrice = subtotalHT * (parseFloat(newDiscount) / 100);
            } else {
                newdiscount = discount;
                if (newdiscount !== undefined) {
                    newdiscountPrice = subtotalHT * (newdiscount / 100);
                } else {
                    newdiscountPrice = 0;
                }
            }
            setDiscountPrice(newdiscountPrice);
            let totalht = subtotalHT.toFixed(2) - newdiscountPrice.toFixed(2);
            setTotalHT(totalht);

            let newListTva = [...listTva];

            for (let k = 0; k < newListTva.length; k++) {
                newListTva[k].value = 0;
            }
            newCredit.groups.forEach((grp) => {
                grp.lines.forEach((line) => {
                    for (let k = 0; k < newListTva.length; k++) {
                        if (newListTva[k].id === line.vatId) {
                            let lineTva = ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) - ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) * (newdiscount / 100))) * newListTva[k].rate / 100;
                            newListTva[k].value = newListTva[k].value + lineTva;
                        }
                    }
                })
            });

            setListTva(newListTva);

            let amountTotalTva = 0;
            for (let k = 0; k < newListTva.length; k++) {
                amountTotalTva += newListTva[k].value;
            }
            let totalttc = parseFloat(totalht.toFixed(2)) + parseFloat(amountTotalTva.toFixed(2));
            setTotalTTC(totalttc);

            let newdepositPercent;
            let newDepositPrice;
            if (newDepositPercent !== undefined) {
                newdepositPercent = newDepositPercent;
                setDepositPercent(newdepositPercent);
                newDepositPrice = totalttc * newdepositPercent / 100;
                setDepositPrice(newDepositPrice);
            } else {
                newdepositPercent = depositPercent;
                newDepositPrice = totalttc * newdepositPercent / 100;
                setDepositPrice(newDepositPrice);
            }

            if (estimatedHours !== undefined) {
                newCredit = { ...newCredit, estimatedHours: estimatedHours };
            }
            if (hourlyCosts !== undefined) {
                newCredit = { ...newCredit, averageHourlyRateHT: hourlyCosts };
            }
            if (purchaseCosts !== undefined) {
                newCredit = { ...newCredit, purchaseCostsHT: purchaseCosts };
            }
            if (fixedCosts !== undefined) {
                newCredit = { ...newCredit, fixedCosts: fixedCosts };
            }
            if (variableCosts !== undefined) {
                newCredit = { ...newCredit, variableCosts: variableCosts };
            }
            return newCredit;
        });
    }, [depositPercent, discount, listTva, setDepositPrice]);

    /*const printCredit = () => {
        window.open(`${process.env.REACT_APP_CREDIT_PRINT}${credit.id}`, "_blank");
    }*/


    const onChangeInterlocutor = (e) => {
        let newInterlocutor = e.target.value;
        setInterlocutor(newInterlocutor);
        let newCredit = { ...credit, userId: newInterlocutor };
        setCredit(newCredit);
    }

    function duplicate() {
        //window.open(`${process.env.REACT_APP_CREDIT_DUP}${credit.id}`, '_self');
    }

    const onValidCredit = async (e) => {
        e.preventDefault();

        const values = {
            ref: ref,
            destinataire: contact?.id,
            date_emission: date?.toLocaleDateString(),
            titre: nameRef?.current?.value || "",
            interlocuteur: credit.userId,
            Hremise1: discount,
            comment: comment,
            heuresTotFact: credit?.estimatedHours,
            tauxHoraireMoyen: credit?.averageHourlyRateHT,
            coutsAchat: credit?.purchaseCostsHT,
            fraisFixes: credit?.fixedCosts,
            fraisVariables: credit?.variableCosts,
            Htotalht1: totalHT,
            Htotalttc: totalTTC,
            groups: credit?.groups,
        };
        console.log("log de value ", values);
        postApiContext.postCredit(id, values)
            .then(response => {
                console.log("log du response de post ", response);
                navigate("/creditList");
            })
            .catch(error => {
                console.log(error);
            })
    }
    const handleClickModal = () => {
        appContext.setTest(true)
        if (firstShowModal === false) {
            getApiContext.getContactsList()
                .then(response => {
                    console.log("response", response);
                    setContacts(response.listContacts);
                });
            setFirstShowModal(true);
        }
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    function handleSelectedContact(index) {
        contacts.forEach(element => {
            if (element.id === index) {
                setContact(element);
            }
        });
        closeModal();
    }

    const addGrp = () => {
        let newCredit = { ...credit }
        let id = generateId();
        newCredit.groups.push(
            {
                id: id,
                creditId: 0,
                title: "",
                totalHT: 0,
                lines: [
                    {
                        id: "New0",
                        designation: "",
                        unit: "",
                        quantity: "1.00",
                        unitPrice: "0.00",
                        tva: 8,
                        totalHT: "0.00",
                        unitId: 1
                    },
                ]
            });
        setCredit(newCredit);
        updateCredit();
    }

    const generateId = () => {
        let prefixIdLine = "New";
        let newIdLig = 0;

        credit.groups.forEach((grp) => {
            while (grp.id === prefixIdLine + newIdLig) {
                newIdLig += 1;
            }
        });
        return prefixIdLine + newIdLig;
    }
    const onChangeInput = (e) => {
        let value = e.target.value;
        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (e.target.name === "discount") {
            if (validated) {
                setDiscount(value);
            } else {
                value = 0;
                setDiscount(value);
            }
            updateCredit(null, value, null);
        } else if (e.target.name === "deposit") {
            if (validated) {
                setDepositPercent(value);
            } else {
                value = 0;
                setDepositPercent(value);
            }
            updateCredit(null, null, value);
        }
    }
    /*const changeAllTva = (i) => {
        switch (i) {
            case 1:
                setAllTva(3);
                break;
            case 2:
                setAllTva(5);
                break;
            case 3:
                setAllTva(2);
                break;
            case 4:
                setAllTva(1);
                break;
            default:
                setAllTva(8)
                break;
        }
    }*/
    const formatInput = (e) => {
        if (e.target.name === "discount") {
            var newDiscount = e.target.value;
            newDiscount = parseFloat(parseFloat(newDiscount.replace(',', '.')).toFixed(2));
            if (isNaN(newDiscount)) {
                newDiscount = "0.00";
            } else {
                newDiscount = parseFloat(newDiscount).toFixed(2);
            }
            setDiscount(newDiscount);
        } else if (e.target.name === "deposit") {
            var newDeposit = e.target.value;
            newDeposit = parseFloat(parseFloat(newDeposit.replace(',', '.')).toFixed(2));
            if (isNaN(newDeposit)) {
                newDeposit = "0.00";
            } else {
                newDeposit = parseFloat(newDeposit).toFixed(2);
            }
            setDepositPercent(newDeposit);
        }
    }

    function handleCloseCatalogues() {
        setShowCatalogues(false);
    }
    function handleSelectedArticle(article) {
        let item = article;
        setShowCatalogues(false);
        setCredit(credit => {
            let newCredit = { ...credit };
            let newLine = { ...lineSelected };
            newLine.name = item.name;
            newLine.quantity = 1;
            newLine.unitPrice = item.basePrice;
            newCredit.groups[grpIndexSelected].lines[lineIndexSelected] = newLine;
            console.log(newCredit);
            return newCredit;
        });
    }

    function closeMailPop() {
        setShowMail(false);
    }

    const closePopup = () => {
        setShowCalendar(false);
        document.getElementById('abso').classList.remove("abso")
    };

    const headerButtonsList={
        dropdown: {
            imprimer:{content: "Imprimer", handleClick: () => console.log("hello"),
                icon: faPrint, redir: false },
            envoyer:{ content: "Envoyer", handleClick: () => setShowMail(true),
                icon: faPaperPlane, redir: false },
            dupliquer:{ content: "Dupliquer", handleClick: () => duplicate(),
                icon: faCopy, redir: false },
        },
        mainButton: {
            buttonIcon: faArrowLeft,
            buttonText: id ? "Sauvegarder" : "Créer le devis",
            buttonAction: (e) => onValidCredit(e),
        },
    }

    return (
        <>
            <ContactsListPop
                show={showModal}
                setShowModal={setShowModal}
                data={contacts}
                handleSelectedContact={handleSelectedContact}
            />
            <CataloguesListPop
                show={showCatalogues}
                handleCloseCatalogues={handleCloseCatalogues}
                handleSelectedArticle={handleSelectedArticle}
                data={catalogues}
            />
                <MailPop
                    show={showMail}
                    creditId={credit.id}
                    closeMailPop={closeMailPop}
                />
                <ContactsListPop />
            <EditingMainHeader roleId={roleId}
                               headerTitle="AVOIR" dateRef={dateRef} setShowCalendar={setShowCalendar} showCalendar={showCalendar}
                               destination={"/creditList"} endPoint={"c-deactive-credit"}
                               id={id} refRef={refRef} defaultRef={ref}
                               headerButtonsList={headerButtonsList}
                               backRedirect="/quotesList"
            />
                    <div className="contract listPage">
                        <div id='abso' onClick={closePopup} className='abso'></div>
                        <div className="block">
                            <div className="editingInfos">
                                <div className="editingInfos__titleContainer">
                                    <h2 className="editingInfos__titleContainer_title">informations</h2>
                                    <hr className="editingInfos__titleContainer_line"/>
                                </div>
                                <div className='editingInfos__firstSection'>
                                    <div className="editingInfos__firstSection_nameContainer">
                                        <h3 className="editingInfos__firstSection_nameContainer_title">Intitulé de l'avoir :</h3>
                                        <input className="editingInfos__firstSection_nameContainer_input" type="text"
                                               ref={nameRef}
                                               placeholder="EXEMPLE DE DEPENSE"/>
                                    </div>
                                    <div className="editingInfos__firstSection_interContainer">
                                        <h3 className="editingInfos__firstSection_interContainer_title">Affaire suivie
                                            par :</h3>
                                        <select onChange={onChangeInterlocutor} value={interlocutor?.id}
                                                className="editingInfos__firstSection_interContainer_select"
                                                name="interlocutorSelect"
                                                id="interlocutorSelect">
                                            {
                                                interlocutorsList && interlocutorsList.length > 0 &&
                                                interlocutorsList.map((interlocutor, index) => {
                                                    return (
                                                        <option
                                                            className="editingInfos__firstSection_interContainer_option"
                                                            key={index}
                                                            value={interlocutor.id}>{interlocutor.first_name} {interlocutor.last_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <ContactInfosPanel contact={contact} handleClickModal={handleClickModal}/>
                            </div>
                            <div className="contract-body">
                                <div className="container">
                                        {
                                            credit?.groups
                                                ?.sort((a,b) => a.numero - b.numero)
                                                .map((group, i) => {
                                                return (
                                                    <CreditGroup
                                                        key={i}
                                                        index={i}
                                                        group={group}
                                                        id={group.id}
                                                        numero={i + 1}
                                                        designation={(group.title === undefined) ? "" : group.title}
                                                        lines={group.lines}
                                                        listTva={listTva}
                                                        listUnit={listUnit}
                                                        creditId={credit.id}
                                                        updateCredit={updateCredit}
                                                        openCatalogues={openCatalogues}
                                                        removeGroup={removeGroup}
                                                        changeOrderGroup={changeOrderGroup}
                                                        credit={credit}
                                                    />
                                                )
                                            })
                                        }
                                    <div className="new-group" onClick={addGrp}>
                                        <p>Ajouter un nouvel ensemble de lignes au avoirs</p>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </div>
                                    <div className="comment">
                                        <h3>COMMENTAIRE AJOUTE AU DEVIS</h3>
                                        <textarea placeholder="Description" value={comment || ""}
                                                  onChange={(e) => setComment(e.target.value)}></textarea>
                                    </div>


                                </div>
                            </div>
                            <div className="aside">
                                <div className="marginPrice">
                                    <div className="marginPrice__bold-block bold-block sub-total-ht">
                                        <p>Sous total HT</p>
                                        <p>{parseFloat(subTotalHT).toFixed(2)} €</p>
                                    </div>
                                    <div className="discount">
                                        <div>
                                            <p>Remise Globale</p>
                                            <input type="number" placeholder="0,00" step="0.01" name='discount'
                                                   onWheel={(e) => e.target.blur()} value={discount || ""}
                                                   onChange={onChangeInput} onBlur={formatInput}/> %
                                        </div>
                                        <p>{parseFloat(discountPrice).toFixed(2)} €</p>
                                    </div>
                                    <div className="marginPrice__bold-block bold-block total-ht">
                                        <p>Total HT</p>
                                        <p>{parseFloat(totalHT).toFixed(2) || 0.00} €</p>
                                    </div>
                                    <div className="marginPrice__bold-block bold-block total-ttc">
                                        <p>Total net TTC</p>
                                        <p>{parseFloat(totalTTC).toFixed(2)} €</p>
                                    </div>
                                    <div className="payment">
                                        <h3>MODALITÉS DE PAIEMENT</h3>
                                        <p>Acompte</p>
                                        <div>
                                            <input type="text" placeholder="0,00" name="deposit"
                                                   value={depositPercent || 0.00} onChange={onChangeInput}
                                                   onBlur={formatInput}/>
                                            <p>% soit {parseFloat(depositPrice).toFixed(2)}€ TTC</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    )


}

export default Credit