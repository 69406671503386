import UnderLineSelect from "../selectComponents/underlineSelect";

export default function SortByFilter({
                                         sortOptions,
                                         setSort
                                     }) {

    return (
        <div className="sortFilter">
            <UnderLineSelect action={(e) => setSort(e.target.value)} label={"Trier par :"} sortOptions={sortOptions}/>
        </div>
    );
}