import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPlus} from "@fortawesome/free-solid-svg-icons";
import InvoiceLine from "./invoiceLine";
import {useEffect, useState} from "react";
import {generateId} from "../../../../../utils/generateId";
import Decimal from "decimal.js";
import DeletButton from "../../../../addOns/deletButton";

export default function InvoiceGroup({deleteGroupByNotId, unitsList, handleChangeGroup, updateLines, vatList, group, updateGroups, setRefresh,deleteLineByNotId, handleMoveGroup }) {


    const [lines, setLines] = useState([])
    const [totalGroup, setTotalGroup] = useState(0);
    useEffect(() => {
        setLines(group?.lines)
    }, [group])
    // console.log(lines)

    useEffect(() => {
        let total = new Decimal(0);
        lines.forEach(line => {
            const quantityDecimal = new Decimal(line.quantity);
            const unitPriceDecimal = new Decimal(line.unitPrice);
            const totalLine = quantityDecimal.times(unitPriceDecimal);
            total = total.plus(totalLine);
        });
        // console.log(total.toNumber())
        setTotalGroup(total.toNumber());
    }, [lines]);

    const addLine = () => {
        let newGroup = { ...group }
        let id = generateId(lines);
        newGroup.lines.push(
            {
                id: id,
                invoiceGroupId: group ? group.id : null,
                itemId: null,
                name: "",
                numero: (lines.length + 1).toString(),
                quantity: 0,
                unitId: 0,
                unitPrice: 0,
                vatId: vatList[0]?.id,
                total: 0
            }
        );
        updateGroups(newGroup);
    }

    const handleMoveLine = (index, direction) => {
        index = parseInt(index);
        if (direction === "up" && index > 1) {
            const lineTargetIndex = lines.findIndex(line => line.numero.toString() === index.toString());
            const lineBeforeIndex = lines.findIndex(line => line.numero === (index - 1).toString());
            if (lineTargetIndex !== -1 && lineBeforeIndex !== -1) {
                const newLines = [...lines];
                newLines[lineBeforeIndex].numero = index.toString();
                newLines[lineTargetIndex].numero = (index - 1).toString();

                setLines(newLines);

            }
        } else if (direction === "down" && index < lines.length) {
            const lineTargetIndex = lines.findIndex(line => line.numero === index.toString());
            const nextIndex = (index + 1).toString();
            const lineAfterIndex = lines.findIndex(line => line.numero === nextIndex);

            if (lineTargetIndex !== -1 && lineAfterIndex !== -1) {
                const newLines = [...lines];
                newLines[lineAfterIndex].numero = index.toString();
                newLines[lineTargetIndex].numero = nextIndex;

                setLines(newLines);
            }
        }
    };

    const handleLineChange = (element, id) => {
        const newLines = [...lines];
        const index = newLines.findIndex(line => line.id === id);
        const name = element.target.name;
        // console.log("newLines", newLines)
        newLines[index][name] = element.target.value;
        setLines(newLines);
        updateLines(newLines[index]);
    }

    const handelGetGroupId = (id) => {
        deleteLineByNotId(id, group.id)
    }

    const validPopContent = {
        title: "Suppression",
        desc: `Êtes-vous sûr de vouloir supprimer le groupe "${group.title}" ?`,
    }

    return (
        <>

            <div className='invoiceGroup'>
                <div className="invoiceGroup__groupContainer">
                    <div className="invoiceGroup__groupContainer_groupHeader">
                        <div className="invoiceGroup__groupContainer_groupHeader_firstPart">
                            <div className="invoiceGroup__groupContainer_groupHeader_firstPart_arrowGroup">
                                <p onClick={() => { handleMoveGroup(group?.numero, "up") }}>
                                    <FontAwesomeIcon
                                        className='invoiceGroup__groupContainer_groupHeader_firstPart_arrowGroup_arrow'
                                        icon={faChevronUp}/>
                                </p>
                                <p onClick={() => { handleMoveGroup(group?.numero, "down") }}>
                                    <FontAwesomeIcon
                                        className='invoiceGroup__groupContainer_groupHeader_firstPart_arrowGroup_arrow'
                                        icon={faChevronDown}/>
                                </p>
                            </div>
                            <input name="title" onChange={(e) => handleChangeGroup(e, group.id)} className='invoiceGroup__groupContainer_groupHeader_firstPart_input' type="text"
                                   placeholder="Nom du groupe d'articles" value={group?.title || ""}/>
                            <DeletButton deleteItemByNotId={deleteGroupByNotId} setRefresh={setRefresh} url={"c-delete-invoice-group"} id={group.id} className={""} validPopContent={validPopContent}/>

                        </div>
                        <p className='invoiceGroup__groupContainer_groupHeader_total'>Montant HT : <span>{totalGroup}</span>€
                        </p>
                    </div>
                    {
                        lines &&
                        lines
                            .slice()
                            .sort((a, b) => parseInt(a.numero) - parseInt(b.numero))
                            .map((line, index) => {
                                return (
                                    <InvoiceLine deleteLineByNotId={handelGetGroupId} setRefresh={setRefresh} linesLength={lines?.length} unitsList={unitsList} handleLineChange={handleLineChange} handleMoveLine={handleMoveLine} vatList={vatList} line={line}
                                                 key={index} index={index}/>
                                )
                            })
                    }
                    <button onClick={addLine} className="invoiceGroup__groupContainer_addLineButton">Ajouter une
                        ligne <FontAwesomeIcon
                            className="invoiceGroup__groupContainer_addLineButton_icon" icon={faPlus}/></button>
                </div>
            </div>
        </>
    )
}