import { useEffect, useRef, useState } from "react";
import MainHeader from "../../../components/addOns/headers/MainHeader";
import SortByFilter from "../../../components/addOns/filterComponent/SortByFilter";
import SearchComponent from "../../../components/addOns/filterComponent/searchComponent";
import DeliveryCard from "../../../components/customer/invoices/delivery/DeliveryCard";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function DeliveriesList() {

    let navigate = useNavigate();

    const [deliveries, setDeliveries] = useState();
    const [reload, setReload] = useState();
    const [sort, setSort] = useState('refup');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DELIVERY_LIST}`)
            .then(response => {
                setDeliveries(response.data.deliveriesList);
            })
            .catch(error => {
                console.log("deliveryList get error:", error);
            })
    }, [])

    const openDelivery = (deliveryId) => {
        navigate("delivery/" + deliveryId);
    }

    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        //setSort("refup");
        setReload(true);
    }

    //const for research component
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const societyRef = useRef();
    const invoiceFilterRef = useRef();
    const deliveryRef = useRef();

    const headerContent = {
        ContentName: "BON DE LIVRAISON",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "society", title: "Société", type: "text", ref: societyRef },
            { name: "refFacture", title: "Référence facture", type: "text", ref: invoiceFilterRef },
            { name: "refDelivery", title: "Référence bon de livraison", type: "text", ref: deliveryRef }
        ]
    }

    useEffect(() => {

        //setMargins(null);

        const values = {
            firstName: firstNameRef?.current?.value,
            lastName: lastNameRef?.current?.value,
            society: societyRef?.current?.value,
            refInvoice: invoiceFilterRef?.current?.value,
            deliveryRef: deliveryRef?.current?.value,
            sort: sort,
        }
        const customHeaders = {
            'Content-Type': 'application/json'
        };

        axios.post(`${process.env.REACT_APP_DELIVERY_FILTER_LIST}`, values, customHeaders)
            .then(response => {
                setDeliveries(response.data.deliveriesList);
                //setNbPages(response.data.nbPages);
            })
            .catch(error => {
                console.log("erreur :", error);
            })
        setReload(false); 
    }, [ sort, reload])

    const onValidFilter = async (e) => {
        e.preventDefault();
        setReload(true);
    }

    /**
     * options for SortByFilter
     */
    const sortOptions = {
        options: [
            { value: "refup", content: "Références croissantes" },
            { value: "refdown", content: "Références décroissantes" },
            { value: "dateup", content: "Date de création croissante" },
            { value: "datedown", content: "Date de création décroissante" },
        ]
    }

    return (
        <>
            <div className="listPage">
                <MainHeader
                    backRedirect={"/invoicesList"}
                    headerTitle={"Bons de livraison"}
                />

                <SearchComponent
                    refresh={refresh}
                    onSearchSelectChange={""}
                    onSearchSubmit={onValidFilter}
                    contentObject={headerContent}
                    interlocutor={""}
                /> 

                <SortByFilter 
                    sortOptions={sortOptions} 
                    setSort={setSort} 
                    onFilterSubmit={onValidFilter}
                />

                {deliveries &&
                    deliveries?.map((delivery, index) => {
                        return (
                            <DeliveryCard
                                key={index}
                                delivery={delivery}
                                openMargin={openDelivery}
                            />)
                    })}
            </div>
        </>
    );
}
export default DeliveriesList;