import { useEffect, useState } from "react";

export default function DeliveryPrice(props) {
    const [listTva, setListTva] = useState();

    useEffect(() => {
        if(Array.isArray(props.listTva)){
        
            let newListTva = [...props.listTva];
            for (let k = 0; k < newListTva.length; k++) {
                newListTva[k].value = 0;
            }
            props.invoice.groups.forEach((grp, i) => {
                grp.lines.forEach((line, j) => {
                    for (let k = 0; k < newListTva.length; k++) {
                        if (newListTva[k].id === line.vatId) {
                            let lineTva = (parseFloat(line.quantity) * parseFloat(line.unitPrice)) * newListTva[k].rate / 100;
                            newListTva[k].value = newListTva[k].value + lineTva;
                        }
                    }
                })
            });
            setListTva(newListTva);
        }
    }, [props])
    return (
        <div className="price">
            <div className="price__bold-block total-ht">
                <p>Total HT</p>
                <p>{parseFloat(props.invoice?.totalHt).toFixed(2)} €</p>
            </div>
            <div className="price__tva">
                {
                    Array.isArray(listTva) && listTva?.map((tva, i) => {
                        if (tva.id !== 8) {
                            return (
                                <div key={i}>
                                    <p className='tva-rate'>TVA à {tva.rate} %</p>
                                    <p>{parseFloat(tva.value).toFixed(2)} €</p>
                                </div>
                            );
                        }
                        return null;
                    })
                }
            </div>
            <div className="price__bold-block total-ttc">
                <p>Total net TTC</p>
                <p>{parseFloat(props.invoice?.totalTtc).toFixed(2)} €</p>
            </div>
        </div>
    );
}