import axios from "axios";
import {useEffect, useState, useContext} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {format} from "date-fns";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";

import {realManpowerCalc, percentMarginCalc, theoreticalMarginCalc, totalCostHTCalc} from "../../utils/calculations";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import MarginInfos from "../../components/customer/margins/MarginInfos";
import MarginBody from "../../components/customer/margins/MarginBody";
import MarginPrice from "../../components/customer/margins/MarginPrice";
import {AppContext} from '../../context/appContext';
import {faArrowLeft, faCopy} from "@fortawesome/free-solid-svg-icons";
import {useAuthState} from "../../context/authContext";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";

function Margin() {

    const {userId, roleId} = useAuthState();
    let navigate = useNavigate();
    const appContext = useContext(AppContext);
    let pathName = useLocation().pathname.split("/")[2];
    let id = useParams();
    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);

    const [margin, setMargin] = useState();
    const [invoiceList, setInvoiceList] = useState();
    const [invoiceRef, setInvoiceRef] = useState("");
    const [interlocutors, setInterlocutors] = useState([]);
    const [estimatedHours, setEstimatedHours] = useState(0);
    const [realHours, setRealHours] = useState(0);
    const [averageHourlyRateHT, setAverageHourlyRateHT] = useState(0);
    const [purchaseCostsHT, setPurchaseCostsHT] = useState(0);
    const [fixedCosts, setFixedCosts] = useState(0);
    const [variableCosts, setVariableCosts] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [theoreticalMargin, setTheoreticalMargin] = useState(0);
    const [percentMargin, setPercentMargin] = useState(0);
    const [expectedCost, setExpectedCost] = useState(0);
    const [realCost, setRealCost] = useState(0);
    const [contact, setContact] = useState(null);
    const [contacts, setContacts] = useState();
    const [date, setDate] = useState(new Date());
    //const [showCalendar, setShowCalendar] = useState(false);
    const [plannedManpower, setPlannedManpower] = useState(0);
    const [realManpower, setRealManpower] = useState(0);
    const [totalCostHT, setTotalCostHT] = useState(0);
    const [realTotalMargin, setRealTotalMargin] = useState(0);
    const [realPercentMargin, setRealPercentMargin] = useState(0);
    const [ref, setRef] = useState('');
    const [invoiceSuggestions, setInvoiceSuggestions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [interlocutorSelected, setInterlocutorSelected] = useState(0);

    // Fetch margin data
    useEffect(() => {
        getApiContext.getMargin(id.id)
        //axios.get(`${process.env.REACT_APP_MARGIN_GET}${id.id}`)
            .then(response => {
                setContact(response.contact);
                setInterlocutors(response.listInterlocutors);
                if (id.id){
                    setMargin(response.marginsList ? response.marginsList[0] : response.margin ? response.margin : {})
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [getApiContext, id.id]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_INVOICE_LIST}`)
            .then(response => {
                setInvoiceList(response.data.lstInvoice);
            })
            .catch(error => {
                console.log("erreur invoice :", error);
            })
    }, []);

    useEffect(() => {
        console.log(interlocutorSelected)
    }, [interlocutorSelected]);

    // Dynamic values update
    useEffect(() => {
        if (margin) {
            setRef(margin.ref || "MAR"+format(new Date(), 'dMyyms'));
            setInvoiceRef(margin.refInvoice || "");
            setEstimatedHours(margin.estimatedHours || 0);
            setRealHours(margin.realHours || margin.estimatedHours || 0);
            setAverageHourlyRateHT(margin.averageHourlyRateHT || 0);
            setPurchaseCostsHT(margin.purchaseCostsHT || 0);
            setFixedCosts(margin.fixedCosts || 0);
            setVariableCosts(margin.variableCosts || 0);
            setTotalHT(margin.totalHT || 0);
            setInterlocutorSelected(margin.userId);
            if (Object.keys(margin).length !== 0 && margin.invoice?.invoiceDate) {
                let dateParts = margin.invoice.invoiceDate.split("-");
                let day = dateParts[2];
                let month = dateParts[1];
                let year = dateParts[0];
                let newDate = month + "/" + day + "/" + year;
                setDate(new Date(newDate));
            }
        }
    }, [margin])

    useEffect(() => {
        if (margin) {
            const newPlannedManpower = estimatedHours * averageHourlyRateHT;
            const newRealManpower = realManpowerCalc(realHours, averageHourlyRateHT);
            const newTotalCostHT = totalCostHTCalc(purchaseCostsHT, fixedCosts, variableCosts);
            setPlannedManpower(newPlannedManpower);
            setRealManpower(newRealManpower);
            setTotalCostHT(newTotalCostHT);
        }
    }, [margin, averageHourlyRateHT, estimatedHours, fixedCosts, purchaseCostsHT, realHours, variableCosts]);

    useEffect(() => {
        if (margin) {
            const newExpectedCost = plannedManpower + parseFloat(totalCostHT.valueOf());
            const newRealCost = parseFloat(realManpower.valueOf()) + parseFloat(totalCostHT.valueOf());
            setExpectedCost(newExpectedCost);
            setRealCost(newRealCost);
        }
    }, [margin, plannedManpower, realManpower, totalCostHT]);

    useEffect(() => {
        if (margin) {
            const newRealTotalMargin = totalHT - realCost;
            const newTheoreticalMargin = theoreticalMarginCalc(totalHT, plannedManpower, totalCostHT);
            setRealTotalMargin(newRealTotalMargin);
            setTheoreticalMargin(newTheoreticalMargin);
        }
    }, [margin, totalHT, realCost, plannedManpower, totalCostHT]);

    useEffect(() => {
        if (margin) {
            const newPercentMargin = totalHT !== 0 ? percentMarginCalc(theoreticalMargin, totalHT) : 0;
            const newRealPercentMargin = totalHT !== 0 ? realTotalMargin / totalHT * 100 : 0;
            setPercentMargin(newPercentMargin);
            setRealPercentMargin(newRealPercentMargin);
        }
    }, [margin, totalHT, theoreticalMargin, realTotalMargin]);

    const onValidMargin = async (e) => {
        e.preventDefault();

        const values = {
            refFacture: invoiceRef,
            reference: ref,
            interlocuteur: interlocutorSelected !== 0 ? interlocutorSelected : userId,
            destinataire: contact?.id || margin?.societyContactId,
            heuresTotFact: estimatedHours,
            heuresReelles: realHours,
            tauxHoraireMoyen: averageHourlyRateHT,
            coutsAchat: purchaseCostsHT,
            fraisFixes: fixedCosts,
            fraisVariables: variableCosts,
            Htotalht1: totalHT
        }

        console.log(values)
        console.log(interlocutorSelected ?? userId)

        let marginId = pathName === "marginDup" ? "" : id.id;
        postApiContext.postMargin(marginId, values)
        //await axios.post(`${process.env.REACT_APP_MARGIN_POST}`+marginId, values, customHeaders)
            .then(response => {
                navigate(localStorage.getItem('parentPath') || "/marginsList");
            })
            .catch(error => {
                console.log(error);
            })
    }

    const duplicate = () => {
        navigate("/marginsList/marginDup/"+ id.id);
    }

    const headerButtonsList = {
        dropdown: {
            duplicate: {
                handleClick: duplicate,
                icon: faCopy,
                content: "Dupliquer",
                redir: "/marginsList"
            }
        },
        mainButton: {
            buttonIcon: faArrowLeft,
            buttonText: id.id ? "Sauvegarder" : "Créer la marge",
            buttonAction: onValidMargin
        },
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInvoiceRef(value);
        if (value.length > 0) {
            const lowercasedValue = value.toLowerCase();
            const filteredInvoices = invoiceList
                .filter(invoice => invoice.invoiceRef.toLowerCase().startsWith(lowercasedValue))
                .slice(0, 5);
            setInvoiceSuggestions(filteredInvoices);
        } else {
            setInvoiceSuggestions([]);
        }
    };

    const handleSuggestionClick = (ref) => {
        setInvoiceRef(ref);
        setInvoiceSuggestions([]);
    };

    const marginBodyData = {
        form: {
            estimatedHours: {
                value: estimatedHours,
                setter: setEstimatedHours
            },
            realHours: {
                value: realHours,
                setter: setRealHours
            },
            averageHourlyRateHT: {
                value: averageHourlyRateHT,
                setter: setAverageHourlyRateHT
            },
            purchaseCostsHT: {
                value: purchaseCostsHT,
                setter: setPurchaseCostsHT
            },
            fixedCosts: {
                value: fixedCosts,
                setter: setFixedCosts
            },
            variableCosts: {
                value: variableCosts,
                setter: setVariableCosts
            },
            totalHT: {
                value: totalHT,
                setter: setTotalHT
            },
        },
        results: {
            theoreticalMargin: {theoreticalMargin},
            percentMargin: {percentMargin}
        }
    }

    const marginPriceData = {
        expectedCost: expectedCost,
        realCost: realCost,
        plannedManpower: plannedManpower,
        realManpower: realManpower,
        totalCostHT: totalCostHT,
        totalHT: totalHT,
        realTotalMargin: realTotalMargin,
        realPercentMargin: realPercentMargin
    }

    const handleClickModal = () => {
        if (appContext.contactPopOpen === false) {
            axios.get(`${process.env.REACT_APP_CONTACT_LIST}`)
                .then(response => {
                    setContacts(response.data.listContacts);
                    //console.log("contact log", response.data.listContacts);
                });
            // setShowContactPop(true);
        }
        // appContext.setContactPopOpen(true);
        setShowModal(true);
    }

    function handleSelectedContact(index) {
        const selectedContact = contacts.find(contact => contact.id === index);
        setContact(selectedContact);
    }

    const contactListPopData = {
        show: {showModal},
        setShowModal: {setShowModal},
        data: {contacts},
        handleSelectedContact: {handleSelectedContact},
        handleClickModal: {handleClickModal}
    }
    const handleInterChange = (e) => {
        setInterlocutorSelected(e.target.value);
    }

    function handleNavigateFunc(path = "") {
        let parentPath = localStorage.getItem('parentPath');
        if (parentPath.includes("quote")) {
            return margin?.quoteId ? `/quotesList/quote/${margin?.quoteId}` : "/quotesList";
        } else if (parentPath.includes("invoice")) {
            return margin?.invoiceId ? `/invoicesList/invoice/${margin?.invoiceId}` : "/invoicesList";
        }
        return "/marginsList";
    }

    /*function handleNavigateFunc(path = "") {
        let quoteId = quote?.id || data?.quote?.id
        let invoiceId = invoice?.id || data?.invoice?.id || null
        let newPath = idPage === 'quote' ? '/quotesList/quote/'+quoteId : idPage === 'invoice' ? '/invoicesList/invoice/'+invoiceId : '/depositsList/'
        return path !== "" ? path : newPath
    }*/

    return (
        <>
            <EditingMainHeader
            backRedirect={handleNavigateFunc()}
            destination={handleNavigateFunc()}
            id={id.id}
            endPoint={"c-deactive-margin"}
            defaultRef={ref}
            date={date}
            headerTitle={"MARGE"}
            setDate={setDate}
            headerButtonsList={headerButtonsList}
            setRef={setRef}
            roleId={roleId}
        />
            <div className="listPage">
                <form onSubmit={(values) => onValidMargin(values)}>
                    <div className="contract">

                        <div className="margin-block">
                            <div className="block__body body">
                                <MarginInfos
                                    invoiceRef={invoiceRef}
                                    handleInputChange={handleInputChange}
                                    invoiceSuggestions={invoiceSuggestions}
                                    handleSuggestionClick={handleSuggestionClick}
                                    interlocutors={interlocutors}
                                    contact={contact}
                                    margin={margin}
                                    setInvoiceRef={setInvoiceRef}
                                    setInvoiceSuggestions={setInvoiceSuggestions}
                                    contactListPopData={contactListPopData}
                                    interlocutorSelected={interlocutorSelected}
                                    handleInterChange={handleInterChange}
                                />
                                <div className="contract-body">
                                    <div className="container">
                                        <MarginBody data={marginBodyData}/>

                                    </div>
                                </div>
                            </div>
                            <MarginPrice
                                data={marginPriceData}/>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}

export default Margin;