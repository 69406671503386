import { useEffect, useState } from "react";

import DeliveryLine from "./DeliveryLine";

function DeliveryGroup(props) {

    const [listGroupTotalHt, setListGroupTotalHt] = useState([]);
    const [totalGroupHt, setTotalGroupHt] = useState();

    useEffect (() => {
        let listGroupTotalHt = [];
        let lines = props.group.lines;
        let totalGroupHt = 0;
        lines.forEach((line, key) => {
            let totalLineHt = line.invoicedQuantity * line.unitPrice;
            totalGroupHt += totalLineHt;
            listGroupTotalHt.push(totalLineHt);
        })
        setListGroupTotalHt(listGroupTotalHt);
        setTotalGroupHt(totalGroupHt);
    },[props.group.lines])

    const handleDesignationChange = (lineId, newDesignation) => {
        const updatedLines = props.group.lines.map((line) => 
            line.id === lineId ? { ...line, name: newDesignation } : line
        );
        props.setGroupLines(updatedLines, props.index);
    };

    const handleDeliveryQuantityChange = (lineId, newDeliveredQuantity) => {
        const updatedLines = props.group.lines.map((line) => 
            line.id === lineId ? { ...line, deliveredQuantity: newDeliveredQuantity } : line
        );
        props.setGroupLines(updatedLines, props.index);
    }

    return (
        <div className="group">
            <input type="hidden" name={"idGrp" + props.id} id={"idGrp" + props.id} value={props.id} />
            <input type="hidden" name={props.index} />
            <div className="group__group-header">
                <p>TOTAL HT : {parseFloat(totalGroupHt).toFixed(2)} €</p>
            </div>
            {props?.group?.lines?.map((line, i) => {
                return (

                    <DeliveryLine
                        key={i}
                        index={i}
                        line={line}
                        id={line.id}
                        num={i + 1}
                        listUnit={props.listUnit}
                        listTva={props.listTva}
                        totalHt={listGroupTotalHt[i]}
                        onDesignationChange={handleDesignationChange}
                        onDeliveredQuantityChange={handleDeliveryQuantityChange}
                    />
                )

            }
            )}
            <hr />
        </div>
    );
}

export default DeliveryGroup;
