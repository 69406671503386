import CataloguesList from "../routes/catalog/CataloguesList";
import PrivateRouter from "../privateRouter";

const roles = ["1", "2", "3"];

export const catalogPaths = [
    {
        path: "/catalogList",
        element: <PrivateRouter roleList={roles}><CataloguesList /></PrivateRouter>,
    },
]