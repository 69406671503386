import "../../../../styles/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuoteLine from "./QuoteLine";
import {useCallback, useEffect, useState} from "react";
import {faAngleDown, faAngleUp, faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";

function QuoteGroup(props) {
    const [designation, setDesignation] = useState(props.group.title || "");
    const [totalHT, setTotalHT] = useState(parseFloat(props.group.line?.quantity) * parseFloat(props.group.line?.unitPrice));

    useEffect(() => {
        setDesignation(props.group.title || "");
    }, [props.group.title]);

    const updateGroup = useCallback((updatedLine, updatedGroup) => {
        let newGroup = { ...props.group };
        let newLines = undefined;
        if (updatedLine !== undefined) {
            newLines = [...newGroup.lines];
            newLines[updatedLine.index] = updatedLine;
        } else {
            newLines = updatedGroup.lines;
        }
        let totalHT = 0;
        newLines.forEach((line) => {
            totalHT = totalHT + (parseFloat(line.quantity * line.unitPrice));
        });
        setTotalHT(totalHT);
        props.group.totalHT = totalHT;
        let newgroup;
        if (updatedLine !== undefined) {
            newgroup = {
                ...newGroup,
                totalHT: totalHT,
                index: props.index,
                quoteId: props.quoteId,
                lines: newLines,
            };
        } else {
            newgroup = {
                ...updatedGroup,
                totalHT: totalHT,
                index: props.index,
                quoteId: props.quoteId,
                lines: newLines,
            };
        }
        props.group.lines = newLines;
        props.updateQuote(newgroup, undefined, undefined, undefined);

    }, [props]);

    const onChange = (e) => {
        let value = e.target.value;
        if (e.target.name === "designation") {
            var newGrp = { ...props.group, title: value };
        }
        updateGroup(undefined, newGrp);
    }

    const addLine = () => {
        let newGroup = { ...props.group }
        let id = generateId();
        newGroup.lines.push(
            {
                id: id,
                name: "",
                unit: "",
                quantity: "1.00",
                unitPrice: "0.00",
                totalHT: "0.00",
                vatId: 8,
                unitId: 1,
                numero: newGroup.lines.length + 1
            }
        );
        props.updateQuote(newGroup);
    }

    const generateId = () => {
        let prefixIdLine = "New";
        let newIdLig = 0;

        Array.from(props.group.lines).forEach((line) => {
            while (line.id === prefixIdLine + newIdLig) {
                newIdLig += 1;
            }
        });

        let newIdHtml = prefixIdLine + newIdLig;
        return newIdHtml;
    }

    function removeItem(index) {
        let newGroup = { ...props.group };
        const filteredLines = props.group?.lines.filter((line, i) => {
            return i !== index;
        });
        newGroup.lines = filteredLines;
        updateGroup(undefined, newGroup);
    }

    function openCatalogues(index, grpIndex) {
        props.openCatalogues(index, grpIndex);
    }

    const handleMoveLine = (index, direction) => {
        index = parseInt(index);
        let newGroup = { ...props.group };
        let lines = newGroup.lines;
        if (direction === "up" && index > 1) {
            const lineTargetIndex = lines.findIndex(line => parseInt(line.numero) === index);
            const lineBeforeIndex = lines.findIndex(line => parseInt(line.numero) === (index - 1));
            if (lineTargetIndex !== -1 && lineBeforeIndex !== -1) {
                const newLines = [...lines];
                newLines[lineBeforeIndex].numero = index;
                newLines[lineTargetIndex].numero = (index - 1);
            }
        } else if (direction === "down" && index < lines.length) {
            const lineTargetIndex = lines.findIndex(line => parseInt(line.numero) === index);
            const nextIndex = (index + 1);
            const lineAfterIndex = lines.findIndex(line => parseInt(line.numero) === nextIndex);
            if (lineTargetIndex !== -1 && lineAfterIndex !== -1) {
                const newLines = [...lines];
                newLines[lineAfterIndex].numero = index;
                newLines[lineTargetIndex].numero = nextIndex;
            }
        }
        updateGroup(undefined, newGroup);
    };

    const onLineChange = (lineId, field, newValue) => {
        const updatedLines = props.group.lines.map((line) =>
            line.id === lineId ? { ...line, [field]: newValue } : line
        );
        let newGroup = { ...props.group };
        newGroup.lines = updatedLines;
        updateGroup(undefined, newGroup);
    }

    return (
        <div className="group">
            <input type="hidden" name={"idGrp" + props.id} id={"idGrp" + props.id} value={props.id} />
            <input type="hidden" name={props.numero} />
            <div className="group-header">
                <div>
                    <div>
                        <p className="up" onClick={() => { props.changeOrderGroup(props.numero, "up") }}>
                            <FontAwesomeIcon icon={faAngleUp} style= {props.numero <= 1 && {opacity: 0.3, cursor: 'not-allowed'}}/>
                        </p>
                        <p className="down" onClick={() => { props.changeOrderGroup(props.numero, "down") }}>
                            <FontAwesomeIcon icon={faAngleDown} style={props.numero.toString() === props.quote.groups.length.toString() && {opacity: 0.3, cursor: 'not-allowed'}}/>
                        </p>
                    </div>
                    <input type="text" placeholder="Désignation groupe" name="designation" value={designation || ""} onChange={onChange} />
                </div>
                <p>TOTAL HT : {totalHT.toFixed(2)} €</p>
            </div>
            {
                props?.group.lines
                    ?.sort((a, b) => a.numero - b.numero)
                    .map((line, i) => (

                        <QuoteLine
                            key={i}
                            index={i}
                            line={line}
                            id={line.id}
                            grpId={props.id}
                            grpIndex={props.index}
                            allTva={props.allTva}
                            removeItem={removeItem}
                            listTva={props.listTva}
                            listUnit={props.listUnit}
                            group={props.group}
                            updateGroup={updateGroup}
                            openCatalogues={openCatalogues}
                            changeOrderLine={handleMoveLine}
                            onLineChange={onLineChange}
                        />

                    ))
            }
            <div className="group-options">
                <div className="new-line" onClick={addLine}>
                    <p>Ajouter une ligne</p>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
                <div className="delete-group" onClick={() => { props.removeGroup(props.index) }}>
                    <FontAwesomeIcon icon={faTrashCan} />
                    <p>Supprimer le groupe</p>
                </div>
            </div>
            <hr />
        </div>
    );
}

export default QuoteGroup;
