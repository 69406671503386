import ContactInfosPanel from "../../editingPages/informations/ContactInfosPanel";

export default function QuoteInfos ({subject, quote, interlocutors, contact, setSubject, onChangeInterlocutor, handleClickModal}) {

    return (
        <div className="editingInfos">
            <div className="editingInfos__titleContainer">
                <h2 className="editingInfos__titleContainer_title">informations</h2>
                <hr className="editingInfos__titleContainer_line"/>
            </div>
            <div className='editingInfos__firstSection'>
                <div className="editingInfos__firstSection_nameContainer">
                    <h3 className="editingInfos__firstSection_nameContainer_title">Intitulé du devis :</h3>
                    <input className="editingInfos__firstSection_nameContainer_input" type="text"
                           value={subject || ""}
                           onChange={(e) => setSubject(e.target.value)}/>
                </div>
                <div className="editingInfos__firstSection_interContainer">
                    <h3 className="editingInfos__firstSection_interContainer_title">Affaire suivie par :</h3>
                    <select onChange={onChangeInterlocutor} value={quote?.userId}
                            className="editingInfos__firstSection_interContainer_select" name="interlocutorSelect"
                            id="interlocutorSelect">
                        {
                            interlocutors &&
                            interlocutors.map((interlocutor, index) => {
                                return (
                                    <option className="editingInfos__firstSection_interContainer_option" key={index}
                                            value={interlocutor.id}>{interlocutor.first_name} {interlocutor.last_name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <ContactInfosPanel contact={contact} handleClickModal={handleClickModal}/>
        </div>
    )
}