import Quote from "../routes/quote/quote"
import QuotesList from "../routes/quote/quotesList"
import PrivateRouter from "../privateRouter";

const roles = ["1", "2", "3", "4", "5", "6"];

export const quotesPath = [
    {
        path: "quotesList",
        element:  <PrivateRouter roleList={roles}><QuotesList /></PrivateRouter>,
    },
    {
        path: "quotesList/quote/:id",
        element:<PrivateRouter roleList={roles}> <Quote /></PrivateRouter>,
    },
    {
        path: "quotesList/quoteDup/:id",
        element:  <PrivateRouter roleList={roles}><Quote /></PrivateRouter>,
    },
    {
        path: "quotesList/quote/",
        element:  <PrivateRouter roleList={roles}><Quote /></PrivateRouter>,
    },
]