import {Decimal} from 'decimal.js';

export const realManpowerCalc = (realHours, averageHourlyRateHT) => {
    realHours = new Decimal(parseFloat(realHours));
    averageHourlyRateHT = new Decimal(parseFloat(averageHourlyRateHT));
    return realHours.times(averageHourlyRateHT);
}

export const theoreticalMarginCalc = (totalHT, plannedManpower, totalCostHT) => {
    totalHT = new Decimal(parseFloat(totalHT));
    plannedManpower = new Decimal(parseFloat(plannedManpower));
    totalCostHT = new Decimal(parseFloat(totalCostHT));
    return totalHT.minus(plannedManpower.plus(totalCostHT));
}

export const percentMarginCalc = (theoreticalMargin, totalHT) => {
    theoreticalMargin = new Decimal(parseFloat(theoreticalMargin));
    totalHT = new Decimal(parseFloat(totalHT));
    return theoreticalMargin.div(totalHT).times(100);
};

export const totalCostHTCalc = (purchaseCostsHT, fixedCosts, variableCosts) => {
    purchaseCostsHT = new Decimal(parseFloat(purchaseCostsHT));
    fixedCosts = new Decimal(parseFloat(fixedCosts));
    variableCosts = new Decimal(parseFloat(variableCosts));
    return purchaseCostsHT.add(fixedCosts).add(variableCosts)
}