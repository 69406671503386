import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

//---------------------Essai Sophie --------------------
function formatLegend(value, entry) {
  const legendMap = {
    cashedLastN: "Encaissé n-1",
    cashed: "Prévisionnel n-1",
    forecastLastN: "Encaissé année courante",
    forecast: "Prévisionnel année courante",
    expenses: "Dépenses",
  };

  return legendMap[value];
}

export default function DashboardContent(props) {
  //useState

  const [sumPerMonth, setSumPerMonth] = useState([]);
  const [sumPerMonthPreviousY, setSumPerMonthPreviousY] = useState({});

  const [sumOrderPerMonth, setSumOrderPerMonth] = useState();

  const [startPeriodYear, setStartPeriodYear] = useState();
  const [finishPeriodYear, setFinishPeriodYear] = useState();

  //transforme l'annee (startPeriodeYear YYYY-MM-DD) en annee DD-MM-YYYY
  const DatePeriode = new Date(startPeriodYear);
  const dayDP = DatePeriode.getDate().toString().padStart(2, "0");
  const monthDP = (DatePeriode.getMonth() + 1).toString().padStart(2, "0");
  const yearDP = DatePeriode.getFullYear();
  const startYear = `${dayDP}/${monthDP}/${yearDP}`;
  const startYearPrecedant = `${dayDP}/${monthDP}/${yearDP - 1}`;

  //transforme l'annee (finishPeriodeYear YYYY-MM-DD) en anne DD-MM-YYYY
  const DateFinPeriode = new Date(finishPeriodYear);
  const dayFP = DateFinPeriode.getDate().toString().padStart(2, "0");
  const monthFP = (DateFinPeriode.getMonth() + 1).toString().padStart(2, "0");
  const yearFP = DateFinPeriode.getFullYear();
  const endYear = `${dayFP}/${monthFP}/${yearFP}`;
  const endYearPrecedent = `${dayFP}/${monthFP}/${yearFP - 1}`;

  useEffect(() => {

    setSumPerMonth(props.sumPerMonth);
    setSumPerMonthPreviousY(props.sumPerMonthPreviousY);
    setSumOrderPerMonth(props.sumOrderPerMonth);

    setStartPeriodYear(props.startPeriodYear);
    setFinishPeriodYear(props.finishPeriodYear);
  }, [props]);

  const startDate = new Date(startPeriodYear);
  const endDate = new Date(finishPeriodYear);

  // Tableau des noms de mois
  const moisNoms = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  // Tableau pour stocker les données
  const data = [];

  // Boucle pour parcourir les mois
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthName = moisNoms[month - 1]; // Nom du mois

    // Création de clés pour accéder aux données des mois correspondants
    const currentMonthKey = `${year}-${month}`;
    const previousYearMonthKey = `${year - 1}-${month}`;

    const currentMonthData = sumPerMonth.sumPerMonth[currentMonthKey] || {};
    const expensesMonthData =
      sumOrderPerMonth.sumOrderPerMonth[currentMonthKey] || {};
    const previousYearMonthData =
      sumPerMonthPreviousY.sumPerMonthPreviousY[previousYearMonthKey] || {};

    // Construire les données pour ce mois
    const monthData = {
      name: monthName,
      cashedLastN: previousYearMonthData.cash,
      cashed: currentMonthData.cash,
      forecastLastN: previousYearMonthData.forecast,
      forecast: currentMonthData.cash,
      expenses: expensesMonthData.expenses,
    };
    // Ajouter les données au tableau data
    data.push(monthData);

    // Passez au mois suivant
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return (
    <>
      <section className="dashboardContent">
        <p className="chartDesc">
          Chiffre d'affaire et Dépenses sur la période du {startYear} au{" "}
          {endYear}, par rapport à la période précédente du {startYearPrecedant}{" "}
          au {endYearPrecedent}
        </p>
        <div className="forecast">
          <p className="chartDesc">
            Le chiffre d'affaire facturé de l'exercice en cours est de :{" "}
            {sumPerMonth !== undefined
              ? parseFloat(sumPerMonth.totalCashPeriod).toFixed(2)
              : "00"}
            €, celui de l'exercice précédent est de :{" "}
            {sumPerMonthPreviousY !== undefined
              ? parseFloat(
                  sumPerMonthPreviousY.totalCashPeriodPreviousY
                ).toFixed(2)
              : "00"}
            € {/* {pourcentage.toFixed(2) */}% du chiffre d'affaire facturé de
            l'exercice précédente
          </p>
          <Paper className="paper">
            <ComposedChart
              className="chart"
              // width={"auto"}
              width={1400}
              height={350}
              data={data}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend formatter={formatLegend} />
              <Bar
                dataKey="cashedLastN"
                barSize={20}
                fill="#135393c5"
                stackId="a"
              />
              <Bar dataKey="cashed" barSize={20} fill="#6085aac5" stackId="a" />
              <Bar dataKey="forecast" barSize={28} fill="#fdab0d" stackId="b" />
              <Bar
                dataKey="forecastLastN"
                barSize={20}
                fill="#ffd78e"
                stackId="b"
              />
              <Line type="monotone" dataKey="expenses" stroke="#ff2a2a" />
            </ComposedChart>
          </Paper>
        </div>
      </section>
    </>
  );
}
