import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

export default function BasicPassInput({refs, labelName, id, className, placeholder}) {
    const [see, setSee] = useState(false);
    return (
        <>
            <div className={`basicInputContainer ${className}`}>
                <label className='basicInputContainer__label' htmlFor={id}>{labelName}</label>
                <div className='basicInputContainer__basicPassInput'>
                    <input autoComplete="current-password" className="basicInputContainer__button" placeholder={placeholder} id={id} ref={refs}
                           type={see ? "text" : "password"}/>
                    <FontAwesomeIcon onClick={() => setSee(!see)} className="basicInputContainer__basicPassInput_icon"
                                     icon={see ? faEye : faEyeSlash}/>
                </div>
            </div>
        </>
    );
}
