import InvoiceInfos from "./invoiceInfos";
import InvoiceGroup from "./invoiceGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useCallback, useEffect, useState} from "react";
import {generateId} from "../../../../../utils/generateId";
import Decimal from "decimal.js";
import MarginCreator from "../../../../addOns/MarginCreator";

export default function InvoiceFormContainer({
                                                 unitsList,
                                                 setGroups,
                                                 groups,
                                                 formDatas,
                                                 setInvoice,
                                                 vatList,
                                                 interlocutorsList,
                                                 invoice, setTotalInvoice,setRefresh, margin, handleSubmitInvoice, setMargin
                                             }) {
    const [lines, setLines] = useState([])

    useEffect(() => {
        setGroups(invoice?.groups)
    }, [invoice, setGroups])

    useEffect(() => {
        let total = new Decimal(0);
        groups?.forEach(group => {
            let totalGroup = new Decimal(0);
            group?.lines.forEach(line => {
                const quantityDecimal = new Decimal(line.quantity);
                const unitPriceDecimal = new Decimal(line.unitPrice);
                const totalLine = quantityDecimal.times(unitPriceDecimal);
                totalGroup = totalGroup.plus(totalLine)
            });
            total = total.plus(totalGroup);
        });
        setTotalInvoice(total.toNumber());
    }, [groups, setTotalInvoice]);

    const handleAddGroup = () => {
        let newInvoice = {...invoice}
        let id = generateId(groups);
        if (!newInvoice.groups) newInvoice.groups = [];
        newInvoice.groups.push(
            {
                id: id,
                invoiceId: invoice.id,
                lines: [
                    {
                        id: id,
                        invoiceGroupId: id,
                        itemId: null,
                        name: "",
                        numero: 1,
                        quantity: 0,
                        unitId: 0,
                        unitPrice: 0,
                        vatId: vatList[0]?.id ?? "0",
                        total: 0
                    }
                ],
                numero: (groups?.length || 0) + 1,
                title: "",
            })
        setInvoice(newInvoice);
    }

    const updateGroups = (newGroup) => {
        let newGroups = [...groups];
        let index = newGroups.findIndex(group => group.id === newGroup.id);
        newGroups[index] = newGroup;
        setGroups(newGroups);
    }

    const updateLines = (newLine) => {
        let newGroups = [...groups];
        let index = newGroups.findIndex(group => group.id === newLine.invoiceGroupId);
        let group = newGroups[index];
        if (group){
            let lineIndex = group.lines.findIndex(line => line.id === newLine.id);
            group.lines[lineIndex] = newLine;
        }
        setGroups(newGroups);
    }

    const handleChangeGroup = (e, id) => {
        let newGroups = [...groups];
        let index = newGroups.findIndex(group => group.id === id);
        newGroups[index][e.target.name] = e.target.value;
        setGroups(newGroups);
    }

    const handleMoveGroup = (index, direction) => {
        index = parseInt(index);
        if (direction === "up" && index > 1) {
            const groupTargetIndex = groups.findIndex(group => parseInt(group.numero) === index);
            const groupBeforeIndex = groups.findIndex(group => parseInt(group.numero) === (index - 1));
            if (groupTargetIndex !== -1 && groupBeforeIndex !== -1) {
                const newGroups = [...groups];
                newGroups[groupBeforeIndex].numero = index;
                newGroups[groupTargetIndex].numero = index - 1;
                setGroups(newGroups);
            }
        }
        if (direction === "down" && index < groups.length) {
            const groupTargetIndex = groups.findIndex(group => parseInt(group.numero) === index);
            const groupAfterIndex = groups.findIndex(group => parseInt(group.numero) === (index + 1));

            if (groupTargetIndex !== -1 && groupAfterIndex !== -1) {
                const newGroups = [...groups];
                newGroups[groupAfterIndex].numero = index;
                newGroups[groupTargetIndex].numero = index + 1;
                setGroups(newGroups);
            }
        }
    }

    const deleteGroupByNotId = (id) => {
        let newGroups = [...groups];
        let index = newGroups.findIndex(group => group.id === id);
        newGroups.splice(index, 1);
        for (let i = index; i < newGroups.length; i++) {
            newGroups[i].numero = newGroups[i].numero ? newGroups[i].numero - 1 : null;
        }
        setGroups(newGroups);
        setInvoice({...invoice, groups: newGroups})
    }

    const deleteLineByNotId = (id, groupId) => {
        let newGroups = [...groups];
        let index = newGroups.findIndex(group => group.id === groupId);
        let group = newGroups[index];
        let lineIndex = group.lines.findIndex(line => line.id === id);
        group.lines.splice(lineIndex, 1);
        setGroups(newGroups);
    }
    const updateMargin = useCallback((field, value) => {
        setMargin(margin => {
            return { ...margin, [field]: value };
        });
    },[setMargin]);
    return (
        <>
            <section className='invoiceFormContainer'>
                <InvoiceInfos formDatas={formDatas} interlocutorsList={interlocutorsList} invoice={invoice}/>
                <div className='invoiceFormContainer__groupsContainer'>
                    <div className="invoiceGroup__titleContainer">
                        <h2 className="invoiceGroup__titleContainer_title">Description du produit</h2>
                        <hr className='invoiceGroup__titleContainer_line'/>
                    </div>
                    {
                        groups &&
                        groups
                            ?.sort((a, b) => a.numero - b.numero)
                            .map((group, index) => {
                            return (
                                <InvoiceGroup deleteLineByNotId={deleteLineByNotId} deleteGroupByNotId={deleteGroupByNotId} setRefresh={setRefresh} unitsList={unitsList} handleChangeGroup={handleChangeGroup}
                                              updateLines={updateLines} updateGroups={updateGroups} setLines={setLines}
                                              lines={lines} groups={groups} setGroups={setGroups} vatList={vatList}
                                              group={group} key={index} handleMoveGroup={handleMoveGroup}/>
                            )
                        })
                    }
                    <hr className='invoiceFormContainer__groupsContainer_line'/>
                    <button onClick={handleAddGroup}
                            className="invoiceFormContainer__groupsContainer_addGroupButton">Ajouter un
                        groupe <FontAwesomeIcon className="invoiceFormContainer__groupsContainer_addGroupButton_icon"
                                                icon={faPlus}/></button>
                </div>
                <MarginCreator margin={margin} object={invoice} onValidObject={handleSubmitInvoice} updateMargin={updateMargin} parent={"invoice"}/>
            </section>
        </>
    )
}