import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./styles/style.css";
import "./styles/sass/styles.sass";
import "./styles/reset.css";
import reportWebVitals from './reportWebVitals';
import App from './App';
import AppProvider from './context/appContext';
import GetApiProvider from "./context/apiCalls/getApiCalls";
import PostApiProvider from "./context/apiCalls/postApiCalls";
import DeleteApiProvider from "./context/apiCalls/deleteApiCalls";
import {AuthProvider} from "./context/authContext";

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <AuthProvider>
            <GetApiProvider>
                <PostApiProvider>
                    <DeleteApiProvider>
                        <AppProvider>
                            <App/>
                        </AppProvider>
                    </DeleteApiProvider>
                </PostApiProvider>
            </GetApiProvider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
