import ContactSearchButton from "../../../addOns/buttonComponent/ContactSearchButton";

export default function ContactInfosPanel({contact, handleClickModal}) {
    return (
        <div className="contactInfosPanel">
            <div className="contactInfosPanel__content">
                <h3 className="contactInfosPanel__content_title">Nom du client :</h3>
                <p className="contactInfosPanel__content_name">{contact?.society} - {contact?.firstName} {contact?.lastName}</p>
                <hr className="contactInfosPanel__content_line"/>
            </div>
            <ContactSearchButton handleClickModal={handleClickModal}/>
        </div>
    )
}