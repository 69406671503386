import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

export default function ContactSearchButton({handleClickModal}){

    return (
        <button onClick={handleClickModal} className='contactInfosPanel__button'>
            Rechercher un client
            <FontAwesomeIcon
                className="contactInfosPanel__button_icon"
                icon={faSearch}
            />
        </button>
    )
}