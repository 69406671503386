import BarCharts from "./BarCharts";
import Pie from "./Pie";
import { format } from "date-fns";
import React, {useEffect, useState, useMemo, useCallback, useContext} from "react";
import {GetApiContext} from "../../../context/apiCalls/getApiCalls";

function Dashboard() {
  //variables
  const currentYear = new Date().getFullYear();

  const months = useMemo(() => [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ], []);

  // useState
  const getApiCall = useContext(GetApiContext);

  const [rate, setRate] = useState("HT");
  const [user, setUser] = useState({});
    const [society, setSociety] = useState({});

  const [generateYearOptions, setGenerateYearOptions] = useState([currentYear]);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [generateMonthOptions, setGenerateMonthOptions] = useState(months);
  const [selectedMonth, setSelectedMonth] = useState("janvier");

  const [sumPerMonth, setSumPerMonth] = useState({});
  const [sumPerMonthPreviousY, setSumPerMonthPreviousY] = useState({});
  const setSumPerMonthNextY = useCallback(() => { }, []);

  const [sumOrderPerMonth, setSumOrderPerMonth] = useState();

  const [nbValidAndInvalid, setNbValidAndInvalid] = useState({});

  const [startPeriodYear, setStartPeriodYear] = useState();
  const [finishPeriodYear, setFinishPeriodYear] = useState();
  const setStartPeriodPreviousYear = useCallback(() => { }, []);
  const setFinishPeriodPreviousYear = useCallback(() => { }, []);

  const selectedMonthNumber = months.indexOf(selectedMonth);
  const selectDate = new Date(selectedYear, selectedMonthNumber, 1);
  const selectedDate = format(selectDate, "yyyy-MM-dd");


  useEffect(() => {
    getApiCall.getDashboardData(selectedDate, rate, selectedMonth)
      .then((response) => {
        console.log(response);
        setUser(response.user);
        setSociety(response.society);
        setSumPerMonth(response.sumPerMonth);
        setSumPerMonthPreviousY(response.sumPerMonthPreviousY);
        setSumPerMonthNextY(response.sumPerMonthNextY);
        setSumOrderPerMonth(response.sumOrderPerMonth);
        setNbValidAndInvalid(response.nbValidAndInvalid);
        setStartPeriodYear(response.startPeriodYear);
        setFinishPeriodYear(response.finishPeriodYear);
        setStartPeriodPreviousYear(response.startPeriodPreviousYear);
        setFinishPeriodPreviousYear(response.finishPeriodPreviousYear);
      })
      .catch((error) => {
        console.error("Erreur lors de la requête :", error);
      });
  }, [selectedDate, rate, selectedMonth, setSumPerMonth, setSumPerMonthPreviousY, setSumPerMonthNextY, setSumOrderPerMonth, setNbValidAndInvalid, setStartPeriodYear, setFinishPeriodYear, setStartPeriodPreviousYear, setFinishPeriodPreviousYear, getApiCall]);

  //ANNEES
  useEffect(() => {
    const yearOptions = [];
    for (let year = currentYear; year >= currentYear - 5; year--) {
      yearOptions.push(year);
    }
    setGenerateYearOptions(yearOptions);
  }, [currentYear]);
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedMonth("janvier");
  };



  //MOIS((month, index)
  useEffect(() => {
    const monthOptions = months.map((monthName) => {
      return {
        name: monthName,
        value: new Date(
          selectedYear,
          months.indexOf(monthName),
          1
        ).toLocaleString("default", {
          month: "long",
        }),
      };
    });
    setGenerateMonthOptions(monthOptions);
  }, [months, selectedYear]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // TYPE DE MONTANT
  const handleChange = () => {
    setRate(rate === "HT" ? "TTC" : "HT");
  };

  return (
    <>
      {/* <TabBar elem="dashboard" /> */}
      <div className="">
        <div className="dashboardContainer">
          <section className="dashboardHeader">
            <div className="head">
              <h2> Bonjour {user?.first_name} 🙂 </h2>
              <p>
                Entreprise <span> {society?.societyName} </span>
              </p>
            </div>
            <div className="orangeLine"> </div>
            <div className="dashboardHeadContent">
              <h1 className="dashboardTitle">
                VOTRE <span className="titleSpan"> TABLEAU DE BORD </span>
              </h1>
              <div className="filterSection">
                <div className="contentFilter">
                  <p className="txt">
                    consulter le chiffre d'affaire de l'année
                  </p>
                  <select
                    name="CaDate"
                    id="CaDate"
                    onChange={handleYearChange}
                    defaultValue={selectedYear}
                  >
                    {generateYearOptions.map((year) => {
                      return (
                        <>
                          <option value={year}>{year}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="contentFilter">
                  <p className="txt"> Mois de début de l'exercice fiscal</p>
                  <select
                    name="Month"
                    id="Month"
                    onChange={handleMonthChange}
                    value={selectedMonth}
                  >
                    {generateMonthOptions.map((month, index) => (
                      <option key={index} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="contentFilter">
                  <p className="txt"> Type de montant </p>
                  <div>
                    <p
                      className={`${rate === "HT"
                        ? "rate rateSelected marginRight"
                        : "rate marginRight"
                        }`}
                      onClick={handleChange}
                    >
                      HT
                    </p>
                    <p
                      className={`${rate === "TTC" ? "rate rateSelected " : "rate "
                        }`}
                      onClick={handleChange}
                    >
                      TTC
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <BarCharts
            rate={rate}
            sumPerMonth={sumPerMonth}
            sumPerMonthPreviousY={sumPerMonthPreviousY}
            sumOrderPerMonth={sumOrderPerMonth}
            startPeriodYear={startPeriodYear}
            finishPeriodYear={finishPeriodYear}
          />

          <Pie
            nbValidAndInvalid={nbValidAndInvalid}
            startPeriodYear={startPeriodYear}
            finishPeriodYear={finishPeriodYear}
          />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
