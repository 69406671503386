import {useContext, useRef, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCopy, faPenToSquare, faXmark} from '@fortawesome/free-solid-svg-icons'
import {PostApiContext} from "../../../../context/apiCalls/postApiCalls";
import BasicInput from "../../../addOns/inputComponents/basicInput";
import Contact from "../../../../routes/contact/contact";
import {AppContext} from "../../../../context/appContext";
import DeletButton from "../../../addOns/deletButton";
import ItemPopForm from "./itemPopForm";
import ItemPop from "./itemPop";

export default function CreatePersonalItemPop({ itemCopy, setItemCopy, personalCatalogList, brandList, familyList, subfamilyList, supplierList, unitList, refresh, setRefresh, selectedArticle, articlePopState, setShowArticlePopup, setArticlePopState }) {

    const nameRef = useRef(selectedArticle?.name ?? "");
    const refDistributorRef = useRef();
    const refMakerRef = useRef();
    const packageRef = useRef();
    const basePriceRef = useRef();
    const netPriceRef = useRef();

    //Inputs refs
    const catalogRef = useRef("??");
    const familyRef = useRef("");
    const subfamilyRef = useRef("");
    const brandRef = useRef("");

    const [catalogSelected, setCatalogSelected] = useState(itemCopy?.personalCatalogId ?? "");
    const [familySelected, setFamilySelected] = useState(itemCopy?.family);
    const [subfamilySelected, setSubfamilySelected] = useState(itemCopy?.subfamily);
    const [brandSelected, setBrandSelected] = useState(itemCopy?.brand);
    const [supplierSelected, setSupplierSelected] = useState(itemCopy?.supplier);
    const [unitSelected, setUnitSelected] = useState(itemCopy?.auPrice);

    let postApiContext = useContext(PostApiContext);
    const appContext = useContext(AppContext);

    const handleEdit = () => {
        console.log("selected article", selectedArticle);
        setItemCopy(selectedArticle);
        if (articlePopState === "edit") {
            setArticlePopState("display");
        } else {
            setArticlePopState("edit");
        }
    }

    const handleEditSubmit = (e) => {
        e.preventDefault();
        const values = {
            id: articlePopState === "edit" ? selectedArticle?.id : null,
            catalogName : catalogRef.current?.value,
            cataogueId: catalogSelected ?? itemCopy.personalCatalogId,
            family: familyRef.current?.value !== "" ? familyRef.current?.value : familySelected ?? itemCopy.family,
            subfamily: subfamilyRef.current?.value !== "" ? subfamilyRef.current?.value : subfamilySelected ?? itemCopy.subfamily,
            brand: brandRef.current?.value !== "" ? brandRef.current?.value : brandSelected ?? itemCopy.brand,
            supplier: supplierSelected ? supplierSelected : itemCopy.supplier,
            name: nameRef.current.value ? nameRef.current.value : itemCopy.designation,
            refMaker: refMakerRef.current?.value ? refMakerRef.current.value : itemCopy.refMaker,
            refDistributor: refDistributorRef.current?.value ? refDistributorRef.current.value : itemCopy.refDistributor,
            packageItem: packageRef.current?.value ? packageRef.current.value : itemCopy.package,
            auPrice: unitSelected ? unitSelected : itemCopy.auPrice,
            netPrice: netPriceRef.current?.value ? netPriceRef.current.value : itemCopy.netPrice,
            basePrice: basePriceRef.current?.value ? basePriceRef.current.value : itemCopy.basePrice,
        }

        console.log("values", values);
        postApiContext.postValidPersonalItem(values)
            .then(response => {
                console.log("response post deposit", response);
                setShowArticlePopup(false);
                setRefresh(!refresh);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const refs = {catalogRef, familyRef, subfamilyRef, brandRef, refDistributorRef, refMakerRef, packageRef, netPriceRef, basePriceRef};
    const setters = {setCatalogSelected, setFamilySelected, setSubfamilySelected, setBrandSelected, setSupplierSelected, setUnitSelected};
    const dataLists = {personalCatalogList, brandList, familyList, subfamilyList, supplierList, unitList};

    const validPopContent = {
        title: "Suppression",
        desc: "Êtes-vous sûr de vouloir supprimer cet article ?",
    }

    return (
        <>
            {appContext.showContactPopup &&
                <div style={{width: '90%', height: '90%', backgroundColor: "#fff", zIndex: 99999, position: "absolute", margin: "0 5%", overflow: "hidden"}}>
                    <Contact/>
                </div>
            }
            <form className="itemPop" onSubmit={handleEditSubmit} action="submit">
                    <div className="itemPop__header">
                        <div className="itemPop__header_options">
                            {articlePopState !== "create" &&
                            <FontAwesomeIcon className="itemPop__header_options_icon" icon={faCopy}
                                             onClick={() => {setItemCopy(selectedArticle); setArticlePopState("create")}}/>
                            }
                            {
                                (selectedArticle?.personalCatalogId || selectedArticle?.societyId) && articlePopState !== "create" &&
                                (
                                    <>
                                        <FontAwesomeIcon className="itemPop__header_options_icon" onClick={handleEdit}
                                                         icon={faPenToSquare}/>
                                        <DeletButton url={'c-delete-personalItem'} id={selectedArticle.id}
                                                     refresh={refresh} setRefresh={setRefresh}
                                                     className={"itemPopGarbage"} validPopContent={validPopContent}/>
                                    </>
                                )
                            }
                        </div>
                        <h2 className="itemPop__header_title">{articlePopState === "create" ? "Créer un article" : articlePopState === "edit" ? "Modifier l'article" : articlePopState === "display" ? "" : ""}</h2>
                        <FontAwesomeIcon className="cross" onClick={() => setShowArticlePopup(false)}
                                         icon={faXmark}/>
                    </div>
                    <BasicInput placeholder={articlePopState === "display" ? "Cet article n'a pas de nom" : "Désignation de l'article"} refs={nameRef} defaultValue={articlePopState === "create" ? itemCopy?.name !== undefined ? itemCopy.name : null : selectedArticle.name} className={"itemPop__name-input"} readOnly={articlePopState === "display"}/>
                {
                        articlePopState === "edit" ? (
                            <ItemPopForm handleEditItemSubmit={handleEditSubmit} selectedElement={selectedArticle} itemCopy={itemCopy}
                                         dataLists={dataLists} setters={setters} refs={refs}/>

                        ) : articlePopState === "create" ? (
                            <ItemPopForm itemCopy={itemCopy} dataLists={dataLists} setters={setters} refs={refs}/>
                        ) : articlePopState === "display" && (
                            <ItemPop selectedArticle={selectedArticle}/>
                        )}
                    <button className="button">Confirmer</button>
            </form>
        </>
    )
}