import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../../context/appContext";
import {GetApiContext} from "../../../../../context/apiCalls/getApiCalls";
import ContactsListPop from "../../../contacts/ContactsListPop";
import ContactInfosPanel from "../../../editingPages/informations/ContactInfosPanel";

export default function InvoiceInfos({formDatas, interlocutorsList, invoice}) {

    const appContext = useContext(AppContext);
    const getApiContext = useContext(GetApiContext);

    const [contactsList, setContactsList] = useState([]);
    const [showPop, setShowPop] = useState(false);
    const [contact, setContact] = useState({})
    const [interlocutor, setInterlocutor] = useState("")

    useEffect(() => {
        setContact(invoice?.contact)
        setInterlocutor(invoice?.user)
    }, [invoice?.contact, invoice?.user])


    const handleClickModal = async () => {
        if (appContext.contactPopOpen === false) {
            await getApiContext.getContactsList().then((data) => {
                setContactsList(data.listContacts);
            })
        }
        setShowPop(true);
    }

    function handleSelectedContact(index) {
        const selectedContact = contactsList.find(contact => contact.id === index);
        setContact(selectedContact);
        formDatas.setContactId(selectedContact.id);
    }

    const handleInterlocutorChange = (e) => {
        e.preventDefault();
        setInterlocutor(e.target.value)
        formDatas.setInterlocutorId(e.target.value)
    }

    return (
        <>
            <ContactsListPop
                show={showPop}
                setShowModal={setShowPop}
                data={contactsList}
                handleSelectedContact={handleSelectedContact}
            />
            <div className="editingInfos">
                <div className="editingInfos__titleContainer">
                    <h2 className="editingInfos__titleContainer_title">informations</h2>
                    <hr className="editingInfos__titleContainer_line"/>
                </div>
                <div className='editingInfos__firstSection'>
                    <div className="editingInfos__firstSection_nameContainer">
                        <h3 className="editingInfos__firstSection_nameContainer_title">Intitulé de la facture :</h3>
                        <input className="editingInfos__firstSection_nameContainer_input" type="text"
                               defaultValue={invoice?.subject}
                               ref={formDatas.titleRef}
                               placeholder="EX: FACTURE POUR UNE ISOLATION"/>
                    </div>
                    <div className="editingInfos__firstSection_interContainer">
                        <h3 className="editingInfos__firstSection_interContainer_title">Affaire suivie par :</h3>
                        <select onChange={handleInterlocutorChange} value={interlocutor?.id} className="editingInfos__firstSection_interContainer_select" name="interlocutorSelect"
                                id="interlocutorSelect">
                            {
                                interlocutorsList && interlocutorsList.length > 0 &&
                                interlocutorsList.map((interlocutor, index) => {
                                    return (
                                        <option className="editingInfos__firstSection_interContainer_option" key={index} value={interlocutor.id}>{interlocutor.first_name} {interlocutor.last_name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <ContactInfosPanel contact={contact} handleClickModal={handleClickModal}/>
            </div>
        </>
    )

}