import Credit from "../routes/credit/credit";
import CreditList from "../routes/credit/creditList";
import PrivateRouter from "../privateRouter";

const roles = ["1", "2", "3"];

export const creditPaths = [
    {
        path: "/creditList",
        element: <PrivateRouter roleList={roles}><CreditList /></PrivateRouter>,
    },
    {
        path: "/creditList/credit/:id",
        element: <PrivateRouter roleList={roles}><Credit /></PrivateRouter>,
    },
    {
        path: "/creditList/credit",
        element: <PrivateRouter roleList={roles}><Credit /></PrivateRouter>,
    }
]