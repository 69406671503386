import axios from "axios";
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../context/appContext';


export default function EditProspect() {

    const appContext = useContext(AppContext);
    const { id } = useParams();

    const callRef = useRef();
    const meetingRef = useRef();
    const dunningRef = useRef();
    const commentRef = useRef();

    const [prospect, setProspect] = useState([]);

    const idProspect = appContext.idprospect;
    useEffect(() => {

        axios.get(`${process.env.REACT_APP_CONTACT_PROSPECT_GET}${idProspect}&idContact=${id}`)
            .then(response => {
                setProspect(response.data.prospecting);
                console.log("log de prospecting", response.data.prospecting);
            });


    }, [appContext.idprospect, id, idProspect]);

    const handleProspectSubmit = async (e) => {
        e.preventDefault();

        const customHeaders = {
            'content-type': 'application/json',
        };
        // console.log("bla", prospect.societyContactId);
        // console.log("bla", id);
        const values = {
            idContact: id,
            idProspect: idProspect,
            call: callRef?.current?.value,
            meeting: meetingRef?.current?.value,
            dunning: dunningRef?.current?.value,
            comment: commentRef?.current?.value,

        };
        await axios.post(`${process.env.REACT_APP_CONTACT_PROSPECT_POST}`, values, customHeaders)
            .then(response => {
                console.log("values post ", values);
                console.log("log du post ", response.data);
                appContext.setBlur(false);
                appContext.setShowEditProspectPopup(false);
                appContext.setReload(true);
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <>
            <div className='pop-up'>
                <h3>Editer un prospect</h3>
                <form onSubmit={handleProspectSubmit} action="submit">
                    <div className='pop-up-line'>
                        <label className='label'>Appel</label>
                        <input className='pop-up-input' ref={callRef} defaultValue={prospect.call} type="text" />
                    </div>
                    <div className='pop-up-line'>
                        <label >Rendez-vous</label>
                        <input className='pop-up-input' ref={meetingRef} defaultValue={prospect.meeting} type="text" />
                    </div>
                    <div className='pop-up-line'>
                        <label >Relance</label>
                        <input className='pop-up-input' ref={dunningRef} defaultValue={prospect.dunning} type="text" />
                    </div>
                    <div className='pop-up-line'>
                        <label >Commentaire</label>
                        <input className='pop-up-input' ref={commentRef} defaultValue={prospect.comment} type="text" />
                    </div>
                    <button >Confirmer la modification</button>
                </form>
            </div>
        </>
    )


}