import { useEffect } from 'react';
import {useAuthDispatch} from "../context/authContext";


const useAuth = () => {
    const dispatch = useAuthDispatch();

    useEffect(() => {
        const token = localStorage.getItem('acces_token');
        if (token) {
            const { roleId, userId } = parseJwt(token).data;
            if (userId && roleId) {
                dispatch({ type: 'SET_USER', payload: { userId, roleId } });
            }
        }
    }, [dispatch]);

    const login = (token) => {
        localStorage.setItem('acces_token', token);
        const { userId, roleId } = parseJwt(token);
        if (userId && roleId) {
            dispatch({ type: 'SET_USER', payload: { userId, roleId } });
        }
    };

    const logout = () => {
        localStorage.removeItem('acces_token');
        window.location.reload();
        dispatch({ type: 'LOGOUT' });
    };

    return { login, logout };
};

const parseJwt = (token) => {
    try {
        // Vérifier que le token est bien formé et contient trois parties séparées par des points
        const parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error('Token JWT mal formé');
        }

        // Décoder la partie payload du JWT
        const base64Url = parts[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        //console.log('jsonPayload', jsonPayload);
        return JSON.parse(jsonPayload);
    } catch (e) {
        console.error('Failed to parse JWT', e);
        return {};
    }
};


export default useAuth;