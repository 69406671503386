import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import EditingMainHeader from "../../../components/addOns/headers/EditingMainHeader";
import DeliveryInfos from "../../../components/customer/invoices/delivery/DeliveryInfos";
import DeliveryGroup from "../../../components/customer/invoices/delivery/DeliveryGroup";
import DeliveryPrice from "../../../components/customer/invoices/delivery/DeliveryPrice";
import {faArrowLeft, faPaperPlane, faPrint} from "@fortawesome/free-solid-svg-icons";

function Delivery() {

    let id = useParams();
    let navigate = useNavigate();

    const [delivery, setDelivery] = useState();
    const [deliveryGroups, setDeliveryGroups] = useState();
    const [ref, setRef] = useState();
    const [interlocutors, setInterlocutors] = useState({});
    const [subject, setSubject] = useState();
    const [date, setDate] = useState();
    const [societyContact, setSocietyContact] = useState();
    const [listUnit, setListUnit] = useState([]);
    const [listTva, setListTva] = useState();
    const [invoice, setInvoice] = useState();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DELIVERY_GET}${id.id}`)
            .then(response => {
                setDelivery(response.data.delivery);
                setRef(response.data.delivery.ref);
                setDeliveryGroups(response.data.deliveryGroups);
                setInterlocutors(response.data.listInterlocutors);
                setSubject(response.data.delivery.subject);
                setSocietyContact(response.data.societyContact);
                setListUnit(response.data.listUnit);
                setListTva(response.data.listTva);
                setInvoice(response.data.delivery.invoice);
                setDate(new Date(response.data.delivery.date));

            })
            .catch(error => {
                console.log("delivery get error :", error);
            })
    }, [id.id]);

    const setGroupLines = (updatedLines, groupIndex) => {
        const updatedGroups = deliveryGroups.map((group, index) =>
            index === groupIndex ? { ...group, lines: updatedLines } : group
        );
        setDeliveryGroups(updatedGroups);
    };

    const buttonAction = async (e) => {
        //e.preventDefault();

        const customHeaders = {
            'Content-Type': 'application/json'
        };

        const nbLines = () => {
            let total = 0;
            deliveryGroups.forEach((group, index) => {
                total += group.lines.length;
            })
            return total;
        }

        const formattedDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const newDate = `${year}-${month}-${day}`;
            return newDate;
        }

        const values = {
            id: delivery.id,
            deliveryRef: ref,
            commentaire: delivery.comment,
            date_emission: formattedDate(date),
            titre: subject,
            i: deliveryGroups.length,
            j: nbLines(),
        }

        let lineIndex = 0;
        deliveryGroups.forEach((group, groupIndex) => {
            values[`idGrp${groupIndex}`] = group.id;
            group.lines.forEach((line) => {
                values[`idLine${lineIndex}`] = line.id;
                values[`designationLigne${lineIndex}`] = line.name;
                values[`quantFact${lineIndex}`] = line.invoicedQuantity;
                values[`quantLiv${lineIndex}`] = line.deliveredQuantity;
                values[`unite${lineIndex}`] = line.unitId;
                values[`prix${lineIndex}`] = line.unitPrice;
                values[`tauxtva${lineIndex}`] = line.vatId;
                lineIndex++;
            });
        });

        await axios.post(`${process.env.REACT_APP_DELIVERY_POST}${id.id}`, values, customHeaders)
            .then(response => {
                navigate("/invoicesList/deliveriesList/");
            })
            .catch(error => {
                console.log("erreur post delivery:", error);
            })
    }

    const deleteAction = (e) => {
        axios.get(`${process.env.REACT_APP_DELIVERY_DELETE}${id.id}`)
            .then(response => {
                console.log(response);
                navigate("/invoicesList/deliveriesList/");
            })
            .catch(error => {
                console.log("erreur delete : ", error);
            })
    }

    const headerButtonsList = {
        dropdown: {
            imprimer: {
                content: "Imprimer",
                icon: faPrint,
            },
            envoyer: {
                content: "Envoyer par mail",
                icon: faPaperPlane
            }
        },
        mainButton: {
            buttonIcon: faArrowLeft,
            buttonText: "Sauvegarder",
            buttonAction: { buttonAction }
        },
        garbageButton: {
            garbageRedirect: "",
            deleteAction: { deleteAction }
        }
    }

    return (
        <>
            <div className="listPage">

                <EditingMainHeader
                    backRedirect={"/invoicesList/deliveriesList"}
                    defaultRef={ref}
                    date={date || new Date()}
                    headerTitle={"Bon n°"}
                    setDate={setDate}
                    headerButtonsList={headerButtonsList}
                    setRef={setRef}
                />
                <div className="block">
                    <div className="body">

                        <DeliveryInfos
                            invoiceRef={delivery?.invoice.ref}
                            interlocutors={interlocutors}
                            subject={subject}
                            setSubject={setSubject}
                            societyContact={societyContact}
                        />
                        {deliveryGroups?.map((group, i) => {
                            return (
                                <DeliveryGroup
                                    key={i}
                                    index={i}
                                    group={group}
                                    id={group.id}
                                    numero={i + 1}
                                    designation={(group.title === undefined) ? "" : group.title}
                                    listUnit={listUnit}
                                    listTva={listTva}
                                    delivery={delivery}
                                    setGroupLines={setGroupLines}
                                />
                            )
                        })}
                    </div>
                    <DeliveryPrice
                        delivery={delivery}
                        invoice={invoice}
                        listTva={listTva || {}}
                    />
                </div>
            </div>
        </>
    );
}

export default Delivery;