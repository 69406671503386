import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Loader from "../../components/addOns/loader";
import Pagination from '../../components/addOns/Pagination';
import TotalAmonts from '../../components/addOns/TotalAmonts';
import {
    faCircleCheck,
    faCopy,
    faDownload,
    faFileMedical,
    faPaperPlane,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import MainHeader from "../../components/addOns/headers/MainHeader";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import Card from "../../components/addOns/card";
import ValidPop from "../../components/addOns/popComponents/validPop";


function OrderList() {

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const quoteFilterRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const societyRef = useRef();

    const [orderList, setOrderList] = useState();
    const [interlocutors] = useState();
    const [orderState, setOrderState] = useState('all');
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState('datedown')
    const [interloSearch, setInterloSearch] = useState();
    const [listRefresh, setListRefresh] = useState(false);
    const [showValidPop, setShowValidPop] = useState(false);

    const [orderId, setOrderId] = useState();
    const [nbPages, setNbPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);

    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getApiContext.getOrderList(orderState)
            .then(response => {
                // console.log(response);
                setOrderList(response.orderFormsList);
                setLoading(false)
                setNbPages(response.nbPages);
                setPerPage(response.perPage);
                setTotalTTC(response.totalAmonts.total_ttc);
                setTotalHT(response.totalAmonts.total_ht);
                setTotalVAT(response.totalAmonts.total_vat);
                if (response.nbPages < response.currentPage) {
                    setCurrentPage(response.nbPages);
                } else {
                    setCurrentPage(response.currentPage);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
        setListRefresh(false);
    }, [getApiContext, listRefresh, orderState]);

    const stateObject = [
        { valid: "all", name: "toutes les dépenses" },
        { valid: "valid", name: "vos dépenses validées" },
        { valid: "in-progress", name: "vos dépenses en cours" },
        { valid: "canceled", name: "vos dépenses annulées" },
    ];

    const contentObject = {
        ContentName: "Dépense",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "refDevis", title: "Référence Devis", type: "text", ref: quoteFilterRef },
            { name: "startDate", title: "Date de début", type: "text", ref: startDateRef },
            { name: "endDate", title: "Date de fin", type: "text", ref: endDateRef },
            { name: "society", title: "Société", type: "text", ref: societyRef },
        ]
    }

    const sortOptions = {
        options: [
            { value: "refup", content: "Références croissantes" },
            { value: "refdown", content: "Références décroissantes" },
            { value: "dateup", content: "Date de création croissante" },
            { value: "datedown", content: "Date de création décroissante" },
        ]
    }

    const onSearchSelectChange = (e) => {
        console.log(e.target.value);
        setInterloSearch(e.target.value);
    }

    const onValidFilter = async (e) => {
        e.preventDefault();

        setOrderList(null)
        setLoading(true)

        const values = {
            lastName: lastNameRef?.current?.value,
            firstName: firstNameRef?.current?.value,
            society: societyRef?.current?.value,
            interlocuteur: interloSearch,
            startDate: startDateRef?.current?.value,
            endDate: endDateRef?.current?.value,
            sort: sort,
            state: orderState
        }

        console.log("values", values)
        postApiContext.postOrderFilterList(values)
            .then(response => {
                console.log(response);
                setOrderList(response.orderFormsList)
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })

    }

    const changePerPage = (e) => {
        setPerPage(e.target.value);
        setCurrentPage(1);
    };

    const openEmptyOrder = () => {
        navigate('/ordersList/order');
    }

    const handleOpenValidItem = (id) => {
        setShowValidPop(true);
        setOrderId(id);
    }

    const handleOpenOrder = () => {
        navigate(`/ordersList/order/${orderId}`)
    }

    const buttonsObject = [
        {text: "Retour", className: "backButton", action: (e) => setShowValidPop(false)},
        {text: "Continuer", className: "continueButton", action: handleOpenOrder},
    ]

    const headerButtons = [
        {title: "Nouvelle dépense", className: "", icon: faFileMedical, action: openEmptyOrder, showByRoleId: true},
    ]

    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setListRefresh(true);
    }

    return (
        <>
                {loading &&
                    (
                        <Loader />
                    )
                }
            {
                showValidPop &&
                <ValidPop bgcAction={(e) => setShowValidPop(false)} showValidPop={showValidPop}
                          title={"Continuer vers cette dépense ?"}
                          desc={"Cette dépense est validé, voulez-vous vraiment la modifier ?"}
                          buttonsObject={buttonsObject}/>
            }
                <MainHeader headerTitle={'DÉPENSES'} objectButtons={headerButtons}/>
            <div className="listPage">
                <div className="listPage__filterContainer">
                    <StateFilter setState={setOrderState} stateObject={stateObject} state={orderState}/>
                    <SearchComponent
                        refresh={refresh}
                        onSearchSelectChange={onSearchSelectChange}
                        onSearchSubmit={onValidFilter}
                        contentObject={contentObject}
                        interlocutor={interlocutors}
                    />
                    <SortByFilter setSort={setSort} sortOptions={sortOptions}/>
                </div>
                <div className="cards-list">
                    {orderList && orderList.map((order, k) => {
                        const element = {
                            id: order.order_form_id,
                            valid: order.valid,
                            state: order.state,
                            date: order.order_date,
                            ref: order.ref,
                            subject: order.subject,
                            lastName: order.uLastName,
                            firstName: order.uFirstName,
                            society: order.societyContactSociety,
                            totalHt: order.total_ht,
                            totalTtc: order.total_ttc,
                            directionPath: "/ordersList/order/",
                            initials: order.initials,
                        }
                        return (
                            <Card
                                key={k}
                                tableName='order_form'
                                setListRefresh={setListRefresh}
                                deleteEndPoint='c-deactive-order'
                                element={element}
                                otherButtonList={[
                                    {title: "Télécharger", icon: faDownload, className: "edit", action: () => navigate(`/ordersList/order/${order.order_form_id}`)},
                                    {title: "Envoyer", icon: faPaperPlane, className: "delete", action: () => postApiContext.deleteOrder(order.order_form_id)},
                                    {title: "imprimer", icon: faPrint, className: "edit", action: () => navigate(`/ordersList/order/${order.order_form_id}`)},
                                    {title: "dupliquer", icon: faCopy, className: "delete", action: () => postApiContext.deleteOrder(order.order_form_id)},
                                ]}
                                stateButtonsList={[
                                    {title: "Validé", icon: faCircleCheck, color: "stateGreen", name: "valid"},
                                    {title: "En cours", icon: faCircleCheck, color: "stateOrange", name: "in-progress"},
                                    {title: "Annulé", icon: faCircleCheck, color: "stateRed", name: "canceled"},
                                ]}
                                handleOpenValidItem={handleOpenValidItem}
                                roleVerif={true}

                            />
                            /*<OrderCard
                                key={k}
                                orderId={order.order_form_id}
                                refOrder={order.ref}
                                totalHT={order.total_ht}
                                totalTTC={order.total_ttc}
                                orderDate={order.order_date}
                                contactId={order.societyContactId}
                                contactLastName={order.LastName}
                                contactFirstName={order.FirstName}
                                societyContact={order.society}
                                state={order.state}
                            />*/
                        )
                    })
                    }


                </div>
            </div>
            <TotalAmonts
                totalTTC={totalTTC}
                totalHT={totalHT}
                totalVAT={totalVAT}
            />
            <div className='pagination'>
                <label htmlFor='perPage'>Éléments par page </label>
                <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                    <Pagination
                        postsPerPage={perPage}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        nbPages={nbPages}
                    />
                </div>
                <a href="#!" alt="" className="add-button"><i className="fa-solid fa-plus"></i></a>
    
        </>
    );
}

export default OrderList;