import React from "react";

const Pagination = ({ setCurrentPage, currentPage, nbPages
}) => {

    const Pages = () => {
        const maxPagesToShow = 5;
        const pageNumbers = [];

        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2))
        let endPage = Math.min(startPage + maxPagesToShow - 1, nbPages);

        if (endPage - startPage + 1 < maxPagesToShow) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;

    };

    return (
        <div className='pagination'>
            <button
                onClick={() => setCurrentPage(1)}
            //disabled={currentPage === 1 && "true"}
            //className={currentPage === 1 && "disabled"}
            >
            </button>

            <button
                onClick={() => setCurrentPage(currentPage - 1)}
            //disabled={currentPage === 1 && "true"}
            //className={currentPage === 1 && "disabled"}
            >
            </button>

            {Pages().map((pageNumber) => (
                <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                //className={currentPage === pageNumber && "active"}
                >
                    {pageNumber}
                </button>
            ))}

            <button
                onClick={() => setCurrentPage(currentPage + 1)}
            //disabled={currentPage === nbPages && "true"}
            //className={currentPage === nbPages && "disabled"}
            >

            </button>

            <button
                onClick={() => setCurrentPage(setCurrentPage = nbPages)}
            //disabled={currentPage === nbPages && "true"}
            //className={currentPage === nbPages && "disabled"}
            >
            </button>
        </div>
    );
};

export default Pagination;