import {Navigate} from "react-router-dom";
import {useAuthState} from "./context/authContext";
import roleVerify from "./utils/roleVerify";

const isAuthenticated = () => {
    //console.log("isAuthenticated", !!localStorage.getItem('acces_token'));
    let token = localStorage.getItem('acces_token');
    if (token) {
        // Vérifier si le token est expiré
        const {exp} = JSON.parse(atob(token.split('.')[1]));
        let expiration = new Date(exp * 1000);
        console.log("expiration du token en front", expiration.toLocaleTimeString());
        if (exp < Date.now() / 1000) {
            console.log("token expiré en front");
            localStorage.removeItem('acces_token');
            return false;
        }
    }
    return !!localStorage.getItem('acces_token');
};

const PrivateRouter = ({children, roleList}) => {
    const {roleId} = useAuthState();
    const test = roleVerify(roleList, roleId)
    if (test) {
        return isAuthenticated() ? children : <Navigate to="/login"/>;
    }else if (!isAuthenticated()){
        return <Navigate to="/login"/>
    }
};

export default PrivateRouter;
