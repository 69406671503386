import React, { createContext, useContext, useReducer } from 'react';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const authReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            //console.log("SET_USER", action.payload);
            return {
                ...state,
                userId: action.payload.userId,
                roleId: action.payload.roleId,
            };
        case 'LOGOUT':
            return {
                ...state,
                userId: null,
                roleId: null,
            };
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        userId: null,
        roleId: null,
    });

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);