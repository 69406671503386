import Contact from "../routes/contact/contact"
import ContactList from "../routes/contact/contactList"
import PrivateRouter from "../privateRouter";

const roles = ["1", "2", "3"];

export const contactPaths = [{
    path: "/contactsList",
    element:  <PrivateRouter roleList={roles}><ContactList /></PrivateRouter>,
},
{
    path: "/contactsList/contact/:id",
    element:  <PrivateRouter roleList={roles}><Contact /></PrivateRouter>,
},
{
    path: "/contactsList/contact",
    element:  <PrivateRouter roleList={roles}><Contact /></PrivateRouter>,
},]
