import "../../../styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../addOns/Spinner";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import CataloguesFilter from "./CataloguesFilter";
import { Pagination } from "@mui/material";
import { Link } from "react-router-dom";
import {faEuroSign, faMagnifyingGlass, faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import { PostApiContext } from "../../../context/apiCalls/postApiCalls";

function CataloguesListPop(props) {
  const showHideClassName = props.show
    ? "modal-pop display-block"
    : "modal-pop display-none";

  const postApiCalls = useContext(PostApiContext);

  const [catalogue, setCatalogue] = useState();
  const [brandList, setBrandList] = useState();
  const [familyList, setFamilyList] = useState();
  const [catalogList, setCatalogList] = useState();
  const [subFamilyList, setSubFamilyList] = useState();
  const [supplierList, setSupplierList] = useState();

  // const [catalog, setCatalog] = useState();
  // const [brand, setBrand] = useState();
  // const [family, setFamily] = useState();
  // const [subFamily, setSubFamily] = useState();
  // const [supplier, setSupplier] = useState();
  // const [refMaker, setrefMaker] = useState();
  // const [refDistributor, setrefDistributor] = useState();
  // const [designation, setDesignation] = useState();

  const [loading, setLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [whichCatalog, setWhichCatalog] = useState("full");

  const [catalogsPerso, setCatalogsPerso] = useState();
  const [lstBrands, setLstBrands] = useState();
  const [lstFamilies, setLstFamilies] = useState();
  const [lstSubFamilies, setLstSubFamilies] = useState();
  const [lstSuppliers, setLstSuppliers] = useState();

  const [nbPages, setNbPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState();

  const crossRotate = showFilter ? "cross-rotate" : "cross-reverse";

  useEffect(() => {
    if (loading === true) {
      document.getElementById("pagination").classList.add("hidden");
    }

    const values = {
      whichCatalog: whichCatalog,
    };

    postApiCalls.postCatalogueList(currentPage, perPage, values)
      .then((response) => {
        //console.log(response);
        setCatalogue(response.listItems);
        setBrandList(response.brandList);
        setFamilyList(response.familyList);
        setCatalogList(response.listCatalogs);
        setSubFamilyList(response.subFamilyList);
        setSupplierList(response.supplierList);
        setNbPages(response.nbPages);
        setPerPage(response.perPage);
        // document.getElementById("pagination").classList.remove("hidden");
        setLoading(false);
      });

    let full = document.getElementById("full");
    let mine = document.getElementById("mine");
    let supplier = document.getElementById("supplier");

    full.classList.remove("active");
    mine.classList.remove("active");
    supplier.classList.remove("active");

    switch (whichCatalog) {
      case "full":
        full.classList.add("active");
        break;
      case "mine":
        mine.classList.add("active");
        break;
      case "supplier":
        supplier.classList.add("active");
        break;
      default:
        break;
    }
  }, [whichCatalog, currentPage, perPage, loading, postApiCalls]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_CATALOG_GET_PERSO}`).then((response) => {
        setCatalogsPerso(response.data.editCatalogs);
      })
      .catch((error) => {
        console.log(error);
      });
    axios.get(`${process.env.REACT_APP_ITEM_GET}`).then((response) => {
        setLstBrands(response.data.listBrands);
        setLstFamilies(response.data.listFamilies);
        setLstSubFamilies(response.data.listSubFamilies);
        setLstSuppliers(response.data.listSuppliers);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function catalogFilter() {
    if (!showFilter) {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
  }

  // const changeValueFilter = (e) => {

  //   console.log(e.target.name);
  //   console.log(e.target.value);

  //   switch (e.target.name) {
  //     case "catalog":
  //       setCatalog(e.target.value);
  //       break;
  //     case "family":
  //       setFamily(e.target.value);
  //       break;
  //     case "subFamily":
  //       setSubFamily(e.target.value);
  //       break;
  //     case "brand":
  //       setBrand(e.target.value);
  //       break;
  //     case "supplier":
  //       setSupplier(e.target.value);
  //       break;
  //     case "refFab":
  //       setrefMaker(e.target.value);
  //       break;
  //     case "refDis":
  //       setrefDistributor(e.target.value);
  //       break;
  //     case "designation":
  //       setDesignation(e.target.value);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const onValidFilter = async (e) => {
  //   e.preventDefault();

  //   setCatalogue(null);
  //   setLoading(true);

  //   const customHeaders = {
  //     "content-type": "application/json",
  //   };

  //   const values = {
  //     catalog: catalog,
  //     brand: brand,
  //     family: family,
  //     subFamily: subFamily,
  //     provider: supplier,
  //     refMaker: refMaker,
  //     refDistributor: refDistributor,
  //     designation: designation,
  //   };
  //   //console.log(values);
  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_CATALOG_LIST_FILTER}`,
  //       values,
  //       customHeaders
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       setCatalogue(response.data.listItems);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const closeCatalogues = () => {
    props.handleCloseCatalogues();
  };

  const newItem = () => {
    document.getElementById("blur").classList.add("blur");
    document.getElementById("pop-up").classList.remove("hidden");

    let selectOptions = '<select id="pop-up-select-catalog">';
    selectOptions += "<option value=null> Catalogue </option>";
    catalogsPerso.forEach((catalog) => {
      selectOptions +=
        '<option value="' + catalog.id + '">' + catalog.name + "</option>";
    });
    selectOptions += "</select>";

    let selectFamily = '<select id="pop-up-select-family">';
    selectFamily += "<option value=null> Famille </option>";
    lstFamilies.forEach((family) => {
      selectFamily +=
        '<option value="' + family.family + '">' + family.family + "</option>";
    });
    selectFamily += "</select>";

    let selectSubFamily = '<select id="pop-up-select-sub-family">';
    selectSubFamily += "<option value=null> Sous Famille </option>";
    lstSubFamilies.forEach((subFamily) => {
      selectSubFamily +=
        '<option value="' +
        subFamily.subfamily +
        '">' +
        subFamily.subfamily +
        "</option>";
    });
    selectSubFamily += "</select>";

    let selectBrand = '<select id="pop-up-select-brand">';
    selectBrand += "<option value=null> Marque </option>";
    lstBrands.forEach((brand) => {
      selectBrand +=
        '<option value="' + brand.brand + '">' + brand.brand + "</option>";
    });
    selectBrand += "</select>";

    let selectSupplier = '<select id="pop-up-select-supplier">';
    selectSupplier += "<option value=null> Fournisseur </option>";
    lstSuppliers.forEach((supplier) => {
      selectSupplier +=
        '<option value="' +
        supplier.supplier +
        '">' +
        supplier.supplier +
        "</option>";
    });
    selectSupplier += "</select>";

    document.getElementById("pop-up").innerHTML =
      '<div class="pop-up-new-item" id="pop-up-new-item">' +
      '<h4 class="pop-up-new-item-line pop-up-title">Ajouter un article</h4>' +
      '<form id="pop-up-submit" class="pop-up-submit">' +
      '<div class="pop-up-new-item-line pop-up-catalog">Catalogue ' +
      selectOptions +
      "</div>" +
      '<div class="pop-up-new-item-block-one">' +
      '<div class="pop-up-new-item-line"><p>Famille</p> ' +
      selectFamily +
      '<input id="pop-up-new-family" placeholder="Ajouter une famille"></input></div>' +
      '<div class="pop-up-new-item-line"><p>Sous Famille</p> ' +
      selectSubFamily +
      '<input id="pop-up-new-sub-family" placeholder="Ajouter une sous famille"></input></div>' +
      '<div class="pop-up-new-item-line"><p>Marque</p> ' +
      selectBrand +
      '<input id="pop-up-new-brand" placeholder="Ajouter une marque"></input></div>' +
      '<div class="pop-up-new-item-line"><p>Fournisseur</p> ' +
      selectSupplier +
      '<input id="pop-up-new-supplier" placeholder="Ajouter un fournisseur"></input></div>' +
      "</div>" +
      '<div class="pop-up-new-item-block-two">' +
      '<div class="pop-up-new-item-line">Ref. Fabricant <input id="ref-fab"></input></div>' +
      '<div class="pop-up-new-item-line">Ref. Distributeur <input id="ref-dis"></input></div>' +
      '<div class="pop-up-new-item-line">Conditionement <input id="packaging"></input></div>' +
      '<div class="pop-up-new-item-line">Prix d\'achat HT* <input id="purchase-price"></input></div>' +
      '<div class="pop-up-new-item-line">Prix de vente HT* <input id="sale-price"></input></div>' +
      "</div>" +
      '<div class="pop-up-new-item-line pop-up-area">Désignation* <textarea id="name"></textarea></div>' +
      '<div class="pop-up-validation"><p class="pop-up-line pop-up-cancel" id="pop-up-cancel">Annuler</p>' +
      '<button class="pop-up-line pop-up-valid" id="pop-up-valid" type="submit">Valider</button></div>' +
      "</form>" +
      "</div>";

    document
      .getElementById("pop-up-select-family")
      .addEventListener("change", function lockFamily() {
        if (document.getElementById("pop-up-select-family").value === "null") {
          document.getElementById("pop-up-new-family").disabled = false;
        } else {
          document.getElementById("pop-up-new-family").disabled = true;
        }
      });

    document
      .getElementById("pop-up-select-sub-family")
      .addEventListener("change", function lockFamily() {
        if (
          document.getElementById("pop-up-select-sub-family").value === "null"
        ) {
          document.getElementById("pop-up-new-sub-family").disabled = false;
        } else {
          document.getElementById("pop-up-new-sub-family").disabled = true;
        }
      });

    document
      .getElementById("pop-up-select-brand")
      .addEventListener("change", function lockFamily() {
        if (document.getElementById("pop-up-select-brand").value === "null") {
          document.getElementById("pop-up-new-brand").disabled = false;
        } else {
          document.getElementById("pop-up-new-brand").disabled = true;
        }
      });

    document
      .getElementById("pop-up-select-supplier")
      .addEventListener("change", function lockFamily() {
        if (document.getElementById("pop-up-select-supplier").value === "null") {
          document.getElementById("pop-up-new-supplier").disabled = false;
        } else {
          document.getElementById("pop-up-new-supplier").disabled = true;
        }
      });

    document
      .getElementById("pop-up-valid")
      .addEventListener("click", function validNewItem(e) {
        e.preventDefault();

        let idCatalog = document.getElementById("pop-up-select-catalog").value;
        let family = "";
        let subFamily = "";
        let brand = "";
        let supplier = "";

        document.getElementById("pop-up-select-family").value === "null"
          ? (family = document.getElementById("pop-up-new-family").value)
          : (family = document.getElementById("pop-up-select-family").value);
        document.getElementById("pop-up-select-sub-family").value === "null"
          ? (subFamily = document.getElementById("pop-up-new-sub-family").value)
          : (subFamily = document.getElementById(
            "pop-up-select-sub-family"
          ).value);
        document.getElementById("pop-up-select-brand").value === "null"
          ? (brand = document.getElementById("pop-up-new-brand").value)
          : (brand = document.getElementById("pop-up-select-brand").value);
        document.getElementById("pop-up-select-supplier").value === "null"
          ? (supplier = document.getElementById("pop-up-new-supplier").value)
          : (supplier = document.getElementById(
            "pop-up-select-supplier"
          ).value);

        let refFab = document.getElementById("ref-fab").value;
        let refDis = document.getElementById("ref-dis").value;
        let packaging = document.getElementById("packaging").value;
        let purchasePrice = document.getElementById("purchase-price").value;
        let salePrice = document.getElementById("sale-price").value;
        let name = document.getElementById("name").value;

        const customHeaders = {
          "content-type": "application/json",
        };

        const values = {
          idCatalog: idCatalog,
          family: family,
          subFamily: subFamily,
          brand: brand,
          supplier: supplier,
          refFab: refFab,
          refDis: refDis,
          packaging: packaging,
          purchasePrice: purchasePrice,
          salePrice: salePrice,
          name: name,
        };

        console.log(values);

        axios
          .post(`${process.env.REACT_APP_ITEM_NEW}`, values, customHeaders)
          .then((response) => {
            console.log(response.data);
            document.getElementById("blur").classList.remove("blur");
            document.getElementById("pop-up").classList.add("hidden");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    document
      .getElementById("pop-up-cancel")
      .addEventListener("click", function cancelPopUp() {
        document.getElementById("blur").classList.remove("blur");
        document.getElementById("pop-up").classList.add("hidden");
      });
  };

  const changePage = (e, value) => {
    setCurrentPage(value);
    setCatalogue(null);
    setLoading(true);
  };

  const changePerPage = (e) => {
    setPerPage(e.target.value);
    setCurrentPage(1);
    setCatalogue(null);
    setLoading(true);
  };

  return (
    <>
      <div className={showHideClassName}>
        <div className="main pop">
          <div className="pop-up hidden" id="pop-up"></div>
          {loading && <Spinner />}
          <FontAwesomeIcon
            icon={faXmark}
            onClick={closeCatalogues}
            viewBox="0 0 400 400"
            size="2xl"
          />
          <div className="list" id="blur">
            <div className="toolbar">
              <div className="title">
                <h2>
                  <span>CATALOGUE</span>
                </h2>
              </div>
              <div className="button" onClick={() => newItem()}>
                <Link to='/'>
                  <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>{" "}
                  Ajouter un article
                </Link>
              </div>
            </div>
            <div className="type">
              <div
                onClick={() => {
                  setWhichCatalog("full");
                  setCatalogue(null);
                  setLoading(true);
                }}
                id="full"
                className="full active"
              >
                <h3>TOUS LES ARTICLES</h3>
              </div>
              <div
                onClick={() => {
                  setWhichCatalog("mine");
                  setCatalogue(null);
                  setLoading(true);
                }}
                id="mine"
                className="mine"
              >
                <h3>MES ARTICLES</h3>
              </div>
              <div
                onClick={() => {
                  setWhichCatalog("supplier");
                  setCatalogue(null);
                  setLoading(true);
                }}
                id="supplier"
                className="supplier"
              >
                <h3>CATALOGUES FOURNISSEUR</h3>
              </div>
            </div>
            <div className="search-bar">
              <p onClick={catalogFilter}>
                <span>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                  RECHERCHER UN ARTICLE
                </span>
                <FontAwesomeIcon
                  className={crossRotate}
                  icon={faPlus}
                  viewBox="0 0 350 350"
                />
              </p>
              <CataloguesFilter
                showFilter={showFilter}
                // onValidFilter={onValidFilter}
                // changeValueFilter={changeValueFilter}
                brandList={brandList}
                familyList={familyList}
                catalogList={catalogList}
                subFamilyList={subFamilyList}
                supplierList={supplierList}
              />
            </div>
            <div className="cards-list items">
              {catalogue?.map((article, j) => {
                return (
                  <div
                    className="card item"
                    key={article.id}
                    onClick={() => props.handleSelectedArticle(article)}
                  >
                    <div className="tileCard">
                      <div className="state valid">
                        <p className="ref">
                          <span>{article.name}</span>
                        </p>
                      </div>
                      <div className="families">
                        <p>{article.family}</p>
                        <p>{article.subfamily}</p>
                      </div>
                      <div className="price">
                        {/* <FontAwesomeIcon icon={faEuroSign} /> */}
                        <p className="ht">
                          {article.basePrice}{" "}
                          <FontAwesomeIcon icon={faEuroSign} /> HT
                        </p>
                        <p className="ttc">
                          {article.netPrice}{" "}
                          <FontAwesomeIcon icon={faEuroSign} /> TTC
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="pagination" id="pagination">
            <label htmlFor="perPage">Éléments par page </label>
            <select
              name="perPage"
              value={perPage}
              onChange={(e) => changePerPage(e)}
            >
              <option value="9">9</option>
              <option value="51">51</option>
              <option value="99">99</option>
            </select>
            <Pagination
              count={nbPages}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
              onChange={(e, value) => changePage(e, value)}
              className="select-page"
              page={currentPage}
            />
          </div>

          <Link to='/' className="add-button">
            <FontAwesomeIcon icon={faPlus} />
          </Link>
        </div>
      </div>
    </>
  );
}

export default CataloguesListPop;
