import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import DeletButton from "../addOns/deletButton";
import {useAuthState} from "../../context/authContext";
export default function Interlocutor({
    interlocutors,
    handleShowInterlocutorPop,
    updateInterlocutor,
    setRefresh,
    refresh,
}) {

  const {roleId}  = useAuthState();

    const validPopContent = {
        title: "Suppression",
        desc: "Êtes-vous sûr de vouloir supprimer le compte de cet interlocuteur ?",
    }

  // faire un popup pour l'ajout d'un interlocuteur quand click sur le bouton
  return (
    <>
      <section className="setInpSection">
        <h2>INTERLOCUTEURS - AFFAIRE SUIVIE PAR</h2>

        <div className="setInputContainer interlocuteur">
          {interlocutors?.map((interlocutor, key) => {
            return (
                <div className="interlocutorContent settingsInterlocutor" key={key}>
                  <div className="settingsInterlocutor__content" onClick={() => updateInterlocutor(interlocutor.id)}>
                    <div className="setInterlocuteurInp settingsInterlocutor__content_name">
                      <label htmlFor="">Prénom / Nom</label>
                      <p className="settingsInterlocutor__content_name_civility">{interlocutor?.civility_id?.toString() === "1" ? "Mr" : interlocutor?.civility_id?.toString() === "2" ? "Mme" : ""}</p>
                      <p className="settingsInterlocutor__content_name_first">{interlocutor.first_name}</p>
                      <p className="settingsInterlocutor__content_name_last">{interlocutor.last_name}</p>
                    </div>
                    <div className={"setInterlocuteurInp settingsInterlocutor__content_function"}>
                      <label htmlFor="">Fonction</label>
                      <p className="settingsInterlocutor__content_function_inp">{interlocutor.function}</p>
                    </div>
                    <div className="setInterlocuteurInp settingsInterlocutor__content_mail">
                      <label htmlFor="">Mail</label>
                      <p className="settingsInterlocutor__content_mail_inp">{interlocutor.email}</p>
                    </div>
                    <div className="setInterlocuteurInp settingsInterlocutor__content_initials">
                      <label htmlFor="">Initiales</label>
                      <p className="settingsInterlocutor__content_initials_inp">{interlocutor.initials}</p>
                    </div>
                  </div>
                  {//Interlocuteur ne peut pas supprimer un interlocuteur de son niveau ou supérieur
                    roleId <= 3 &&
                  <DeletButton className={`interloButton ${interlocutor?.role_id <= roleId ? "invisible" : ""}`} id={interlocutor.id} url={"c-delete-interlocutor"} setRefresh={setRefresh} refresh={refresh} validPopContent={validPopContent}/>
                  }
                </div>
            );
          })}
          <div
            onClick={handleShowInterlocutorPop}
            className="setInterlocuteurBtn"
          >
            <FontAwesomeIcon icon={faPlus} />
            <p>Ajouter un interlocuteur</p>
          </div>
        </div>
      </section>
    </>
  );
}
