import { useState } from "react";
import { createContext } from "react";

export const AppContext = createContext(null);

export default function AppProvider(props) {
    const [showCreateProspectPopup, setShowCreateProspectPopup] = useState(false);
    const [showProspectListPopup, setShowProspectListPopup] = useState(false);
    const [showEditProspectPopup, setShowEditProspectPopup] = useState(false);

    const [showContactPopup, setShowContactPopup] = useState(false);
    const [showQuotePopup, setShowQuotePopup] = useState(false);
    const [showInvoicePopup, setShowInvoicePopup] = useState(false);
    const [showOrderPopup, setShowOrderPopup] = useState(false);

    const [blur, setBlur] = useState(false);

    const [prospectValue, setProspectValue] = useState([]);
    const [idContact, setIdContact] = useState(null);
    const [idprospect, setIdprospect] = useState(null);
    const [idQuote, setIdQuote] = useState(null);
    const [idInvoice, setIdInvoice] = useState(null);
    const [idOrder, setIdOrder] = useState(null);

    const [reload, setReload] = useState(false);

    const [contactId, setContactId] = useState(null);
    const [contactPopOpen, setContactPopOpen] = useState(false);
    const [test, setTest] = useState(false);

    const [depositPageName, setDepositPageName] = useState(null);

    const [showMailPop, setShowMailPop] = useState(false)
    const [mailId, setMailId] = useState(null)
    
    const [redirection, setRedirection] = useState("")

    return (
        <AppContext.Provider
            value={{
                setMailId,
                mailId,
                setShowMailPop,
                showMailPop,
                contactPopOpen,
                setContactPopOpen,
                contactId,
                setContactId,
                idQuote,
                setIdQuote,
                idInvoice,
                setIdInvoice,
                idOrder,
                setIdOrder,
                showQuotePopup,
                setShowQuotePopup,
                showInvoicePopup,
                setShowInvoicePopup,
                showOrderPopup,
                setShowOrderPopup,
                reload,
                setReload,
                idprospect,
                setIdprospect,
                showEditProspectPopup,
                setShowEditProspectPopup,
                prospectValue,
                setProspectValue,
                idContact,
                setIdContact,
                setShowCreateProspectPopup,
                showCreateProspectPopup,
                setShowProspectListPopup,
                showProspectListPopup,
                blur,
                setBlur,
                test,
                setTest,
                depositPageName,
                setDepositPageName,
                redirection,
                setRedirection,
                showContactPopup,
                setShowContactPopup
            }}>
            {props.children}
        </AppContext.Provider>
    );
}
