import React from "react";
function TotalAmonts({totalTTC,totalHT,totalVAT}){

const formatNumber = (number) =>{
    return parseFloat(number).toFixed(2);
}

    return(
        <div className="totalamonts">
            
            <table>
                <thead>
                    <tr>
                        <td>Montant Total HT</td>
                        <td>Montant Total TVA</td>
                        <td>Montant Total TTC</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{formatNumber(totalHT)} €</td>
                        <td>{formatNumber(totalVAT)} €</td>
                        <td>{formatNumber(totalTTC)} €</td>
                    </tr>
                </tbody>
            </table>


        </div>
    );
}

export default TotalAmonts;