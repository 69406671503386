import Total from "./total";
import TvaList from "./tvaList";

export default function PriceRecap({discount, handleDiscountChange, totalInvoice, vatList, totalHt, totalTtc}) {
    return (
        <div className="priceRecap">
            <Total title="Sous total HT" price={`${totalInvoice} €`}/>
            <div className="priceRecap__discount">
                <p className="priceRecap__discount_title">Remise globale</p>
                <div className='priceRecap__discount_priceContainer'>
                    <input onChange={handleDiscountChange}
                           className="priceRecap__discount_priceContainer_input"
                           onWheel={(e) => e.target.blur()}
                           defaultValue={discount} type="number" placeholder="0,00 €"/>
                    <p className="priceRecap__discount_priceContainer_price">{discount ? discount : 0} €</p>
                </div>
            </div>
            <Total title="Total HT" price={totalHt}/>
            <TvaList tvaList={vatList}/>
            <Total title="Total net TTC" price={totalTtc}/>
        </div>
    )
}