import {useContext, useEffect, useRef, useState} from "react";
import DepositCard from "../../components/customer/deposit/depositCard";
import Loader from "../../components/addOns/Spinner";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import MainHeader from "../../components/addOns/headers/MainHeader";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";

function DepositsList() {

    const navigate = useNavigate();
    // Refs
    const firstNameRef = useRef("");
    const lastNameRef = useRef("");
    const depositRef = useRef("");
    const quoteRef = useRef("");
    const invoiceRef = useRef("");

    // States
    const [deposits, setDeposits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('datedown');
    const [interlocutor, setInterlocutor] = useState([]);
    const [searchSelect, setSearchSelect] = useState("");
    const [searchReset, setSearchReset] = useState(false);
    const [stateUpdtate, setStateUpdtate] = useState(false);
    const [depositState, setDepositState] = useState("");

    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);

    // Consts
    const searchData = {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        depositRef: depositRef.current.value,
        quoteRef: quoteRef.current.value,
        invoiceRef: invoiceRef.current.value,
        interlocutor: searchSelect
    }

    useEffect(() => {
        setLoading(true);
        if (filter) {
            getApiContext.getDepositsList(depositState, filter)
                .then(response => {
                    console.log("response 1")
                    //console.log(response);
                    setInterlocutor(response.listInterlocutors);
                    setDeposits(response.depositsList);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        } else if (!filter || !searchSelect) {
            getApiContext.getDepositsList()
            //axios.get(`${process.env.REACT_APP_DEPOSIT_LIST}`)
                .then(response => {
                    //console.log(response);
                    setInterlocutor(response.listInterlocutors);
                    setDeposits(response.depositsList);
                    // setPerPage(response.perPage);
                    // setNbPages(response.nbPages);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }
        setStateUpdtate(false)
        setSearchReset(false)
    }, [filter, searchSelect, searchReset, stateUpdtate, getApiContext, depositState]);

    const test = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setSearchReset(true)
    }

    const contentObject = {
        ContentName: "ACOMPTE",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "refAconmpte", title: "Référence Acompte", type: "text", ref: depositRef },
            { name: "refDevis", title: "Référence Devis", type: "text", ref: quoteRef },
            { name: "refFacture", title: "Référence Facture", type: "text", ref: invoiceRef },
        ]
    }

    const onSearchSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        console.log("searchData", searchData);
        postApiContext.postDepositsList(searchData)
        //axios.post(`${process.env.REACT_APP_DEPOSIT_LIST}`, searchData)
            .then(response => {
                console.log(response.data);
                setDeposits(response.data.depositsList);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }

    const onSearchSelectChange = (e) => {
        setSearchSelect(e.target.value)
    }

    const changePerPage = () => {
        // setPerPage(e.target.value);
        // setCurrentPage(1);
    };

    const stateObject = [
        { valid: "", name: "Tous les acomptes" },
        { valid: 1, name: "Acomptes payés" },
        { valid: 0, name: "Acomptes impayés" }
    ];

    const openEmptyDeposit = () => {
        navigate("/depositsList/deposit/")
    }

    const headerButtons = [
        {title: "Nouveau acompte", className: "", icon: faFileMedical, action: openEmptyDeposit, showByRoleId: true},
    ]

    const sortOptions = {
        options: [
            { value: "refup", content: "Références croissantes" },
            { value: "refdown", content: "Références décroissantes" },
            { value: "dateup", content: "Date de création croissante" },
            { value: "datedown", content: "Date de création décroissante" },
        ]
    }
    return (
        <>
            {/* <TabBar
                elem='deposits'
            /> */}
                {loading && < Loader />}
            <MainHeader headerTitle={"ACOMPTES"} objectButtons={headerButtons}/>
                <div className="listPage">
                    <div className="listPage__filterContainer">
                        <StateFilter setState={setDepositState} state={depositState} stateObject={stateObject}/>
                        <SearchComponent refresh={test} onSearchSelectChange={onSearchSelectChange} onSearchSubmit={onSearchSubmit} contentObject={contentObject} interlocutor={interlocutor} />
                        <SortByFilter setSort={setFilter} sortOptions={sortOptions}/>
                    </div>
                    {
                        deposits && deposits.length > 0 &&
                        <DepositCard deposits={deposits}/>
                    }
                </div>
                <div className='pagination'>
                            <label htmlFor='perPage'>Éléments par page </label>
                            <select name='perPage' onChange={(e) => changePerPage(e)}>
                            {/* <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}> */}
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="300">300</option>
                                <option value="500">500</option>
                            </select>
                            {/* <Pagination 
                                // postsPerPage={perPage}
                                // setCurrentPage={setCurrentPage}
                                // currentPage={currentPage}
                                // nbPages={nbPages}
                            />*/}
                </div>
        </>
    )

}

export default DepositsList;