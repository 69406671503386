import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useContext, useEffect, useRef, useState} from "react";
import AddFile from "./../addOns/addFile";
import InterlocutorPop from "./InterlocutorPop";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
export default function InformationGroup(props) {
  //const formData = new FormData();

  const postApiCall = useContext(PostApiContext);

  // REF
  const socialReasonRef = useRef();
  const addressRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();
  const siretRef = useRef();
  const apeRef = useRef();
  const rcsRef = useRef();
  const socialFormRef = useRef();
  const tvaRef = useRef();
  const phoneRef = useRef();
  const mobileRef = useRef();
  const faxRef = useRef();
  const websiteRef = useRef();
  const emailRef = useRef();

  const marginRef = useRef();
  const validityPeriodRef = useRef();
  const invoicePaymentDeadlineRef = useRef();
  const purchaseOrderPrefixRef = useRef();
  const purchaseOrdreIncrementRef = useRef();
  const invoicePrefixRef = useRef();
  const invoiceIncrementRef = useRef();
  const depositPrefixRef = useRef();
  const depositIncrementRef = useRef();
  const quotePrefixRef = useRef();
  const quoteIncrementRef = useRef();
  const creditPrefixRef = useRef();
  const creditIncrementRef = useRef();
  const deliveryPrefixNote = useRef();
  const deliveryIncrementNote = useRef();
  const quoteMentionRef = useRef();
  const invoiceMentionRef = useRef();
  const orderMentionRef = useRef();
  const informationRef = useRef();
  //const imageRef = useRef();
  //const pdfRef = useRef();

  /* const interCivility = useRef();
  const interLastName = useRef();
  const interFirstName = useRef();
  const interFunction = useRef();
  const interEmail = useRef();
  const interInitials = useRef(); */

  /* const interRefObject = {
    interCivility,
    interLastName,
    interFirstName,
    interFunction,
    interEmail,
    interInitials,
  }; */
  // console.log("props.society", props.society);

  const logoExtention = ["jpg", "jpeg", "png", "svg"];
  const pdfExtention = ["pdf"];

  // STATES
  const [society, setSociety] = useState(props.society);
  const [interlocutorsData, setInterlocutorsData] = useState([]);
  const [logoFile, setLogoFile] = useState(null);
  const [cgvFile, setCgvFile] = useState(null);
  //const [array, setArray] = useState([]);
  const [showInterlocutorPop, setShowInterlocutorPop] = useState(false);

  useEffect(() => {
    setSociety(props.society);
    setInterlocutorsData(props.interlocutors);

  }, [props, props.society, props.interlocutors, props.accountant]);


  function fileNameFormat(name) {
    if (name !== undefined) {
      const specialCharacterNameReformat = name.replace(/[^\w\s.]/gi, '').replace(/_/g, '');

      const date = new Date();
      const timeFormater = date.toISOString().replace(/[-T:]/g, '').slice(0, -5);
      // console.log("file formater", timeFormater + '' + specialCarachterNameReformat);
      return timeFormater + '' + specialCharacterNameReformat;
    } else {
      // Gérer le cas où 'name' est undefined
      console.error("La variable 'name' est indéfinie.");
    }

  }

  // console.log("logoFile", logoFile);
  // console.log("cgvFile", cgvFile);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    let formData = new FormData();
    
    // console.log("logoFile in sub", logoFile);
    // console.log("cgvFile in sub", cgvFile);
    const interlocutorsDataString = JSON.stringify(interlocutorsData);

    // Ajout de la chaîne JSON au FormData

    const values = {
      socialReason: socialReasonRef.current.value,
      address: addressRef.current.value,
      postalCode: postalCodeRef.current.value,
      city: cityRef.current.value,
      siret: siretRef.current.value,
      ape: apeRef.current.value,
      rcs: rcsRef.current.value,
      socialForm: socialFormRef.current.value,
      tva: tvaRef.current.value,
      phone: phoneRef.current.value,
      mobile: mobileRef.current.value,
      fax: faxRef.current.value,
      website: websiteRef.current.value,
      email: emailRef.current.value,
      margin: marginRef.current.value,
      validityPeriod: validityPeriodRef.current.value,
      invoicePaymentDeadline: invoicePaymentDeadlineRef.current.value,
      purchaseOrder: purchaseOrderPrefixRef.current.value,
      purchaseOrderIncrement: purchaseOrdreIncrementRef.current.value,
      invoice: invoicePrefixRef.current.value,
      invoiceIncrement: invoiceIncrementRef.current.value,
      deposit: depositPrefixRef.current.value,
      depositIncrement: depositIncrementRef.current.value,
      quote: quotePrefixRef.current.value,
      quoteIncrement: quoteIncrementRef.current.value,
      credit: creditPrefixRef.current.value,
      creditIncrement: creditIncrementRef.current.value,
      deliveryNote: deliveryPrefixNote.current.value,
      deliveryNoteIncrement: deliveryIncrementNote.current.value,
      quoteMention: quoteMentionRef.current.value,
      invoiceMention: invoiceMentionRef.current.value,
      orderMention: orderMentionRef.current.value,
      information: informationRef.current.value,
      interlocutors: interlocutorsDataString,
      logoFile: logoFile,
      cgvFile: cgvFile,
    };
    //console.log(values);
    
    for (var cle in values) {
      if (cle === "logoFile" && values[cle] !== null) {
          console.log("logoFile", values[cle]);
          formData.append(cle, values[cle], fileNameFormat(values[cle].name));
      } else if (values[cle] !== null) {
          formData.append(cle, values[cle]);
      }
    }

    postApiCall.postSettings(values, logoFile, cgvFile)
      .then((response) => {
        props.setForm(true);
        props.setRefresh(true);
        console.log("refresh 2")
        //console.log("post respnse", response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addNewInterlocutor = () => {
    let body = document.body;
    setShowInterlocutorPop(!showInterlocutorPop);
    if (showInterlocutorPop) {
      body.style.overflow = "visible";
    } else {
      body.style.overflow = "hidden";
    }
  };

  return (
    <>
      {showInterlocutorPop && (
        <InterlocutorPop addNewInterlocutor={addNewInterlocutor} />
      )}
      <form
        className={showInterlocutorPop ? "blur" : ""}
        id="settingsForm"
        onSubmit={handleSubmit}
      >
        <div className="setInpSection">
          <h2>IDENTITÉ DE L'ENTREPRISE</h2>
          <div className="setInputContainer">
            <div className="setLeftInpGroup">
              <div className="setInpGroup">
                <label htmlFor="">Raison sociale*</label>
                <input
                  defaultValue={society?.societyName}
                  ref={socialReasonRef}
                  type="text"
                />
              </div>
              <div className="setInpGroup">
                <label htmlFor="">Adresse*</label>
                <input
                  defaultValue={society?.adress1}
                  ref={addressRef}
                  type="text"
                />
              </div>
              <div className="setInpGroupLine3">
                <label htmlFor="">Code Postal* / Ville*</label>
                <div>
                  <input
                    defaultValue={society?.postalCode}
                    ref={postalCodeRef}
                    type="text"
                  />
                  <input
                    defaultValue={society?.city}
                    ref={cityRef}
                    type="text"
                  />
                </div>
              </div>
              <div className="setInpGroupLine4">
                <label htmlFor="">Siret*</label>
                <div className="siretDiv">
                  <input
                    className="siretInput"
                    defaultValue={society?.siret}
                    ref={siretRef}
                    type="text"
                  />
                  <label htmlFor="">APE</label>
                  <input className="siretInput" defaultValue={society?.ape} ref={apeRef} type="text" />
                </div>
              </div>
              <div className="setInpGroup">
                <label htmlFor="">RCS* / RM*</label>
                <input defaultValue={society?.rcs} ref={rcsRef} type="text" />
              </div>
              <div className="setTextGroup">
                <p className={"lightFont"}>
                  <span>Artisan</span> : saisir votre n° RM si vous en avez un,
                  précédé des lettres RM.
                </p>
                <p className={"lightFont"}>
                  <span>Commerçant</span> : saisir votre n° RCS si vous en avez
                  un, précédé des lettres RCS.
                </p>
              </div>
              <div className="setInpGroup">
                <label htmlFor="">Forme sociale</label>
                <input
                  defaultValue={society?.legalStatus}
                  ref={socialFormRef}
                  type="text"
                />
              </div>
              <div className="setInpGroup">
                <label htmlFor="">TVA intra</label>
                <input
                  defaultValue={society?.intraCommunityVat}
                  ref={tvaRef}
                  type="text"
                />
              </div>
            </div>
            <div className="setRightInpGroup">
              <div className="setInpGroup">
                <label htmlFor="">Téléphone principal</label>
                <input
                    defaultValue={society?.landlinePhone}
                    ref={phoneRef}
                    type="text"
                />
              </div>
              <div className="setInpGroup">
                <label htmlFor="">Mobile</label>
                <input
                    defaultValue={society?.mobilePhone}
                    ref={mobileRef}
                    type="text"
                />
              </div>
              <div className="setInpGroup">
                <label htmlFor="">Fax</label>
                <input
                    defaultValue={society?.faxPhone}
                    ref={faxRef}
                    type="text"
                />
              </div>
              <div className="setInpGroup">
                <label htmlFor="">Site web</label>
                <input
                    defaultValue={society?.website}
                    ref={websiteRef}
                    type="text"
                />
              </div>
              <div className="setInpGroup">
                <label htmlFor="">E-mail</label>
                <input
                    defaultValue={society?.email}
                    ref={emailRef}
                    type="text"
                />
              </div>
              <div className="dropZoneFileContainer">
                <AddFile key={`fileType-logo`} fileType={"logo"} setFile={setLogoFile} receivedFile={society?.societyLogo} extentionFiles={logoExtention}
                         setRefresh={props.setRefresh} refresh={props.refresh}/>
                <AddFile key={`fileType-cgv`} fileType={"cgv"} setFile={setCgvFile} receivedFile={society?.cgu} extentionFiles={pdfExtention} setRefresh={props.setRefresh} refresh={props.refresh}/>
              </div>
            </div>
          </div>
        </div>
        <div className="setInpSection">
          <h2>PRÉSENTATION DES DEVIS ET FACTURES</h2>

          <div className="setInputContainer">
            <div className="setLeftInpGroup">
              <div className="setInpGroup">
                <label htmlFor="">Taux de marge</label>
                <div className="settingsMarginDiv">
                  <input
                    defaultValue={society?.margin}
                    ref={marginRef}
                    type="text"
                  />
                  <FontAwesomeIcon
                    title="% Ce taux s'appliquera automatiquement au prix d'achat de vos
                catalogues, mais chaque article reste modifiable manuellement au
                moment de la création du devis."
                    icon={faCircleInfo}
                  />
                </div>
              </div>
              <div className="setInpGroupLine4">
                <label htmlFor="">Délai de validité / paiement : Devis</label>
                <div>
                  <input
                    defaultValue={society?.delay1}
                    ref={validityPeriodRef}
                    type="text"
                  />
                  <label htmlFor="">Factures</label>
                  <input
                    defaultValue={society?.delay2}
                    ref={invoicePaymentDeadlineRef}
                    type="text"
                  />
                </div>
              </div>
              <div className="setInpGroupLine4">
                <label htmlFor="">Bon de commande : Préfixe</label>
                <div>
                  <input
                    defaultValue={society?.orderRefPrefix}
                    ref={purchaseOrderPrefixRef}
                    type="text"
                  />
                  <label htmlFor="">Incrément</label>
                  <input
                    defaultValue={society?.orderRefInc}
                    ref={purchaseOrdreIncrementRef}
                    type="text"
                  />
                </div>
              </div>
              <div className="setInpGroupLine4">
                <label htmlFor="">Facture : Préfixe</label>
                <div>
                  <input
                    defaultValue={society?.invoiceRefPrefix}
                    ref={invoicePrefixRef}
                    type="text"
                  />
                  <label htmlFor="">Incrément</label>
                  <input
                    defaultValue={society?.invoiceRefInc}
                    ref={invoiceIncrementRef}
                    type="text"
                  />
                </div>
              </div>
              <div className="setInpGroupLine4">
                <label htmlFor="">Acompte : Préfixe</label>
                <div>
                  <input
                    defaultValue={society?.creditRefPrefix}
                    ref={creditPrefixRef}
                    type="text"
                  />
                  <label htmlFor="">Incrément</label>
                  <input
                    defaultValue={society?.creditRefInc}
                    ref={creditIncrementRef}
                    type="text"
                  />
                </div>
              </div>
              <div className="setInpGroupLine4">
                <label htmlFor="">Devis : Préfixe</label>
                <div>
                  <input
                    defaultValue={society?.quoteRefPrefix}
                    ref={quotePrefixRef}
                    type="text"
                  />
                  <label htmlFor="">Incrément</label>
                  <input
                    defaultValue={society?.quoteRefInc}
                    ref={quoteIncrementRef}
                    type="text"
                  />
                </div>
              </div>
              <div className="setInpGroupLine4">
                <label htmlFor="">Avoir : Préfixe</label>
                <div>
                  <input
                    defaultValue={society?.depositRefPrefix}
                    ref={depositPrefixRef}
                    type="text"
                  />
                  <label htmlFor="">Incrément</label>
                  <input
                    defaultValue={society?.depositRefInc}
                    ref={depositIncrementRef}
                    type="text"
                  />
                </div>
              </div>
              <div className="setInpGroupLine4">
                <label htmlFor="">Bon de livraison : Préfixe</label>
                <div>
                  <input
                    defaultValue={society?.deliveryRefPrefix}
                    ref={deliveryPrefixNote}
                    type="text"
                  />
                  <label htmlFor="">Incrément</label>
                  <input
                    defaultValue={society?.deliveryRefInc}
                    ref={deliveryIncrementNote}
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="setRightInpGroup">
              <div className="setInpGroup textAreaGroup">
                <div className="textAreaTilteContent">

                  <label htmlFor="">Mentions particulières Devis</label>
                  <FontAwesomeIcon className="textAreaIcon"
                    title="Figurant en pied de page de vos
                  devis."
                    icon={faCircleInfo}
                  />
                </div>
                <textarea
                  defaultValue={society?.quoteFooter}
                  ref={quoteMentionRef}
                  className="settingsTextarea"
                  name=""
                  id=""
                ></textarea>
              </div>
              <div className="setInpGroup textAreaGroup">
                <div className="textAreaTilteContent">

                  <label htmlFor="">Mentions particulières Factures</label>
                  <FontAwesomeIcon className="textAreaIcon"
                    title="Figurant en pied de page de vos
                  factures."
                    icon={faCircleInfo}
                  />
                </div>
                <textarea
                  defaultValue={society?.invoiceFooter}
                  ref={invoiceMentionRef}
                  className="settingsTextarea"
                  name=""
                  id=""
                ></textarea>
              </div>
              <div className="setInpGroup textAreaGroup">
                <div className="textAreaTilteContent">

                  <label htmlFor="">Mentions particulières Commandes</label>
                  <FontAwesomeIcon className="textAreaIcon"
                    title="Figurant en pied de page de vos
                  commandes."
                    icon={faCircleInfo}
                  />
                </div>
                <textarea
                  defaultValue={society?.orderFooter}
                  ref={orderMentionRef}
                  className="settingsTextarea"
                  name=""
                  id=""
                ></textarea>
              </div>
              <div className="setInpGroup textAreaGroup">
                <div className="textAreaTilteContent">

                  <label htmlFor="">Informations</label>
                  <FontAwesomeIcon className="textAreaIcon"
                    title="Apparaitra en fin de document"
                    icon={faCircleInfo}
                  />
                </div>
                <textarea
                  defaultValue={society?.comment}
                  ref={informationRef}
                  className="settingsTextarea"
                  name=""
                  id=""
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
