import { useEffect, useState } from "react";
import "../../../styles/style.css";
function CataloguesFilter(props) {
  // const [showFilter, setShowFilter] = useState();
  // console.log("props.showFilter", props.showFilter);
  const showHideClassName = props?.showFilter ? "" : "hidden-filter";
  const [listCatalogs, setListCatalogs] = useState(props.listCatalogs);
  const [listFamilies, setListFamilies] = useState(props.listFamilies);
  const [listSubFamilies, setListSubFamilies] = useState(props.listSubFamilies);
  const [listBrands, setListBrands] = useState(props.listBrands);
  const [listSuppliers, setListSuppliers] = useState(props.listSuppliers);
  const [onValidFilter, setOnValidFilter] = useState(props?.onValidFilter);
  const [changeValueFilter, setChangeValueFilter] = useState(props?.changeValueFilter);
  // const [setHandleChange] = useState(props.handleChange);
  useEffect(() => {
    setListCatalogs(props.listCatalogs);
    setListFamilies(props.listFamilies);
    setListSubFamilies(props.listSubFamilies);
    setListBrands(props.listBrands);
    setListSuppliers(props.listSuppliers);
    setOnValidFilter(props.onValidFilter);
    setChangeValueFilter(props.changeValueFilter);
    // setHandleChange(props.handleChange);
  }, [props.listCatalogs, props.listFamilies, props.listSubFamilies, props.listBrands, props.listSuppliers, props.onValidFilter, props.changeValueFilter]);

  return (
    <div className={`card-filter ${showHideClassName}`}>
      <form onSubmit={onValidFilter}>
        <div className="row-one">
          <select name="catalog" onChange={changeValueFilter}>
            <option value="">Catalogues</option>
            {listCatalogs?.map((catalog, k) => {
              return (
                <option key={catalog.name} value={catalog.id}>
                  {catalog.name}
                </option>
              );
            })}
          </select>
          <select name="family" onChange={changeValueFilter}>
            <option value="">Famille</option>
            {listFamilies?.map((family, k) => {
              return <option key={family.family}>{family.family}</option>;
            })}
          </select>
          <select name="subFamily" onChange={changeValueFilter}>
            <option value="">Sous-famille</option>
            {listSubFamilies?.map((subfamily, k) => {
              return (
                <option key={subfamily.subfamily}>{subfamily.subfamily}</option>
              );
            })}
          </select>
          <select name="brand" onChange={changeValueFilter}>
            <option value="">Marque</option>
            {listBrands?.map((brand, k) => {
              return <option key={brand.brand}>{brand.brand}</option>;
            })}
          </select>
          <select name="supplier" onChange={changeValueFilter}>
            <option value="">Fournisseur</option>
            {listSuppliers?.map((supplier, k) => {
              return (
                <option key={supplier.supplier}>{supplier.supplier}</option>
              );
            })}
          </select>
          <input
            type="text"
            placeholder="Ref. Fabricant"
            name="refFab"
            title="Ref. Fabricant"
            onBlur={changeValueFilter}
          ></input>
          <input
            type="text"
            placeholder="Ref. Distributeur"
            name="refDis"
            title="Ref. Distributeur"
            onBlur={changeValueFilter}
          ></input>
          <input
            type="text"
            placeholder="Désignation"
            name="designation"
            title="Désignation"
            onBlur={changeValueFilter}
          ></input>
          <input
            className="search"
            id="filter-submit"
            type="submit"
            value="Rechercher"
          ></input>
        </div>
      </form>
    </div>
  );
}

export default CataloguesFilter;
