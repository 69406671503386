export default function ItemPop({ selectedArticle }) {

    return (
        <>
                        <div className="articleContent">
                            <h2 className="name">{selectedArticle.name}</h2>
                            <div className="content">
                                <div className="category">
                                    <h3 className="h3">Catégories</h3>
                                    <p><span className="span">Famille : </span>{selectedArticle.family}</p>
                                    <p><span className="span">Sous famille : </span>{selectedArticle.subfamily}</p>
                                    <p><span className="span">Catalogue : </span>{selectedArticle.catalog}</p>
                                    <p><span className="span">Fournisseur :</span>{selectedArticle.supplier}</p>
                                    <p><span className="span">Marque : </span>{selectedArticle.brand}</p>
                                </div>
                                <div className="values">
                                    <h3 className="h3">Valeurs</h3>
                                    <p><span className="span">Prix d'achat : </span>{selectedArticle.netPrice}</p>
                                    <p><span className="span">Prix de vente : </span>{selectedArticle.basePrice}</p>
                                    <p><span className="span">Unitée : </span>{selectedArticle.auPrice}</p>
                                    <p><span className="span">Conditionnement : </span>{selectedArticle.package}</p>
                                </div>
                                <div className="ref">
                                    <h3 className="h3">Références</h3>
                                    <p><span className="span">Ref distributeur : </span>{selectedArticle.refDistributor}</p>
                                    <p><span className="span">Ref fabriquant : </span>{selectedArticle.refMaker}</p>
                                </div>
                            </div>
                        </div>
        </>
    )
}