import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/layouts/layouts";
import { contactPaths } from "./paths/contactPaths";
import { quotesPath } from "./paths/quotePaths";
import { invoicePaths } from "./paths/invoicePaths";
import { marginPaths } from "./paths/marginPaths";
import { orderPaths } from "./paths/orderPaths";
import { creditPaths } from "./paths/creditPaths";
import { depositPaths } from "./paths/depositPaths";
import { catalogPaths } from "./paths/catalogPaths";
import { settingsPaths } from "./paths/settings";
import Authentification from "./routes/Authentification";
function App() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Authentification />,
    },
    {
      path: "/",
      element: <Layout />,
      children: invoicePaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: quotesPath,
    },
    {
      path: "/",
      element: <Layout />,
      children: contactPaths,
    },
    {
      path: "/",
      element: <Layout />,
      children: marginPaths,
    },
    {
      path:"/",
      element: <Layout />,
      children: orderPaths
    },
    {
      path:"/",
      element: <Layout />,
      children: creditPaths
    },
    {
      path:"/",
      element: <Layout />,
      children: depositPaths
    },
    {
      path:"/",
      element: <Layout />,
      children: catalogPaths
    },
    {
      path:"/",
      element: <Layout />,
      children: settingsPaths
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
