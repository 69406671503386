import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faBarcode, faTag, faSignature, faCopyright, faTruckField } from "@fortawesome/free-solid-svg-icons";

function ArticleCard({
  article,
  handleItemClick,
}) {

  //console.log("article", article);

  return (
    <div
      onClick={() => {
        handleItemClick(article);
      }}
      className="articleCard"
      key={article.id}
    >

      <p className="content"><FontAwesomeIcon icon={faSignature} />{article.name}</p>
      {
        article.family &&
        <div className="contentGroup">
          <FontAwesomeIcon icon={faTag} />
          <p>{article.family}{article.subfamily && ` - ${article.subfamily}`}</p>
        </div>
      }

      <p className="content"><FontAwesomeIcon icon={faCopyright} />{article.brand}</p>

      <p className="content"><FontAwesomeIcon icon={faTruckField} />{article.supplier}</p>
      {
        (article.refDistributor || article.refMaker) &&
        <div className="contentGroup">
          <FontAwesomeIcon icon={faAsterisk} />
          <div>
            <p>{article?.refMaker}{(article.refDistributor && article.refMaker) && " - "}{article?.refDistributor}</p>
          </div>
        </div>
      }
      <div className="contentGroup">
        <FontAwesomeIcon icon={faBarcode} />
        <p>{article.netPrice}€ - {article.basePrice}€</p>
      </div>
    </div>
  );
}

export default ArticleCard;
