import PriceRecap from "./priceReacp";
import LinkedDepositList from "../../../deposit/LinkedDepositList";

export default function RecapContainer({linkDeposit, handleSubmitInvoice,invoiceId, totalTtc, totalHt ,discount, handleDiscountChange, totalInvoice, vatList, deleteDeposit, createDeposit}) {
    const onDepositLinkClick = (e, path) => {
        handleSubmitInvoice(e, path)
    }
    return (
        <section className="reacpContainer">
            <PriceRecap totalTtc={totalTtc} totalHt={totalHt} discount={discount} handleDiscountChange={handleDiscountChange} totalInvoice={totalInvoice} vatList={vatList} />
            <LinkedDepositList linkDeposit={linkDeposit} onSubmit={onDepositLinkClick} deleteDeposit={deleteDeposit} createDeposit={createDeposit} parentPath={`/invoicesList/invoice/${invoiceId}`} />
        </section>
    )
}