export const generateId = (groupLines) => {
    let prefixIdLine = "New";
    let newIdLig = 0;


    if (!groupLines) return prefixIdLine + newIdLig;
    Array.from(groupLines).forEach((line) => {
        while (line.id === prefixIdLine + newIdLig) {
            newIdLig ++;
        }
    });
    return prefixIdLine + newIdLig;
}
