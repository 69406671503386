
function DeliveryInfos({
    invoiceRef,
    interlocutors,
    subject,
    setSubject,
    societyContact
}) {

    return (
        <>
            <div className="marginInfos infos">
                <h3>ÉDITION D'UN BON DE LIVRAISON</h3>
                <div className="marginInfos__infos-block infos-block">
                    <div className="marginInfos__infos-block_invoice invoice">
                        <h4>Référence facture :</h4>
                        <div className="marginInfos__infos-block_invoice_invoiceSearch invoiceSearch">
                            <input
                                autoComplete="off"
                                type="text"
                                name="invoiceRef"
                                defaultValue={invoiceRef}
                                disabled="disabled"
                            />
                        </div>
                    </div>
                    <div className="marginInfos__infos-block_interlocutor">
                        <div>
                            <h4>Affaire suivie par</h4>
                        </div>
                        <div>
                            {interlocutors && interlocutors.length > 0 && (
                                <>
                                    {interlocutors[0].last_name} {interlocutors[0].first_name}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="marginInfos__subject subject">
                        <h4>Intitulé du bon de livraison</h4>
                        <input name="subject" placeholder="EX : RENOVATION ISOLATION" value={subject ? subject : ""} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <div className="client">
                        <p><span>Facturation :</span></p>
                        {societyContact?.society
                            || `${societyContact?.lastName || ""} ${societyContact?.firstName || ""}`.trim()
                            || "Nom de société"}
                        <p>{societyContact?.adress1 || societyContact?.adress2 || "adresse"}</p>
                        <p>{societyContact?.postal || "code postal"} {societyContact?.city || "Ville"}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeliveryInfos;
