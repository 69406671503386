import Margin from "../routes/margin/Margin"
import MarginsList from "../routes/margin/MarginsList"
import PrivateRouter from "../privateRouter";
const roles = ["1", "2", "3"];

export const marginPaths = [
    {
        path: "/marginsList",
        element: <PrivateRouter roleList={roles}><MarginsList /></PrivateRouter>,
    },
    {
        path: "/marginsList/margin/:id",
        element: <PrivateRouter roleList={roles}><Margin /></PrivateRouter>,
    },
    {
        path: "/marginsList/marginDup/:id",
        element: <PrivateRouter roleList={roles}><Margin /></PrivateRouter>,
    },
    {
        path: "/marginsList/margin",
        element: <PrivateRouter roleList={roles}><Margin /></PrivateRouter>,
    }
]