import {useEffect, useState} from "react";

export default function MarginCreator({ margin, object, updateMargin, onValidObject, parent}) {
    const [totalMargin, setTotalMargin] = useState(0.00);
    const [percentMargin, setPercentMargin] = useState(0.00);

    useEffect(() => {
        let totalHT = margin?.totalHT ?? object?.totalHt ?? 0;
        let newTotalMargin = totalHT - ((margin?.estimatedHours ?? 0) * parseFloat(margin?.averageHourlyRateHT ?? 0) + parseFloat(margin?.purchaseCostsHT ?? 0) + parseFloat(margin?.fixedCosts ?? 0) + parseFloat(margin?.variableCosts ?? 0));
        if (!isNaN(newTotalMargin)) {
            setTotalMargin(newTotalMargin);
        }

        let newPercentMargin = newTotalMargin / totalHT * 100;
        if (!isNaN(newPercentMargin)) {
            setPercentMargin(newPercentMargin);
        }
    }, [margin, object?.totalHt]);

    const MarginBtnClick = (e) => {
        let parentPath;
        if (parent === "quote") {
            parentPath = `/quotesList/quote/${margin?.quoteId ?? object?.id}`;
        } else if (parent === "invoice") {
            parentPath = `/invoicesList/invoice/${margin?.invoiceId ?? object?.id}`;
        } else {
            parentPath = parent === "quote" ? "/quotesList" : "/invoicesList";
        }
        localStorage.setItem('parentPath', parentPath);

        let path = '/marginsList/margin/';
        onValidObject(e, path);
    }

    const onLineBlur = (e) => {
        e.target.value = e.target.value === "" ? e.target.value = 0 : e.target.value;
    }

    return (
        <div className="margin-creator">
            <h3>% CALCUL DE LA MARGE</h3>
            <p>Les champs ci-dessous n'apparaissent pas sur le document envoyé au client mais
                peuvent être renseignés pour le calcul de la marge brute.
            </p>
            <div className="margin-creator__amounts margin-amounts">
                <div>
                    <p>Heures prévues totales sur le devis</p>
                    <input type="number" name="estimatedHours" onBlur={onLineBlur} value={margin?.estimatedHours ?? 0} onChange={(e) => updateMargin(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()}/>
                </div>
                <div>
                    <p>Coût horaire moyen HT sur le devis</p>
                    <input type="number" step="0.01" name="averageHourlyRateHT" onBlur={onLineBlur} value={margin?.averageHourlyRateHT ?? 0} onChange={(e) => updateMargin(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()}/>
                </div>
                <div>
                    <p>Coûts d'achats estimés</p>
                    <input type="number" step="0.01" name="purchaseCostsHT" onBlur={onLineBlur} value={margin?.purchaseCostsHT ?? 0} onChange={(e) => updateMargin(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()}/>
                </div>
                <div>
                    <p>Frais fixes estimés</p>
                    <input type="number" step="0.01" name="fixedCosts" onBlur={onLineBlur} value={margin?.fixedCosts ?? 0} onChange={(e) => updateMargin(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()}/>
                </div>
                <div>
                    <p>Frais variables estimés</p>
                    <input type="number" step="0.01" name="variableCosts" onBlur={onLineBlur} value={margin?.variableCosts ?? 0} onChange={(e) => updateMargin(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()}/>
                </div>
            </div>
            <div className="margin-creator__footer footer-margin">
                <div className="margin-creator__footer_data">
                    <div>
                        <p>Total marge brute théorique</p>
                        <p className="margin-creator__footer_data_number">{totalMargin.toFixed(2)} €</p>
                    </div>
                    <div className="v-line"></div>
                    <div>
                        <p>% marge brute théorique</p>
                        <p className="margin-creator__footer_data_number">{percentMargin.toFixed(2)} %</p>
                    </div>
                </div>
                <div className="margin-creator__footer_button">
                    <button onClick={MarginBtnClick}>Accéder à la marge</button>
                </div>
            </div>
        </div>
    );
}