import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../context/appContext';
import {faPenToSquare, faPrint} from "@fortawesome/free-solid-svg-icons";
import {GetApiContext} from "../../../context/apiCalls/getApiCalls";

export default function ContactInvoiceList() {
    const appContext = useContext(AppContext);
    const getApiCalls = useContext(GetApiContext);
    const [invoice, setInvoice] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {


        const limit = 5;

        getApiCalls.contactQuoteListGet(id, limit)
        //axios.get(`${process.env.REACT_APP_CONTACT_QUOTE_LIST_GET}${id}&limit=${limit}`)
            .then(response => {
                //console.log(response)
                setInvoice(response.listInvoices);
            });

    }, [getApiCalls, id])


    const showInvoiceListPop = () => {
        window.monEcouteurScroll = function (e) {
            e.preventDefault();
            window.scrollTo(0, 0);
        };
        //window.addEventListener('scroll', window.monEcouteurScroll);

        appContext.setBlur(true);
        appContext.setShowInvoicePopup(!appContext.showInvoicePopup)
    }

    const navigateTo = (e) => {
        navigate(`/invoicesList/invoice/${e}`)
    }

    return (

        <>
            <div className='table'>
                <div className='titleLine'>
                    <h3>Historique des factures en cours</h3>
                </div>
                <div className='borderForm'>
                    <table >
                        <thead>
                            <tr>
                                <th className='th1'>Ref</th>
                                <th className='th2'>Intitulé de la facture</th>
                                <th className='th3'>Date de création</th>
                                <th className='th4'>Montant HT</th>
                                {invoice &&
                                    invoice.length > 0 &&
                                    <th className='th5'>Consulter</th>
                                }
                            </tr>
                        </thead>
                        <tbody className='tbody'>
                            {
                                invoice && invoice.length > 0 ? (
                                    invoice.map((element, index) => {
                                        return (
                                            <tr className='tr' key={index} >
                                                <td className='td1'>{element?.ref ? element?.ref : "vide"}</td>
                                                <td className='td2'>{element?.subject ? element?.subject : "vide"}</td>
                                                <td className='td3'>{element?.invoiceDate ? element?.invoiceDate : "vide"}</td>
                                                <td className='td4'>{element?.totalHt ? element?.totalHt : "vide"}</td>
                                                {
                                                    (element.valid !== 0) ? (
                                                        <td className='tdEdit'><FontAwesomeIcon onClick={() => { navigateTo(element.id) }} className='editIcon' icon={faPenToSquare} /></td>
                                                    ) : (
                                                        <td className='tdPrint'><FontAwesomeIcon icon={faPrint} className='editIcon' /></td>
                                                    )
                                                }
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className='tr'>
                                        <td className='td1'>vide</td>
                                        <td className='td2'>vide</td>
                                        <td className='td3'>vide</td>
                                        <td className='td4'>vide</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className='seeMore'>
                    <p className='seeMoreButton' onClick={showInvoiceListPop}>Voir plus</p>
                </div>
            </div >

        </>

    )

}