import { useEffect, useMemo, useState } from "react";

function DeliveryLine(props) {

    const [designation, setDesignation] = useState(props?.line.name);
    const [quantity, setQuantity] = useState(props?.quantity);
    const [unitPrice, setUnitPrice] = useState(props?.unitPrice);
    const [tva, setTva] = useState(0);
    const [totalHt, setTotalHt] = useState(props?.totalHt || 0)
    const [deliveredQuantity, setDeliveredQuantity] = useState(props?.line.deliveredQuantity)
    const unit = useMemo(() => {return props.listUnit.find((unit) => unit.id === props.line.unitId)},[props]);

    useEffect(() => {
        setDesignation(props?.line.name);
        setQuantity(props?.line.invoicedQuantity);
        setDeliveredQuantity(props?.line.deliveredQuantity)
        setUnitPrice(props?.line.unitPrice);
        setTotalHt(props?.totalHt || 0);
        let foundObject = props.listTva.find(item => item.id === props.line.vatId);
        setTva(foundObject ? foundObject.rate : null);
    }, [props])

    const changeDesignation = (e) => {
        setDesignation(e.target.value);
        props.onDesignationChange(props.line.id, e.target.value);
    };

    const changeDeliveredQuantity = (e) => {
        setDeliveredQuantity(e.target.value);
        props.onDeliveredQuantityChange(props.line.id, e.target.value);
    }

    return (
        <div className="group__line">
            <div className="group__line_left-component">
                <div className="group__line_left-component_options">
                        <p className="group__line_left-component_options_num">{props?.num}</p>
                    <textarea placeholder="Désignation" title="Désignation" name="designation" value={designation || ""} onChange={changeDesignation} ></textarea>
                </div>
            </div>
            <div className="group__line_right-component">
                <div className="group__line_right-component_data">
                    <div className="group__line_right-component_data_unitSelect">
                        <p>Unité</p>
                        <input type="text" placeholder="Unité de mesure" name="unit" title="Unité de mesure" value={unit?.unit_name} disabled="disabled" />
                    </div>
                    <div className="group__line_right-component_data_unit">
                        <p>Quantité commandée</p>
                        <input type="number" placeholder="Quantité commandée" name="quantity" title="Quantité commandée" value={quantity || ""} disabled="disabled" />
                    </div>
                    <div className="group__line_right-component_data_unit-enable">
                        <p>Quantité livrée</p>
                        <input type="number" placeholder="Quantité livrée" name="quantity" title="Quantité livrée" value={deliveredQuantity || ""} onChange={changeDeliveredQuantity}/>
                    </div>
                    <div className="group__line_right-component_data_unit">
                        <p>Prix&nbsp;HT</p>
                        <input type="number" placeholder="Prix HT" name="unitPrice" title="Prix HT" value={unitPrice || ""} disabled="disabled" />
                    </div>
                    <div className="group__line_right-component_data_unit">
                        <p>TVA %</p>
                        <input type="number" placeholder="Taux TVA" name="unitPrice" title="Taux TVA" value={tva} disabled="disabled" />
                    </div>
                </div>
                <div className="group__line_right-component_data_line-amount">
                    <p className="group__line_right-component_data_line-amount_ht">MONTANT HT : <span>{totalHt.toFixed(2)} €</span></p>
                </div>

            </div>
        </div>
    );
}

export default DeliveryLine;