import Deposit from "../routes/deposit/deposit";
import DepositsList from "../routes/deposit/DepositsList";
import PrivateRouter from "../privateRouter";

const roles = ["1", "2", "3", "4", "5", "6"];

export const depositPaths = [
    {
        path: "/depositsList",
        element: <PrivateRouter roleList={roles}><DepositsList /></PrivateRouter>,
    },
    {
        path: "/depositsList/deposit/:id",
        element: <PrivateRouter roleList={roles}><Deposit /></PrivateRouter>,
    },
    {
        path: "/depositsList/deposit",
        element: <PrivateRouter roleList={roles}><Deposit /></PrivateRouter>,
    }
]