export default function MarginPrice(data) {
    return (
        <aside className="marginPrice">
            <div className="marginPrice__bold-block bold-block">
                <p>Coût entreprise HT attendu</p>
                <p>{data.data.expectedCost} €</p>
            </div>
            <div className="marginPrice__bold-block bold-block">
                <p>Coût entreprise HT réel</p>
                <p>{data.data.realCost} €</p>
            </div>
            <div className="marginPrice__tva">
                <div>
                    <p>MO prévue HT</p>
                    <p>{data.data.plannedManpower?.toFixed(2)} €</p>
                </div>
                <div>
                    <p>MO réelle HT</p>
                    <p>{data.data.realManpower?.toFixed(2)} €</p>
                </div>
                <div>
                    <p>Coût total HT (Hors MO)</p>
                    <p>{data.data.totalCostHT?.toFixed(2)} €</p>
                </div>
            </div>
            <div className="marginPrice__bold-block bold-block">
                <p>Montant total HT facture</p>
                <p>{data.data.totalHT} €</p>
            </div>
            <div className="marginPrice__bold-block bold-block">
                <p>TOTAL MARGE BRUTE</p>
                <p>{data.data.realTotalMargin?.toFixed(2)} €</p>
            </div>
            <div className="marginPrice__bold-block bold-block">
                <p>% Marge réelle</p>
                <p>{data.data.realPercentMargin?.toFixed(2)} %</p>
            </div>
        </aside>
    );
}