import { useEffect, useState, useRef } from "react";
import "../../../styles/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faPlus } from "@fortawesome/free-solid-svg-icons";

function CreditLine(props) {
    const [designation, setDesignation] = useState(props?.designation);
    const [quantity, setQuantity] = useState(props?.quantity);
    const [unitPrice, setUnitPrice] = useState(parseFloat(props?.unitPrice).toFixed(2));
    const [totalHT, setTotalHT] = useState((props.line.unitPrice * props.line.quantity) || 0);

    const firstRender = useRef(true);

    // Fonction pour mettre à jour la TVA
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeAllTva = (allTva) => {
        let newTva = parseInt(allTva);
        var newLine = { ...props.line, vatId: newTva };
        props.updateGroup(newLine);
    };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        // Ne pas réinitialiser si les valeurs ont été modifiées localement
        if (
            props?.designation !== designation &&
            props?.designation !== undefined
        ) {
            setDesignation(props?.designation);
        }

        if (
            props?.quantity !== quantity &&
            props?.quantity !== undefined
        ) {
            setQuantity(props?.quantity);
        }

        if (
            props?.unitPrice !== unitPrice &&
            props?.unitPrice !== undefined
        ) {
            setUnitPrice(parseFloat(props?.unitPrice).toFixed(2));
        }

        const newTotalHT = (parseFloat(unitPrice) * parseFloat(quantity)).toFixed(2);
        if (newTotalHT !== totalHT) {
            setTotalHT(newTotalHT);
            let newLine = { ...props.line, totalHT: newTotalHT };
            props.updateGroup(newLine);
        }

        if (props.allTva !== undefined) {
            changeAllTva(props.allTva);
        }
    }, [changeAllTva, designation, props, props.allTva, props?.designation, props?.quantity, props?.unitPrice, quantity, totalHT, unitPrice]);

    const onChange = (e) => {
        let value = e.target.value;
        //const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        switch (e.target.name) {
            case 'quantity':
                setQuantity(value);
                break;
            case 'unitPrice':
                setUnitPrice(value);
                break;
            case 'tva':
                let newTva = parseInt(e.target.value);
                let newLineTva = { ...props.line, vatId: newTva };
                props.updateGroup(newLineTva);
                break;
            case 'unit':
                let newUnit = parseInt(e.target.value);
                let newLineUnit = { ...props.line, unitId: newUnit };
                props.updateGroup(newLineUnit);
                break;
            default:
                break;
        }
    };

    const formatInput = (e) => {
    let value = e.target.value.replace(',', '.');
    let newLine = { ...props.line };
        console.log(newLine);

    switch (e.target.name) {
        case 'quantity':
            let newQuantity = parseFloat(value).toFixed(2);
            if (isNaN(newQuantity)) newQuantity = 0.00;
            setQuantity(newQuantity);
            newLine.quantity = newQuantity;
            break;
        case 'unitPrice':
            let newUnitPrice = parseFloat(value).toFixed(2);
            if (isNaN(newUnitPrice)) newUnitPrice = 0.00;
            setUnitPrice(newUnitPrice);
            newLine.unitPrice = newUnitPrice;
            break;
        case 'designation':
            newLine.name = value;
            break;
        default:
            break;
    }

    let newTotalHT = (parseFloat(quantity || 0) * parseFloat(unitPrice || 0.00)).toFixed(2);
    setTotalHT(newTotalHT);
    newLine.totalHT = newTotalHT;
        console.log("newline",newLine);
    props.updateGroup(newLine);
};

    return (
        <div className="line">
            <input type="hidden" name={"idLine" + props.grpId + "_" + props.id} />
            <div className="line-component2" style={{ display: "flex", flexDirection: "column" }}>
                <div className="options">
                    <div className="buttons">
                        <p className="num">{props?.numero}</p>
                        <a className="cart" href="#!" onClick={() => { props.openCatalogues(props.index, props.grpIndex) }}>
                            <img src={require("../../../assets/images/icons/Picto/Achat.svg").default} alt="" />
                            <p style={{ marginLeft: "3px" }}>Ajouter un article</p>
                        </a>
                        <a className="garbage" href="#!" onClick={() => { props.removeItem(props.index) }}>
                            <FontAwesomeIcon icon={faPlus} />
                        </a>
                    </div>
                    <div className="tri">
                        <a className="up" href="#!" onClick={() => { props.changeOrderLine(props.index, "up") }}>
                            <FontAwesomeIcon icon={faAngleUp} />
                        </a>
                        <a className="down" href="#!" onClick={() => { props.changeOrderLine(props.index, "down") }}>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </a>
                    </div>
                </div>
                <textarea
                    placeholder="Désignation"
                    title="Désignation"
                    name="designation"
                    value={designation || ""}
                    onChange={(e) => setDesignation(e.target.value)}
                    onBlur={formatInput}
                />
            </div>
            <div className="line-component1">
                <div className="unit">
                    <div>
                        <p>Unité</p>
                        <select name="unit" value={props.unit || ""} onChange={onChange} onBlur={formatInput}>
                            {props.listUnit.map((unit, i) => (
                                <option key={i} value={unit.id}>{unit.unit_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="unit">
                        <p>Quantité</p>
                        <input type="number" placeholder="Quantité" name="quantity" title="Quantité" value={quantity} onChange={onChange} onBlur={formatInput} />
                    </div>
                    <div className="unit">
                        <p>Prix&nbsp;HT</p>
                        <input type="number" placeholder="Prix HT" name="unitPrice" title="Prix HT" value={unitPrice} onChange={onChange} onBlur={formatInput} />
                    </div>
                    <div className="unit">
                        <p>TVA</p>
                        <select name="tva" value={props.tva || ""} onChange={onChange}>
                            {props.listTva.map((tva, i) => (
                                <option key={i} value={tva.id}>{tva.rate} %</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="options">
                    <div className="line-amount">
                        <p className="ht">MONTANT HT : <span>{totalHT} €</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreditLine;
