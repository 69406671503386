import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/appContext';
import {faPenToSquare, faPrint} from "@fortawesome/free-solid-svg-icons";
import {GetApiContext} from "../../../context/apiCalls/getApiCalls";


export default function InvoicePopList() {

    const appContext = useContext(AppContext);
    const getApiCalls = useContext(GetApiContext);
    const [invoice, setInvoice] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        const limit = 10;

        getApiCalls.contactInvoiceListGet(appContext.idContact, limit)
        //axios.get(`${process.env.REACT_APP_CONTACT_QUOTE_LIST_GET}${appContext.idContact}&limit=${limit}`)
            .then(response => {
                setInvoice(response.listInvoices);
                // appContext.setProspectValue(response.data.listQuotes);
                appContext.setReload(false)
            });

    }, [appContext.reload, appContext, getApiCalls]);

    const closePop = () => {
        appContext.setBlur(false);
        appContext.setShowInvoicePopup(false);
        window.removeEventListener('scroll', window.monEcouteurScroll);

    }


    const navigateTo = (e) => {
        navigate(`/invoicesList/invoice/${e}`)
    }


    return (

        <div className='tablePop'>
            <h3 className='titlePop'>Liste des factures</h3>
            <div className='borderForm'>
                <table className='tableForm'>
                    <thead>
                        <tr>
                            <th className='thForm th1'>Appel</th>
                            <th className='thForm th2'>Rendez-vous</th>
                            <th className='thForm th3'>Relance</th>
                            <th className='thForm th4'>Commentaire</th>
                            <th className='thForm th5'>Consulter</th>
                        </tr>
                    </thead>
                    <tbody className='tbody'>
                        {invoice &&
                            invoice?.map((element, index) => {
                                return (
                                    <tr className='tr' key={index} >
                                        <td className='tdForm td1'>{element?.ref ? element?.ref : "vide"}</td>
                                        <td className='tdForm td2'>{element?.subject ? element?.subject : "vide"}</td>
                                        <td className='tdForm td3'>{element?.invoiceDate ? element?.invoiceDate : "vide"}</td>
                                        <td className='tdForm td4'>{element?.totalHt ? element?.totalHt : "vide"}</td>
                                        {
                                            (element.valid !== 0) ? (
                                                <td className='tdEdit tdForm'><FontAwesomeIcon onClick={() => { navigateTo(element.id) }} className='editIcon' icon={faPenToSquare} /></td>
                                            ) : (
                                                <td className='tdEdit tdForm'><FontAwesomeIcon icon={faPrint} className='editIconPop' /></td>
                                            )
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <p className='pop-up-cancel' onClick={closePop}>fermer</p>

        </div>
    )

}