import ContactsListPop from '../contacts/ContactsListPop';

function MarginInfos({
                         invoiceRef,
                         handleInputChange,
                         invoiceSuggestions,
                         handleSuggestionClick,
                         interlocutors,
                         contact,
                         margin,
                         setInvoiceRef,
                         setInvoiceSuggestions,
                         contactListPopData,
                         handleInterChange,
                         interlocutorSelected
                     }) {

    return (
        <>
            <ContactsListPop
                show={contactListPopData.show.showModal}
                setShowModal={contactListPopData.setShowModal.setShowModal}
                data={contactListPopData.data.contacts}
                handleSelectedContact={contactListPopData.handleSelectedContact.handleSelectedContact}
            />
            <div className="marginInfos infos">
                <h3>ÉDITION D'UNE MARGE</h3>
                <div className="marginInfos__infos-block infos-block">
                    <div className="marginInfos__infos-block_invoice invoice">
                        <h4>Référence facture :</h4>
                        <div className="marginInfos__infos-block_invoice_invoiceSearch invoiceSearch">
                            <input
                                autoComplete="off"
                                type="text"
                                name="invoiceRef"
                                value={invoiceRef}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    e.target.value === "" ? setInvoiceRef("") : setInvoiceRef(e.target.value);
                                    setTimeout(() => setInvoiceSuggestions([]), 100); // Pour éviter de cacher les suggestions avant le clic
                                }}
                            />
                            {invoiceSuggestions.length > 0 && (
                                <ul className="marginInfos__infos-block_invoice_invoiceSearch_suggestions-list">
                                    {invoiceSuggestions.map((invoice) => (
                                        <li key={invoice.invoiceId}
                                            onClick={() => handleSuggestionClick(invoice.invoiceRef)}>
                                            {invoice.invoiceRef}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="marginInfos__infos-block_interlocutor">
                        <div>
                            <h4>Affaire suivie par</h4>
                        </div>
                        <select value={interlocutorSelected} onChange={handleInterChange}>
                            {interlocutors?.map((interlocutor, index) => (
                                <option value={interlocutor.id} key={index}>
                                    {interlocutor.last_name} {interlocutor.first_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="marginInfos__infos-block_contact">
                        <div>
                            <h4>Client</h4>
                        </div>
                        <p>{contact?.lastName} {contact?.firstName} {contact?.society ? "-" : ""} {contact?.society}</p>
                    </div>
                    <div className="marginInfos__infos-block_contact-search">
                        <a href="#!" onClick={contactListPopData.handleClickModal.handleClickModal}>Rechercher un
                            client</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MarginInfos;
