import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function MainButton({
    buttonIcon,
    buttonText,
    buttonAction,
    className,
}) {

    return (
        <>
            <button
                onClick={buttonAction}
                className={`toolbar__container_button button ${className}`}>
                {buttonText}
                { buttonIcon && <FontAwesomeIcon style={buttonIcon && {marginLeft: "1rem"}} className='toolbar__container_button_icon' icon={buttonIcon} />}
            </button>
        </>
    );
}