import {useEffect, useState, useRef, useContext} from "react";
import { useNavigate } from "react-router-dom";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";

import Pagination from '../../components/addOns/Pagination';
import MainHeader from '../../components/addOns/headers/MainHeader';
import SearchComponent from '../../components/addOns/filterComponent/searchComponent';
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import Loader from "../../components/addOns/loader";
import Card from "../../components/addOns/card";
import {useAuthState} from "../../context/authContext";
import roleVerify from "../../utils/roleVerify";
function MarginsList() {

    let navigate = useNavigate();
    const postApiContext = useContext(PostApiContext);
    const {roleId} = useAuthState();
    const roleList = ["1", "2", "3", "4", "5", "6"];
    const roleVerif = roleVerify(roleList, roleId)

    const [margins, setMargins] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [nbPages, setNbPages] = useState();
    const [sort, setSort] = useState('refdown')
    const [reload, setReload] = useState();
    const [loading, setLoading] = useState(true);


    //const for research component
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const societyRef = useRef();
    const invoiceFilterRef = useRef();
    const marginRef = useRef();

    /**
     * content for research component
     */
    const contentObject = {
        ContentName: "MARGE",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "society", title: "Société", type: "text", ref: societyRef },
            { name: "refFacture", title: "Référence facture", type: "text", ref: invoiceFilterRef },
            { name: "refMargin", title: "Référence marge", type: "text", ref: marginRef }
        ]
    }

    /**
     * options for SortByFilter
     */
    const sortOptions = {
        options: [
            { value: "refup", content: "Références croissantes" },
            { value: "refdown", content: "Références décroissantes" },
            { value: "dateup", content: "Date de création croissante" },
            { value: "datedown", content: "Date de création décroissante" },
        ]
    }
    useEffect(() => {

        setMargins(null);

        const values = {
            firstName: firstNameRef.current?.value,
            lastName: lastNameRef.current?.value,
            society: societyRef.current?.value,
            refInvoice: invoiceFilterRef.current?.value,
            refMargin: marginRef.current?.value,
            sort: sort,
        }

        postApiContext.postMarginsList(values)
        //axios.post(`${process.env.REACT_APP_MARGIN_FILTER_LIST}`, values, customHeaders)
            .then(response => {
                console.log("response :", response);
                setMargins(response.marginsList);
                setNbPages(response.nbPages);
                setLoading(false)
            })
            .catch(error => {
                console.log("erreur :", error);
            })

        setReload(false);
    }, [postApiContext, sort, reload])


    const openMargin = (marginId) => {
        navigate("/marginsList/margin/" + marginId);
    }

    const openEmptyMargin = () => {
        navigate("/marginsList/margin/")
    }

    const changePerPage = (e) => {
        setPerPage(e.target.value);
        setCurrentPage(1);
    };

    //Reset research component
    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setSort("refdown");
        setReload(true);
    }

    const onSearchSelectChange = (e) => {
        console.log(e.target.value);
        //setInterlocutorSearch(e.target.value);
    }

    const onValidFilter = async (e) => {
        e.preventDefault();
        setReload(true);
    }

    const objectButtons = [
        {title: "Nouvelle marge", className: "", icon: faFileMedical, action: openEmptyMargin, showByRoleId: true},
    ]

    return (
        <>
            {
                loading &&
                <Loader/>
            }

            <MainHeader
                headerTitle={"MARGES"}
                objectButtons={objectButtons}
            />

            <div className="listPage">
                <SearchComponent
                    refresh={refresh}
                    onSearchSelectChange={onSearchSelectChange}
                    onSearchSubmit={onValidFilter}
                    contentObject={contentObject}
                    interlocutor={/*interlocutors*/""}
                />

                <SortByFilter sortOptions={sortOptions} setSort={setSort} onFilterSubmit={"onValidFilter"}/>

                {margins?.map((margin, k) => {
                    const element = {
                        id: margin.id,
                        valid: 0,
                        state: "",
                        date: "",
                        ref: margin.ref,
                        subject: "",
                        lastName: margin.contact.lastName,
                        firstName: margin.contact.firstName,
                        society: margin.contact.society,
                        totalHt: margin.invoice.totalHt ?? margin.quote?.totalHt,
                        totalTtc: margin.invoice.totalTtc,
                        directionPath: "/marginsList/margin/",
                        initials: margin.user.initials,
                    }
                    return (
                        <Card
                            roleVerif={roleVerif} key={k} element={element} tableName={"margin"}
                            handleOpenValidItem={openMargin}
                            stateButtonsList={[]} setListRefresh={() => setReload(true)}
                            deleteEndPoint={"c-deactive-margin"} otherButtonList={{}}
                        />
                    )

                })}

            </div>
            <div className='pagination'>
                <label htmlFor='perPage'>Éléments par page </label>
                <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="300">300</option>
                    <option value="500">500</option>
                </select>
                <Pagination
                    postsPerPage={perPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    nbPages={nbPages}
                />
            </div>
        </>
    )
}

export default MarginsList;
